import React, { useCallback, useState } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { SECTION_USER_DETAILS } from "../../../constants/common";
import styled from "styled-components";
import { IUserDto } from "../../../types/DTO/IUserDto";
import useInput from "../../../hooks/useInput";
import EditDetailsFormActions from "./EditDetailsFormActions";
import { useAppDispatch } from "../../../hooks/redux-hooks";
import { updateUser } from "../slice/thunks";

const CLASS_INPUT_ACTIVE = "input-active";
const CLASS_INPUT_REGULAR_FONT_SIZE = "regular-font-size";
const CLASS_INPUT_BIG_FONT_SIZE = "big-font-size";
const CLASS_INPUT_LABEL = "MuiTypography-root";
const CLASS_INPUT = "MuiInput-root";

interface EditDetailsFormProps {
  selectedUserDetails: IUserDto;
  updatingInProgress: boolean;
}

const EditDetailsFormContainer = styled(FormControl)`
  & .${CLASS_INPUT_ACTIVE} {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    transform: translateY(-8px);
    margin-bottom: 8px;

    & .${CLASS_INPUT_LABEL} {
      font-weight: 600;
      font-size: 10px;
      line-height: 18px;
    }
    & .${CLASS_INPUT} {
      line-height: 18px;
      width: 224px;
      font-size: inherit;
      font-weight: inherit;
    }
    & .${CLASS_INPUT_REGULAR_FONT_SIZE} {
      font-size: 12px;
    }
    & .${CLASS_INPUT_BIG_FONT_SIZE} {
      font-weight: 600;
      font-size: 16px;
    }
  }
`;

const EditDetailsForm: React.FC<EditDetailsFormProps> = ({
  selectedUserDetails,
  updatingInProgress
}) => {
  const dispatch = useAppDispatch();
  const { value: firstName, onChange: onChangeFirstName } = useInput<string>(
    (selectedUserDetails.firstName ?? "").trim()
  );
  const { value: lastName, onChange: onChangeLastName } = useInput<string>(
    (selectedUserDetails.lastName ?? "").trim()
  );
  const { value: advisorId, onChange: onChangeAdvisorId } = useInput<string>(
    (selectedUserDetails.advisorId ?? "").trim()
  );
  const { value: mobile, onChange: onChangeMobile } = useInput<string>(
    (selectedUserDetails.mobile ?? "").trim()
  );

  const [active, onChangeActive] = useState(selectedUserDetails.active);

  const handleChange = () => {
    onChangeActive(!active);
  };

  const handleOnClickApply = useCallback(() => {
    dispatch(updateUser({ firstName, lastName, advisorId, mobile, active }));
  }, [dispatch, firstName, lastName, advisorId, mobile, active]);

  return (
    <>
      <EditDetailsFormContainer className={SECTION_USER_DETAILS}>
        <Box className={CLASS_INPUT_ACTIVE}>
          <Typography component="label">Active</Typography>
          <input
            type="checkbox"
            className={CLASS_INPUT_BIG_FONT_SIZE}
            checked={active}
            onChange={handleChange}
          />
        </Box>
        <Box className={CLASS_INPUT_ACTIVE}>
          <Typography component="label">First Name</Typography>
          <TextField
            variant="standard"
            className={CLASS_INPUT_BIG_FONT_SIZE}
            value={firstName}
            onChange={onChangeFirstName}
          />
        </Box>
        <Box className={CLASS_INPUT_ACTIVE}>
          <Typography component="label">Last Name</Typography>
          <TextField
            variant="standard"
            className={CLASS_INPUT_BIG_FONT_SIZE}
            value={lastName}
            onChange={onChangeLastName}
          />
        </Box>
        <Box className={CLASS_INPUT_ACTIVE}>
          <Typography component="label">Advisor id</Typography>
          <TextField
            variant="standard"
            className={CLASS_INPUT_REGULAR_FONT_SIZE}
            value={advisorId}
            onChange={onChangeAdvisorId}
          />
        </Box>
        <Box className={CLASS_INPUT_ACTIVE}>
          <Typography component="label">Mobile</Typography>
          <TextField
            variant="standard"
            className={CLASS_INPUT_REGULAR_FONT_SIZE}
            value={mobile}
            onChange={onChangeMobile}
          />
        </Box>
      </EditDetailsFormContainer>
      <EditDetailsFormActions
        onClickApply={handleOnClickApply}
        updatingInProgress={updatingInProgress}
      />
    </>
  );
};

export default React.memo(EditDetailsForm);
