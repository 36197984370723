import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { AG_GRID_CONFIG_TABLE_ROW_HEIGHT, TABLE_ROW_HEIGHT } from "./common";
import { GridOptions } from "ag-grid-community";
import { RowSelectedEvent } from "ag-grid-community/dist/lib/events";
import styled from "styled-components";

const TABLE_CLASS = "agent-table-element";
const ROW_CLASS = "agent-table-row";

interface TableProps {
  rowData?: any[];
  columnDef: { name: string; componentName: string }[];
  // @ts-ignore
  cellRenderers: typeof GridOptions.frameworkComponents;
  onRowSelected(event: RowSelectedEvent): void;
}

const GridContainer = styled(AgGridReact)`
  width: unset;

  &.${TABLE_CLASS} {
    border: none;
    background-color: transparent;

    & .ag-root-wrapper {
      background-color: inherit;

      & .ag-row {
        height: ${TABLE_ROW_HEIGHT}px !important;

        &.${ROW_CLASS} {
          background: #ffffff;
          border-radius: 12px;
          &.ag-row-selected {
            box-shadow: 0 4px 20px rgba(0, 0, 0, 0.13);
          }
        }
      }
      & .ag-cell-focus {
        border-color: transparent !important;
      }
    }

    & .ag-header {
      background: #f4f4f4;
      border: none;
    }

    & > * {
      border: inherit;
    }
  }
`;

const Table: React.FC<TableProps> = ({
  rowData,
  columnDef,
  cellRenderers,
  onRowSelected
}) => {
  const [gridApi, setGridApi] = useState<any>(null);

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
  }, []);

  const columns = useMemo(
    () =>
      columnDef.map((def) => (
        <AgGridColumn
          key={def.name}
          field={def.name}
          cellRenderer={def.componentName}
        />
      )),
    [columnDef]
  );

  useEffect(() => {
    if (gridApi) {
      gridApi.sizeColumnsToFit();
    }
  }, [gridApi]);

  return (
    <GridContainer
      className={TABLE_CLASS}
      defaultColDef={{
        resizable: true,
        flex: 1
      }}
      onGridReady={onGridReady}
      rowSelection={"single"}
      rowClass={ROW_CLASS}
      getRowHeight={() => AG_GRID_CONFIG_TABLE_ROW_HEIGHT}
      headerHeight={20}
      rowData={rowData}
      onRowClicked={onRowSelected}
      frameworkComponents={cellRenderers}
    >
      {columns}
    </GridContainer>
  );
};

export default Table;
