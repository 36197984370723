import React, { useCallback, useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Container from "@mui/material/Container";
import {
  selectConnectPolicyModal,
  selectSelectedClaim
} from "../slice/selectors";
import { abortConnectPolicyModal, setSelectedClaimPolicyId } from "../slice";
import styled from "styled-components";
import Box from "@mui/material/Box";
import {
  Autocomplete,
  Button,
  Collapse,
  LinearProgress,
  TextField,
  Typography
} from "@mui/material";
import {
  AutocompleteItem,
  MobileUserPolicyDto,
  Nullable
} from "../../../types/general";
import ApiWrapper from "../../../utils/ApiWrapper";
import { connectClaimToPolicy } from "../slice/thunks";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux-hooks";

const MODAL_CONTENT_CLASS = "modal-content";
const CONNECT_POLICY_CTA_CONTAINER = "connect-policy-CTA-container";

interface DeclineExpenseProps {}

const ConnectPolicyModalContainer = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;

  & .${MODAL_CONTENT_CLASS} {
    padding: 20px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
    border-radius: 8px;

    & .${CONNECT_POLICY_CTA_CONTAINER} {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;

      & .MuiButton-root {
        overflow: hidden;
        margin-top: 20px;

        & .MuiLinearProgress-root {
          position: absolute;
          top: 0;
          width: 100%;
        }
      }
    }
  }
`;

const ConnectPolicyModal: React.FC<DeclineExpenseProps> = () => {
  const dispatch = useAppDispatch();
  const { isOpen, completed, inProcess } = useAppSelector(
    selectConnectPolicyModal
  );
  const { userId } = useAppSelector(selectSelectedClaim)!;

  const [policies, setPolicies] = useState<Nullable<AutocompleteItem[]>>([]);
  const [selectedPolicy, setSelectedPolicy] =
    useState<Nullable<AutocompleteItem>>(null);

  const handleAbortConnectPolicyModal = useCallback(() => {
    dispatch(abortConnectPolicyModal());
  }, [dispatch]);

  const handleSelectPolicy = useCallback((_: any, newValue: any) => {
    setSelectedPolicy(newValue);
  }, []);

  const fetchPolicies = useCallback(async (customerId) => {
    const response = await ApiWrapper.request<MobileUserPolicyDto[]>(
      `/policy/user/${customerId}/policies`,
      "get"
    );
    if (!response?.length) {
      setPolicies(null);
    } else {
      setPolicies(
        response.map((policy) => ({
          label: policy.userPolicyUniqueUUID,
          id: policy.id
        }))
      );
    }
  }, []);

  const handleButtonClick = useCallback(() => {
    dispatch(connectClaimToPolicy(selectedPolicy!.id as number));
    dispatch(setSelectedClaimPolicyId(selectedPolicy!.id as number));
  }, [dispatch, selectedPolicy]);

  useEffect(() => {
    fetchPolicies(userId).then(() => {});
  }, [fetchPolicies, userId]);

  return (
    <ConnectPolicyModalContainer
      open={isOpen}
      onClose={handleAbortConnectPolicyModal}
    >
      <Container className={MODAL_CONTENT_CLASS}>
        <h3>Choose a policy to link to the claim</h3>
        {policies === null && (
          <Typography>There are no policies connected to this user</Typography>
        )}
        {policies && policies.length > 0 && (
          <Box className={CONNECT_POLICY_CTA_CONTAINER}>
            <Autocomplete
              disabled={completed || inProcess}
              value={selectedPolicy}
              disablePortal
              options={policies!}
              onChange={handleSelectPolicy}
              fullWidth
              renderInput={(params) => <TextField {...params} label="Policy" />}
            />
            <Button
              variant="contained"
              color="secondary"
              disabled={inProcess}
              onClick={handleButtonClick}
            >
              {inProcess && <LinearProgress />}
              Connect Claim to Policy
            </Button>
          </Box>
        )}
        <Collapse in={completed}>Claim connected to policy</Collapse>
      </Container>
    </ConnectPolicyModalContainer>
  );
};

export default React.memo(ConnectPolicyModal);
