import React, { useEffect, useState } from "react";
import { IDashboardTrips } from "../../types/DTO/IDashboardDto";
import styled from "styled-components";
import Container from "@mui/material/Container";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Box } from "@mui/material";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface PurchasesAndPoliciesTileProps {
  className: string;
  purchasesAndPoliciesInfo: IDashboardTrips[];
}

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const
    },
    title: {
      display: false,
      text: ""
    }
  }
};

const PurchasesAndPoliciesTileContainer = styled(Container)`
  & .canvas {
    height: 86% !important;
  }

  & .top {
    margin-top: 16px;
    display: flex;

    & .top-title {
      flex: 3;
    }

    & .buttons {
      flex: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;

      & div {
        border-radius: 12px;
        padding: 2px 8px;
        color: #009d65;
        width: 50px;
        height: 22px;
        margin: 0;
        font-size: 13px;
        flex-grow: unset;
        white-space: nowrap;
        text-align: center;
        cursor: pointer;
      }

      & .selected {
        background-color: #4726d1;
        color: #ffffff;
      }

      & .not-selected {
        background-color: #f2f2f2;
        color: #838383;
      }
    }
  }
`;

enum PeriodEnum {
  "24hr" = 1,
  "7Days" = 7,
  all = 0
}

const PurchasesAndPoliciesTile: React.FC<PurchasesAndPoliciesTileProps> = ({
  className,
  purchasesAndPoliciesInfo
}) => {
  const [period, setPeriod] = useState(0);

  const mapPolicies = new Map<string, number>();
  const mapPurchases = new Map<string, number>();

  let data: any;
  let labels: string[];

  function getKey(period: number, info: IDashboardTrips) {
    let key = `${info.month}/${info.year}`;
    if (period === PeriodEnum["7Days"]) {
      key = `${info.day}`;
    } else if (period === PeriodEnum["24hr"]) {
      key = `${info.hour}`;
    }
    return key;
  }

  function setData() {
    const filteredPurchasesAndPoliciesInfo = purchasesAndPoliciesInfo.filter(
      (info) => {
        if (period === PeriodEnum["7Days"]) {
          return Number(info.period) === 1 || Number(info.period) === 7;
        } else if (period === PeriodEnum["24hr"]) {
          return Number(info.period) === 1;
        }
        return true;
      }
    );

    filteredPurchasesAndPoliciesInfo.forEach((info) => {
      const key = getKey(period, info);
      const valuePolicies = mapPolicies.get(key);
      if (valuePolicies) {
        mapPolicies.set(key, Number(valuePolicies) + Number(info.noPolicies));
      } else {
        mapPolicies.set(key, Number(info.noPolicies));
      }

      const valuePurchases = mapPurchases.get(key);
      if (valuePurchases) {
        mapPurchases.set(key, Number(valuePurchases) + Number(info.cnt));
      } else {
        mapPurchases.set(key, Number(info.cnt));
      }
    });

    labels = filteredPurchasesAndPoliciesInfo.map((info) => {
      return getKey(period, info);
    });
    labels = labels.filter(function (v, i) {
      return labels.indexOf(v) === i;
    });

    data = {
      labels, //x axis
      datasets: [
        {
          label: "Policies",
          data: labels.map((label) => mapPolicies.get(label)),
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.5)"
        },
        {
          label: "Purchases",
          data: labels.map((label) => mapPurchases.get(label)),
          borderColor: "rgb(53, 162, 235)",
          backgroundColor: "rgba(53, 162, 235, 0.5)"
        }
      ]
    };
  }
  setData();

  useEffect(() => {
    setData();
  }, [period]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PurchasesAndPoliciesTileContainer className={className}>
      <Box className="top">
        <Box className="top-title">
          <span className="tile-name">Purchases & Policies</span>
        </Box>
        <Box className="buttons">
          <div
            className={period === 0 ? "selected" : "not-selected"}
            onClick={() => setPeriod(PeriodEnum.all)}
          >
            Total
          </div>
          <div
            className={period === 7 ? "selected" : "not-selected"}
            onClick={() => setPeriod(PeriodEnum["7Days"])}
          >
            7 days
          </div>
          <div
            className={period === 1 ? "selected" : "not-selected"}
            onClick={() => setPeriod(PeriodEnum["24hr"])}
          >
            24hr
          </div>
        </Box>
      </Box>

      <Line className="canvas" options={options} data={data} />
    </PurchasesAndPoliciesTileContainer>
  );
};

export default React.memo(PurchasesAndPoliciesTile);
