import React from "react";
import { Nullable } from "../../../../../types/general";
import styled from "styled-components";
import { Box, Stack, Typography } from "@mui/material";

const HistoryItemContainer = styled(Box)`
  &.history-item {
    box-shadow: 0 4px 20px rgb(0 0 0 / 13%);
    padding: 10px;
    gap: 5px;
    display: flex;
    flex-direction: column;
    & > .history-item-top {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      & > h5,
      .item-content {
        font-size: 14px;
        font-weight: bold;
      }
    }
    & > .history-item-content {
      & > .item-content {
        font-size: 13px;
      }
    }
  }
`;

interface HistoryItemProps {
  metaData: {
    data: Nullable<boolean>;
    type: string;
  };
  name: string;
}

const HistoryItem: React.FC<HistoryItemProps> = ({ metaData, name }) => {
  return (
    <HistoryItemContainer className={"history-item"}>
      <Stack className={"history-item-top"}>
        <Typography variant={"h5"}>{name}</Typography>
        <Typography className={"item-content"}>{metaData.type}</Typography>
      </Stack>

      <Stack className={"history-item-content"}>
        <Typography className={"item-content"}>
          Data: {metaData.data}
        </Typography>
      </Stack>
    </HistoryItemContainer>
  );
};

export default HistoryItem;
