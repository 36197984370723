import React from "react";
import CancelPolicyModal from "./CancelPolicyModal";
import ChangeDatesModal from "./ChangeDatesModal";
import EditCustomerDetails from "./EditCustomerDetails";
import { useAppSelector } from "../../../hooks/redux-hooks";
import {
  editCustomerModal,
  selectChangeDatesModalOpen
} from "../slice/selectors";

const PolicyModalsContainer: React.FC = () => {
  const changeDatesModalOpen = useAppSelector(selectChangeDatesModalOpen);
  const { isOpen: openEditCustomerDetails } = useAppSelector(editCustomerModal);

  return (
    <>
      <CancelPolicyModal />
      {changeDatesModalOpen && changeDatesModalOpen.isOpen && (
        <ChangeDatesModal />
      )}
      {openEditCustomerDetails && <EditCustomerDetails />}
    </>
  );
};

export default PolicyModalsContainer;
