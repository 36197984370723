import React, { useEffect, useState } from "react";
import classnames from "classnames";
import styled from "styled-components";
import TabPanel from "../../../../TabPanel";
import { IClaim, Nullable } from "../../../../../types/general";
import {
  useAppDispatch,
  useAppSelector
} from "../../../../../hooks/redux-hooks";
import { selectSelectedClaimHistory } from "../../../slice/selectors";
import { fetchClaimHistory } from "../../../slice/thunks";
import HistoryItem from "./HistoryItem";
import { Box } from "@mui/material";

const HistoryTabContainer = styled(TabPanel)`
  &.history-tab {
    overflow: auto;
    & > .history-list {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 10px;
    }
  }
`;

export type HistoryMetaData = {
  [key: string]: {
    data: Nullable<boolean>;
    type: string;
  };
};

export type History = {
  created: string;
  eventDescription: string;
  eventType: string;
  id: number;
  metaData: HistoryMetaData;
  modifierId: number;
  refTableId: number;
};

interface HistoryTabProps {
  selectedClaim: IClaim;
}

const HistoryTab: React.FC<HistoryTabProps> = ({ selectedClaim }) => {
  const dispatch = useAppDispatch();
  const claimHistory: History[] = useAppSelector(selectSelectedClaimHistory);
  const [historyMetaData, setHistoryMetaData] =
    useState<HistoryMetaData | null>(null);

  useEffect(() => {
    dispatch(
      fetchClaimHistory({
        tableId: selectedClaim.claim.id,
        eventType: "claim"
      })
    );
  }, [selectedClaim, dispatch]);

  useEffect(() => {
    if (!claimHistory || claimHistory.length <= 0) setHistoryMetaData(null);
    else if (claimHistory.length > 0) {
      setHistoryMetaData(claimHistory[0].metaData);
    }
  }, [claimHistory]);

  return (
    <HistoryTabContainer
      currTabIndex={3}
      tabIndex={3}
      isHidden={Boolean(!selectedClaim)}
      className={classnames("history-tab", "tab-container")}
    >
      <Box className={"history-list"}>
        {historyMetaData ? (
          Object.keys(historyMetaData).map((key) => {
            return (
              <HistoryItem
                metaData={historyMetaData[key]}
                name={key}
                key={key}
              />
            );
          })
        ) : (
          <></>
        )}
      </Box>
    </HistoryTabContainer>
  );
};

export default HistoryTab;
