import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useCreateEmergencyContactForm from "hooks/useCreateEmergencyContactForm";
import { validator } from "utils/validator";
import { FormEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/redux-hooks";
import {
  createOrEditEmergencyContact,
  fetchEmergencyContact
} from "./slice/thunks";
import { getWidgetsCountry } from "./slice/selectors";
import { IContact } from "types/DTO/IContactDTO";
import { IEmergencyContactDTO } from "types/DTO/IEmergencyContactDTO";
import { Nullable } from "types/general";
import _ from "lodash";
import { IEmergencyContactWidget } from "../../types/DTO/IWidgetsDto";
import styled from "styled-components";
import classNames from "classnames";

const CreateEmergencyContactFormDialogContainer = styled.div`
  & .btn {
    margin-top: 10px;
    background: #4726d1;
    border: 1px solid #4726d1;
    box-shadow: 0 4px 10px rgba(165, 165, 165, 0.25);
    border-radius: 4px;
    text-transform: unset;
    font-family: "TWK Lausanne";
    font-style: normal;
    font-weight: 550;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #ffffff;
  }

  & .Edit {
    width: 100%;
    margin-bottom: 10px;
  }

  & .Add {
    margin: 20px 0;
  }
`;

class Contact implements IContact {
  constructor(
    public type: string,
    public value: string,
    public caption: string,
    public extension?: string
  ) {}
}

interface EmergencyContactProps {
  contactDetails: Nullable<IEmergencyContactDTO>;
}

const CreateEmergencyContactFormDialog: React.FC<EmergencyContactProps> = ({
  contactDetails
}) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const [submitAllowed, setSubmitAllowed] = useState<boolean>(false);
  const country: string = useAppSelector(getWidgetsCountry);

  interface IState {
    [key: string]: null | string;
  }

  interface IFieldsData {
    emergencyNumber: IContact;
    contacts: IContact[];
  }

  interface IUseCreateEmergencyContactForm {
    handleChange: (e: FormEvent) => void;
    handleSubmit?: (e: FormEvent) => void;
    handleBlur: (e: FormEvent) => void;
    state: IState;
    errors: IState;
  }

  const fields: string[] = [
    "Emergency number",
    "Police",
    "Ambulance",
    "US embassy",
    "US embassy email"
  ];

  const fieldsData: IFieldsData = {
    emergencyNumber: new Contact("phone", "", "Emergency number", ""),
    contacts: []
  };

  const placeholder = (type: string) => {
    switch (type) {
      case "phone":
        return "";
      case "email":
        return "example@example.com";
    }
  };
  const initiateState = () => {
    const init: { [key: string]: string } = {};
    fields.forEach((field) => {
      init[field] = "";
      if (field !== "Emergency number") {
        if (fields.includes("email")) {
          fieldsData.contacts.push(new Contact("email", "", `${field}`, ""));
        } else {
          fieldsData.contacts.push(new Contact("phone", "", `${field}`, ""));
        }
      }
    });
    return init;
  };

  const initState: IState = initiateState();

  if (contactDetails) {
    initState[`${contactDetails.emergencyNumber.caption}`] =
      contactDetails.emergencyNumber.value;
    contactDetails.contacts.forEach((contact, index) => {
      if (index === contactDetails.contacts.length - 1) {
        initState[`${contact.caption} email`] = contact.value;
      } else {
        initState[`${contact.caption}`] = contact.value;
      }
    });
  }

  const buttonText = contactDetails ? "Edit" : "Add";
  const {
    handleChange,
    handleBlur,
    state,
    errors
  }: IUseCreateEmergencyContactForm = useCreateEmergencyContactForm({
    initState,
    validator
  });

  useEffect(() => {
    let allowSubmit = !_.isEqual(state, initState);
    Object.values(errors).forEach((error) => {
      if (error) {
        allowSubmit = false;
      }
    });

    setSubmitAllowed(allowSubmit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, initState, errors]);

  const handleClickOpen = async () => {
    if (country && !contactDetails) {
      await dispatch(fetchEmergencyContact({ country }));
    }
    setOpen(true);
  };

  const createPayload = () => {
    const payload: IEmergencyContactWidget = {
      location: country,
      emergencyNumber: new Contact("phone", "", "Emergency number", ""),
      contacts: []
    };
    Object.keys(state).forEach((key) => {
      if (!!state[key]) {
        let value = state[key] as string;
        if (key.includes("email")) {
          let newCaption: string | string[] = key.split(" ");
          newCaption.pop();
          newCaption = newCaption.join(" ");
          payload.contacts.push(new Contact("email", value, newCaption, ""));
        } else {
          if (key === "Emergency number") {
            payload.emergencyNumber = new Contact("phone", value, key, "");
          } else {
            payload.contacts.push(new Contact("phone", value, key, ""));
          }
        }
      }
    });
    if (contactDetails && contactDetails.id) {
      payload["id"] = +contactDetails.id;
    }
    return payload;
  };

  const handleClose = () => {
    setOpen(false);
    if (submitAllowed) {
      const payload = createPayload();
      dispatch(createOrEditEmergencyContact({ payload }));
    }
  };

  const textFields = [
    <TextField
      autoFocus
      key={10}
      margin="dense"
      id={`${10}`}
      placeholder={placeholder(fieldsData.emergencyNumber.type)}
      label={fieldsData.emergencyNumber.caption}
      name={fieldsData.emergencyNumber.caption}
      type={fieldsData.emergencyNumber.type}
      defaultValue={state[fieldsData.emergencyNumber.caption]}
      onBlur={handleBlur}
      onChange={handleChange}
      error={!!errors[fieldsData.emergencyNumber.caption]}
      helperText={errors[fieldsData.emergencyNumber.caption]}
      required
      fullWidth
      variant="standard"
    />
  ];

  fieldsData.contacts.forEach((contact: IContact, index: number) =>
    textFields.push(
      <TextField
        key={index}
        margin="dense"
        placeholder={placeholder(contact.type)}
        id={`${index}`}
        label={contact.caption}
        name={contact.caption}
        type={contact.type}
        defaultValue={state[contact.caption]}
        onBlur={handleBlur}
        onChange={handleChange}
        error={!!errors[contact.caption]}
        helperText={errors[contact.caption]}
        fullWidth
        variant="standard"
      />
    )
  );
  return (
    <CreateEmergencyContactFormDialogContainer>
      <Button
        variant="outlined"
        className={classNames("btn", `${buttonText}`)}
        onClick={handleClickOpen}
      >
        {`${buttonText} emergency contacts`}
      </Button>
      <Dialog open={open}>
        <DialogTitle>Create Emergency contacts</DialogTitle>
        <DialogContent>{textFields}</DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button disabled={!submitAllowed} onClick={handleClose}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </CreateEmergencyContactFormDialogContainer>
  );
};
export default CreateEmergencyContactFormDialog;
