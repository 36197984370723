import React from "react";
import UsersCollection from "../components/advisors/AdvisorsList";
import UserView from "../components/advisors/AdvisorView";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import WestIcon from "@mui/icons-material/West";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import { selectSelectedUser } from "../components/advisors/slice/selectors";
import { resetSelectedUser } from "../components/advisors/slice";
import styled from "styled-components";

interface AdvisorsProps {}

const AdvisorsDrawer = styled(Drawer)`
  & .MuiDrawer-paper {
    box-sizing: border-box;
    width: 97%;
    flex-shrink: 0;
    margin-top: 72px;
  }
  & .btn {
    justify-content: start;
    margin: 1em;
    text-transform: unset;
    max-width: 2em;
  }
`;

const Advisors: React.FC<AdvisorsProps> = () => {
  const dispatch = useAppDispatch();
  const open = !!useAppSelector(selectSelectedUser);
  return (
    <>
      <AdvisorsDrawer
        onClose={() => dispatch(resetSelectedUser())}
        anchor={"right"}
        hideBackdrop={true}
        open={open}
      >
        <Button
          fullWidth={false}
          variant="text"
          onClick={() => dispatch(resetSelectedUser())}
          className="btn"
          size={"small"}
          startIcon={<WestIcon />}
        >
          Back
        </Button>
        <UserView />
      </AdvisorsDrawer>
      <UsersCollection />
    </>
  );
};

export default Advisors;
