import React, { useCallback } from "react";
import { IUserPolicyInfoDto } from "../../types/DTO/IUsersInfoDto";
import styled from "styled-components";
import smallPhone from "../../assets/svg/small-phone.svg";
import Container from "@mui/material/Container";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { selectPoliciesSearchText } from "./slice/selectors";
import Avatar from "../ui/Avatar";
import { setDisplayPolicy, setSelectedPolicy } from "./slice";

interface PolicyLineProps {
  policy: IUserPolicyInfoDto;
  index: number;
}

const ConnectedPoliciesContainer = styled(Container)`
  padding-left: 0 !important;
  padding-right: 0 !important;

  & .row-container {
    border-top: 1px solid #e5e5e5;
    box-shadow: unset;

    & .code-container {
      color: #959493;
    }
    & .img-url-container {
      padding-left: 8px;
    }

    & .small-phone {
      background-image: url("${smallPhone}");
      width: 10px;
      height: 16px;
    }
  }
`;

const ConnectedPolicies: React.FC<PolicyLineProps> = ({ policy, index }) => {
  const dispatch = useAppDispatch();
  const searchText = useAppSelector(selectPoliciesSearchText);

  const isSelectedRow = () => {
    if (searchText && searchText.length > 0) {
      const innerSearchText = searchText.toLowerCase();
      if (
        policy?.email?.toLowerCase()?.indexOf(innerSearchText) > -1 ||
        policy?.userName?.toLowerCase()?.indexOf(innerSearchText) > -1 ||
        policy?.code?.toLowerCase()?.indexOf(innerSearchText) > -1
      ) {
        return true;
      }
    }
    return false;
  };

  const handleRowSelected = useCallback(() => {
    dispatch(setSelectedPolicy(policy));
    dispatch(setDisplayPolicy(true));
  }, [dispatch, policy]);
  return (
    <ConnectedPoliciesContainer>
      <div className="row-container">
        <div
          onClick={() => handleRowSelected()}
          className={`row ${isSelectedRow() ? "selected-row" : ""}`}
        >
          <div className="img-url-container">
            <Avatar
              index={index}
              isMainAvatar={false}
              imgUrl={policy.imgUrl?.url ? policy.imgUrl.url : ""}
              name={policy.userName}
            ></Avatar>
          </div>
          <div className="user-name-container">{policy.userName}</div>
          <div className="email-container">{policy.email}</div>
          <div className="code-container">{policy.code}</div>
          <div className="mobile-container">
            <div className={`${policy.hadLogin ? "small-phone" : ""}`}></div>
          </div>
          <div className="trip-status-container">
            <div
              className={`status-container ${policy.tripStatus.replace(
                " ",
                ""
              )}`}
            >
              <div>{policy.tripStatus}</div>
            </div>
          </div>
          <div className="handler-container"></div>
        </div>
      </div>
    </ConnectedPoliciesContainer>
  );
};

export default ConnectedPolicies;
