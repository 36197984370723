import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../types/redux-types";
import ApiWrapper from "../../../utils/ApiWrapper";
import { IUserPolicyInfoDto } from "../../../types/DTO/IUsersInfoDto";
import { IPageMeta } from "../../../types/general";
import { ICustomerSearchDto } from "../../../types/DTO/ICustomerSearchDto";
import { getUserById } from "../../../app/common/API-Requests";
import TokenService from "../../../utils/TokenService";
import { IDocDto } from "../../../types/DTO/IDocDto";
import { IPolicyDto } from "../../../types/DTO/IPolicyDto";
import {
  convertToTripStatusToProperFormat,
  fetchGroupedPolicies
} from "../../../utils/policies";
import {
  getTimeZonelessDate,
  stringDateToDisplayFormat
} from "../../../utils/dates";
import { IAddress } from "../../../types/DTO/IAddress";
import { ICustomerDetails } from "../../../types/DTO/ICustomerDetails";
import { ICustomerProfileDto } from "../../../types/DTO/ICustomerProfileDto";
import { ILastLoginDto } from "../../../types/DTO/ILastLoginDto";
import { getPictureUrl, getAllImgUrlFromImgObjArr } from "utils/policies";
import { ICustomerClaimDto } from "../../../types/DTO/ICustomerClaimDto";

type GetCustomersResponse = {
  policies: IUserPolicyInfoDto[];
  pageMeta?: IPageMeta;
};

export const searchPolicyPage = async (
  search: string,
  page: number
): Promise<GetCustomersResponse> => {
  const customerSearchQueryDto: ICustomerSearchDto = {
    search,
    page,
    sort: "name",
    order: "down"
  };

  const res = await ApiWrapper.request<GetCustomersResponse>(
    "/policy/userPolicy/search",
    "POST",
    {
      data: customerSearchQueryDto
    }
  );
  if (res) {
    for (const policy of res.policies) {
      if (!!policy.noPoliciesInGroup && +policy.noPoliciesInGroup > 1) {
        policy["relatedPolicies"] = await fetchGroupedPolicies(policy.id).then(
          async (policies) =>
            !!policies ? getAllImgUrlFromImgObjArr(policies) : undefined
        );
      }
      if (!!policy.imgUrl && !!policy.imgUrl.key) {
        policy.imgUrl["url"] = await getPictureUrl(policy.imgUrl.key);
      }
      if (!!policy.extraTravelersInfo) {
        for (const traveler of policy.extraTravelersInfo) {
          if (!!traveler.imgUrl && !!traveler.imgUrl.key) {
            traveler.imgUrl["url"] = await getPictureUrl(traveler.imgUrl.key);
          }
        }
      }
    }
  }
  return res;
};

export const fetchPolicyPage = createAsyncThunk<
  GetCustomersResponse | undefined,
  number,
  { state: RootState }
>("policies/fetchCustomers", async (page, { getState }) => {
  const { policiesSearchText, customersFetchingInProcessAmount } =
    getState().policies;
  if (customersFetchingInProcessAmount > 1) {
    return;
  }
  try {
    return await searchPolicyPage(policiesSearchText, page);
  } catch (error: any) {
    TokenService.removeUser();
    throw new Error(error.response.data.message);
  }
});

export const deleteCustomerPolicy = createAsyncThunk<
  void,
  { policyId: number },
  { state: RootState }
>("policies/cancelPolicy", async ({ policyId }) => {
  await ApiWrapper.delete(`/policy/amendment/cancel/${policyId}`);
});

export const fetchPolicyClaims = createAsyncThunk<
  ICustomerClaimDto[],
  { userId: number },
  { state: RootState }
>("policies/fetchCustomerClaims", async ({ userId }) => {
  return await ApiWrapper.request<ICustomerClaimDto[]>(
    `/claims/user/${userId}`,
    "GET"
  );
});

export const fetchPolicyComments = createAsyncThunk<
  any,
  { userId: number },
  { state: RootState }
>("policies/fetchCustomerComments", async ({ userId }) => {
  return await ApiWrapper.request<any>(`/notes/user/${userId}`, "GET");
});

export const fetchPolicyPDF = createAsyncThunk<
  any,
  { policyId: number },
  { state: RootState }
>("policies/fetchPolicyPDF", async ({ policyId }) => {
  return await ApiWrapper.request<any>(
    `/docs/download/${policyId}/type/policySummary`,
    "GET"
  );
});

export const getPdfLink = createAsyncThunk<
  any,
  { fileName: string; policyId: number },
  { state: RootState }
>("policies/getPdfLink", async ({ fileName }) => {
  return await ApiWrapper.request<any>(
    `/file-manager/file/signed/${fileName}`,
    "GET"
  );
});

export const fetchPolicyTrips = createAsyncThunk<
  any,
  { userId: number },
  { state: RootState }
>("policies/fetchTripsById", async ({ userId }, { dispatch }) => {
  const response = await ApiWrapper.request<any>(
    `/policy/user/${userId}/policies`,
    "GET"
  );
  response.forEach((trip: any) => {
    dispatch(fetchRelatedPolicies({ policyId: trip.id }));
    dispatch(fetchRelatedFlights({ policyId: trip.id }));
  });
  return response;
});

export const fetchRelatedPolicies = createAsyncThunk<
  any,
  { policyId: number },
  { state: RootState }
>("policies/fetchRelatedPolicies", async ({ policyId }) => {
  return await ApiWrapper.request<any>(
    `/policy/userPolicy/related/policy/${policyId}`,
    "GET"
  );
});

export const fetchRelatedFlights = createAsyncThunk<
  any,
  { policyId: number },
  { state: RootState }
>("policies/fetchRelatedFlights", async ({ policyId }) => {
  return await ApiWrapper.request<any>(`/policy/flights/${policyId}`, "GET");
});

export const fetchPolicyChat = createAsyncThunk<
  any,
  { userId: number },
  { state: RootState }
>("policies/fetchChatById", async ({ userId }) => {
  return await ApiWrapper.request<any>(`/chat/user/${userId}`, "GET");
});

export const fetchPolicyPayments = createAsyncThunk<
  any,
  { id: number },
  { state: RootState }
>("policies/fetchPaymentsByCustomerId", async ({ id }) => {
  return await ApiWrapper.request<any>(`/bank/transfers/user/${id}`, "GET");
});

export const fetchSelectedUserProfile = createAsyncThunk<
  any,
  number,
  { state: RootState }
>("policies/fetchUserById", async (userId) => {
  return getUserById(userId.toString());
});

export const addPolicyComment = createAsyncThunk<
  any,
  {
    userId: number;
    created: string;
    updated?: string;
    note: string;
    id?: number;
    tripId: number;
  },
  { state: RootState }
>(
  "policies/addCommentToClaim",
  async ({ userId, created, updated, note, id, tripId }, { dispatch }) => {
    if (!updated) updated = created;
    const response = await ApiWrapper.request<any>(
      `/notes/addOrUpdateUserNote`,
      "POST",
      { data: { userId, created, updated, note, id, tripId } }
    );
    dispatch(fetchPolicyComments({ userId }));
    return response;
  }
);

export const deletePolicyComment = createAsyncThunk<
  any,
  { noteId: number; userId: number },
  { state: RootState }
>("policies/deleteComment", async ({ noteId, userId }, { dispatch }) => {
  const response = await ApiWrapper.request<any>(
    `/notes/${noteId.toString()}`,
    "DELETE"
  );

  dispatch(fetchPolicyComments({ userId }));
  return response;
});

export const updateUser = createAsyncThunk<
  ICustomerDetails,
  {
    policyId: number;
    userId: number | null;
    firstName: string;
    lastName: string;
    email: string;
    address: IAddress | null;
    dateOfBirth: string;
    mobile: string;
  },
  { state: RootState }
>(
  "policies/updateUser",
  async (
    {
      policyId,
      userId,
      firstName,
      lastName,
      email,
      address,
      mobile,
      dateOfBirth
    },
    { getState }
  ) => {
    const selectedCustomerDetails = getState().policies.selectedCustomerDetails;

    const updatedUser = await ApiWrapper.request<ICustomerProfileDto>(
      "/user-update",
      "POST",
      {
        data: {
          userId,
          policyId,
          firstName,
          lastName,
          email,
          address,
          dob: dateOfBirth,
          mobile
        }
      }
    );
    return {
      ...selectedCustomerDetails,
      firstName: updatedUser.firstName,
      lastName: updatedUser.lastName,
      email: updatedUser.email ? updatedUser.email : "",
      phone: updatedUser.mobile ? updatedUser.mobile : "",
      id: updatedUser.id ? updatedUser.id : 0,
      dateOfBirth: updatedUser.dateOfBirth
        ? stringDateToDisplayFormat(
            getTimeZonelessDate(new Date(updatedUser.dateOfBirth))
          )
        : ""
    };
  }
);

export const thunkChangeDates = createAsyncThunk<
  IPolicyDto,
  { policyId: number; tripStartDate: Date | null; tripEndDate: Date | null },
  { state: RootState }
>("policies/updateDates", async ({ policyId, tripStartDate, tripEndDate }) => {
  const data = { policyId, tripStartDate, tripEndDate };
  if (data.tripStartDate) {
    data.tripStartDate = new Date(
      data.tripStartDate.getTime() -
        data.tripStartDate.getTimezoneOffset() * 60000
    );
  }
  if (data.tripEndDate) {
    data.tripEndDate = new Date(
      data.tripEndDate.getTime() - data.tripEndDate.getTimezoneOffset() * 60000
    );
  }

  return await ApiWrapper.request<IPolicyDto>(
    "/policy/amendment/change/dates",
    "POST",
    {
      data
    }
  );
});

export const fetchPolicyDocs = createAsyncThunk<
  IDocDto[],
  { userId: number },
  { state: RootState }
>("policies/fetchCustomerDocs", async ({ userId }) => {
  return await ApiWrapper.request<any>(`/docs/user/${userId}`, "GET");
});

export const removeFlight = createAsyncThunk<
  void,
  { userId: number; flightId: number; policyId: number },
  { state: RootState }
>("policies/removeFlight", async ({ userId, flightId }) => {
  await ApiWrapper.request<void>(
    `flight/user/${userId}/flight/${flightId}`,
    "DELETE"
  );
});

export const createPolicyPdf = createAsyncThunk<
  number,
  { policyId: number },
  { state: RootState }
>("policies/createPdf", async ({ policyId }) => {
  await ApiWrapper.request<any>(
    `QuoteFileDownload/create/files/${policyId}/`,
    "PATCH"
  );
  return policyId;
});

export const fetchAppLastLogIn = createAsyncThunk<
  ILastLoginDto,
  { userId: number },
  { state: RootState }
>("customer/lastAppLogIn", async ({ userId }) => {
  return await ApiWrapper.request<ILastLoginDto>(
    `login-event/user/${userId}/last`,
    "GET"
  );
});

export const fetchCustomerDetails = createAsyncThunk<
  ICustomerDetails,
  { userId: number },
  { state: RootState }
>("customer/details", async ({ userId }, { getState }) => {
  const {
    id,
    firstName,
    lastName,
    email,
    mobile,
    tripStatus,
    dateOfBirth,
    profilePicture
  } = await ApiWrapper.request<ICustomerProfileDto>(
    `user/profile/${userId}`,
    "GET"
  );
  const selectedCustomerDetails = getState().policies.selectedCustomerDetails;
  let url = "";
  if (profilePicture) {
    url = await getPictureUrl(profilePicture.key);
  }
  return {
    ...selectedCustomerDetails,
    id,
    firstName,
    lastName,
    email,
    profilePicture: !!profilePicture
      ? {
          ...profilePicture,
          url
        }
      : {
          Bucket: "",
          ETag: "",
          Key: "",
          key: "",
          url: ""
        },
    phone: !!mobile ? mobile : "",
    dateOfBirth: stringDateToDisplayFormat(
      getTimeZonelessDate(new Date(dateOfBirth))
    ),
    tripStatus: convertToTripStatusToProperFormat(tripStatus)
  };
});
