import { RootState } from "../../../types/redux-types";

export const selectUsers = (state: RootState) => state.users.Users;
export const selectNewUserModalOpen = (state: RootState) =>
  state.users.createUserModal.isOpen;
export const selectSelectedUser = (state: RootState) =>
  state.users.selectedUser;
export const selectUsersPagination = (state: RootState) =>
  state.users.pagination;
export const selectUsersSearchText = (state: RootState) =>
  state.users.UsersSearchText;
export const selectSelectedUserDetails = (state: RootState) =>
  state.users.selectedUserDetails;
export const selectSelectedUserEdit = (state: RootState) =>
  state.users.isSelectedUserEdit;
export const isComponentLoading = (state: RootState) => state.users.isLoading;
