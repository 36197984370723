import React, { useCallback } from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import Box from "@mui/material/Box";
import { setSelectedUserEdit } from "./slice";
import { useAppDispatch } from "../../hooks/redux-hooks";
import styled from "styled-components";

const CLASS_BUTTON_APPLY = "button-apply";
export const SECTION_ACTIONS = "card-actions";

interface CardActionsProps {}

const CardActionsContainer = styled(Box)`
  &.${SECTION_ACTIONS} {
    grid-area: ${SECTION_ACTIONS};
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: space-between;

    & .MuiIconButton-root {
      width: 24px;
      height: 24px;
      padding: 0;
    }
    & .MuiSvgIcon-root {
      width: 16px;
      height: 16px;
    }

    & .${CLASS_BUTTON_APPLY} {
      width: 54px;
      height: 24px;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      margin-right: 6px;
    }
    & .fraud-actions {
      flex-direction: row;
      gap: 5px;
      font-size: 12px;
      align-items: center;
      & > .fraud-indicator {
        font-size: 12px;
      }
    }
  }
`;

const CardActions: React.FC<CardActionsProps> = () => {
  const dispatch = useAppDispatch();

  const handleEditUserClicked = useCallback(() => {
    dispatch(setSelectedUserEdit(true));
  }, [dispatch]);

  return (
    <CardActionsContainer className={SECTION_ACTIONS}>
      <Tooltip title="Edit">
        <IconButton onClick={handleEditUserClicked}>
          <EditIcon />
        </IconButton>
      </Tooltip>
    </CardActionsContainer>
  );
};

export default React.memo(CardActions);
