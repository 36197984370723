import { axiosInstance, axiosRefreshTokenInstance } from "./index";
import TokenService from "../TokenService";
import { AppUserAuthData } from "../../components/authentication/slice/thunks";
import { refreshUserTokenState } from "../../components/authentication/slice";
import { AppDispatch } from "../../types/redux-types";

const refreshUserToken = async (dispatch: AppDispatch) => {
  const refreshToken = TokenService.getLocalRefreshToken();
  const response = await axiosRefreshTokenInstance.post("", {
    grant_type: "refresh_token",
    refresh_token: refreshToken
  });
  const { data } = response;
  const { access_token, refresh_token, expires_in } = data as any;
  const result: AppUserAuthData = {
    accessToken: access_token,
    refreshToken: refresh_token,
    expirationTime: Date.now() + expires_in
  };
  TokenService.updateLocalAccessToken(result);
  dispatch(refreshUserTokenState({ authData: result }));
};

const setupInterceptors = (store: any) => {
  return new Promise((resolve) => {
    axiosInstance.interceptors.request.use(
      (config: any) => {
        const token = TokenService.getLocalAccessToken();
        if (token) {
          config.headers["Authorization"] = "Bearer " + token; // for Spring Boot back-end
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    const { dispatch } = store;
    axiosInstance.interceptors.response.use(
      (res) => {
        return res;
      },
      async (err) => {
        const originalConfig = err.config;

        if (originalConfig.url !== "/auth/signin" && err.response) {
          // Access Token was expired
          if (err.response.status === 401 && !originalConfig._retry) {
            originalConfig._retry = true;

            // @ts-ignore
            // @ts-ignore
            try {
              await refreshUserToken(dispatch);
              return axiosInstance(originalConfig);
            } catch (
              //@ts-ignore next line
              _error: AxiosError
            ) {
              return Promise.reject(_error);
            }
          }
        }

        return Promise.reject(err.response.data);
      }
    );

    resolve({});
  });
};

export default setupInterceptors;
