import React from "react";
import classnames from "classnames";
import styled from "styled-components";
import Box from "@mui/material/Box";
import { TABLE_ROW_HEIGHT } from "../common";
import { selectSelectedRowIndex } from "components/collectionTable/selectors";
import { useAppSelector } from "hooks/redux-hooks";
import Avatar from "@mui/material/Avatar";

const CellContainer = styled(Box)`
  height: ${TABLE_ROW_HEIGHT}px; !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: normal;
  
  &.selected-row{
    & label.cell-value {
      color: #434140;
    }
  }
`;

interface AvatarCallRendererProps {
  value: string;
  rowIndex: number;
}

const AvatarCallRenderer: React.FC<AvatarCallRendererProps> = ({
  value,
  rowIndex
}) => {
  const selectedRowIndex = useAppSelector(selectSelectedRowIndex);
  return (
    <CellContainer
      className={classnames({ "selected-row": selectedRowIndex === rowIndex })}
    >
      <Avatar src={value} className="cell-value" />
    </CellContainer>
  );
};
export default React.memo(AvatarCallRenderer);
