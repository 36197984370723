import React from "react";
import Chip from "@mui/material/Chip";
import moment from "moment";

enum PolicyStatus {
  PRE_TRIP = "Pre Trip",
  IN_TRIP = "In Trip",
  POST_TRIP = "Post Trip"
}
interface PolicyStatusChipProps {
  endDate: Date;
  startDate: Date;
  color?: "primary" | "secondary";
  className?: string;
}

const getStatus = ({ startDate, endDate }: PolicyStatusChipProps) => {
  let status;
  if (moment().isBefore(moment(startDate))) {
    status = PolicyStatus.PRE_TRIP;
  } else if (moment().isAfter(moment(endDate))) {
    status = PolicyStatus.POST_TRIP;
  } else {
    status = PolicyStatus.IN_TRIP;
  }
  return status;
};

const PolicyStatusChip: React.FC<PolicyStatusChipProps> = ({
  endDate,
  startDate,
  className,
  color = "primary"
}) => (
  <Chip
    className={className}
    label={getStatus({ startDate, endDate, className })}
    // color="primary"
    color={color}
  />
);

export default PolicyStatusChip;
