import React, { useEffect } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import moment from "moment";
import {
  selectSelectedClaim,
  selectSelectedClaimQuote,
  selectSelectedPolicyPDF
} from "../slice/selectors";
import { Link, Skeleton, Typography } from "@mui/material";
import styled from "styled-components";
import PolicyStatusChip from "../../PolicyStatusChip";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux-hooks";
import { fetchPolicyPDF } from "../slice/thunks";
import { Traveller } from "../../../types/quote-types";

interface PolicySectionProps {}

const PolicySectionContainer = styled(Container)`
  &.policy-section {
    padding: 0;
    grid-area: policy-section;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & .policy-title-container {
      display: flex;
      align-items: center;

      & > * {
        display: inline-block;
      }

      & > .policy-chip {
        border-radius: 16px;
        margin-left: 10px;
        min-width: 50px;
        height: 30px;
      }

      & > .policy-title {
        min-width: 70px;
        height: 36px;
      }
    }

    & label {
      display: block;
    }
    & .MuiChip-root {
      height: 18px;
      font-weight: 500;
      margin-left: 10px;
      transform: translateY(-2px);
    }

    & .policy-details-container {
      & .policy-skeleton-line {
        width: 250px;
      }
    }

    & .skeleton-see-full-details {
      width: 150px;
    }
  }

  & .details-link {
    text-transform: none;
    text-decoration: none;
    font-size: 14px;
  }
`;

const PlaceHolder = () => {
  return (
    <PolicySectionContainer className="policy-section">
      <Box className="policy-title-container">
        <Skeleton className="policy-title" />
        <Skeleton className="policy-chip" />
      </Box>
      <Box className="policy-details-container">
        <Skeleton className="policy-skeleton-line" />
        <Skeleton className="policy-skeleton-line" />
        <Skeleton className="policy-skeleton-line" />
      </Box>
      <Skeleton className="skeleton-see-full-details" />
    </PolicySectionContainer>
  );
};

const PolicySection: React.FC<PolicySectionProps> = () => {
  const selectedClaimQuote = useAppSelector(selectSelectedClaimQuote);
  const selectedClaim = useAppSelector(selectSelectedClaim);
  const selectedPolicyPDF = useAppSelector(selectSelectedPolicyPDF);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (selectedClaimQuote) {
      dispatch(fetchPolicyPDF({ policyId: selectedClaimQuote!.userPolicyId }));
    }
  }, [dispatch, selectedClaim, selectedClaimQuote]);

  if (selectedClaimQuote === undefined) {
    return <PlaceHolder />;
  } else if (selectedClaimQuote === null) {
    return (
      <Typography
        component="div"
        sx={{ display: "flex", alignItems: "center" }}
      >
        <Typography component="p">
          There is no policy connected to this claim.
        </Typography>
      </Typography>
    );
  }

  const getTravelerName = (code: string, travellers: Traveller[]) => {
    if (travellers && code) {
      const traveler = travellers.find((t) => {
        return t.userPolicyUniqueUUID === code;
      });
      if (traveler) {
        return `${traveler.firstName} ${traveler.lastName}`;
      }
    }
    return "";
  };

  return (
    <PolicySectionContainer className="policy-section">
      <Box className="policy-title-container">
        <h3>Policy</h3>
        <PolicyStatusChip
          startDate={selectedClaimQuote!.effectiveStartDate}
          endDate={selectedClaimQuote!.effectiveEndDate}
        />
      </Box>
      <Box className="policy-details-container">
        <label>{selectedClaimQuote?.userPolicyUniqueUUID}</label>
        <label>
          Traveler{" "}
          {getTravelerName(
            selectedClaimQuote?.userPolicyUniqueUUID,
            selectedClaimQuote?.travellers
          )}
        </label>
        <label>
          {moment(selectedClaimQuote?.effectiveStartDate).format("MM.D.YYYY")}-
          {moment(selectedClaimQuote?.effectiveEndDate).format("MM.D.YYYY")}
        </label>
        <label>
          Traveling To{" "}
          {selectedClaimQuote?.destinations
            .map((item) => item.label)
            .join(", ")}
        </label>
      </Box>
      <Link className="details-link" href={selectedPolicyPDF} target={"_blank"}>
        View Policy
      </Link>
    </PolicySectionContainer>
  );
};

export default PolicySection;
