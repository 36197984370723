import React, { useCallback, useState } from "react";

type InputType = string | number;

const useInput = <T extends InputType>(
  initialValue: T,
  validationCallback?: (...args: any[]) => boolean
) => {
  const [value, setValue] = useState<T>(initialValue);
  const [valid, setValid] = useState<boolean>(true);

  const handleChange = useCallback(
    (e: React.BaseSyntheticEvent) => {
      let newValue: InputType = e.target.value;
      if (typeof initialValue === "number") {
        newValue = Number.parseFloat(e.target.value || 0);
      }
      setValue(newValue as T);
      if (validationCallback) {
        const isValid = validationCallback(e.target.value.toString());
        setValid(isValid);
      }
    },
    [validationCallback, initialValue]
  );

  const resetInput = useCallback(() => {
    setValue(initialValue);
  }, [initialValue]);

  return { value, onChange: handleChange, valid, resetInput };
};

export default useInput;
