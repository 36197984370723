import React, { useCallback, useEffect } from "react";
import useInput from "../hooks/useInput";
import { Alert, Button, TextField } from "@mui/material";
import styled from "styled-components";
import Container from "@mui/material/Container";
import {
  signInUsingEmailPassword,
  signInWithGoogle
} from "../components/authentication/slice/thunks";
import { ReactComponent as GoogleGLogo } from "../assets/svg/btn_google_light_normal_ios.svg";
import {
  selectAuthError,
  selectToken
} from "../components/authentication/selectors";
import { useHistory } from "react-router-dom";
import { POLICIES_URL } from "./constants";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import FormControl from "@mui/material/FormControl";

const GOOGLE_G_LOGO_CLASS = "google-g-logo";
const LOGIN_WITH_GOOGLE_BUTTON_CLASS = "login-with-google-button";
const SIGN_IN_BUTTON_CLASS = "sign-in-button";
const LOGIN_CONTENT_CLASS = "login-content";
const ALERT_AUTH_ERROR_MESSAGE = "MuiAlert-root";

const LoginContainer = styled((props) => <Container {...props} />)`
  height: 100%;
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 2fr;
  grid-template-areas:
    ". . ."
    ". content ."
    ". . .";

  & .${LOGIN_CONTENT_CLASS} {
    grid-area: content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & .${LOGIN_WITH_GOOGLE_BUTTON_CLASS} {
      margin-bottom: 20px;

      & .${GOOGLE_G_LOGO_CLASS} {
        height: 28px;
        width: 28px;
        margin-right: 10px;
      }
    }

    & .${SIGN_IN_BUTTON_CLASS} {
      margin-top: 20px;
    }
    & .${ALERT_AUTH_ERROR_MESSAGE} {
      margin-top: 30px;
    }
  }
`;

const Login = () => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector(selectToken);
  const authError = useAppSelector(selectAuthError);
  const history = useHistory();

  const { value: email, onChange: onChangeEmail } = useInput("");
  const { value: password, onChange: onChangePassword } = useInput("");

  const handleUserPasswordLoginClick = useCallback(() => {
    dispatch(
      signInUsingEmailPassword({
        email: email.toString(),
        password: password.toString()
      })
    );
  }, [dispatch, email, password]);

  const handleLoginWithGoogleClick = useCallback(() => {
    dispatch(signInWithGoogle());
  }, [dispatch]);

  useEffect(() => {
    if (accessToken) {
      history.push(POLICIES_URL);
    }
  }, [history, accessToken]);

  return (
    <LoginContainer>
      <FormControl className={LOGIN_CONTENT_CLASS} component="form">
        <h1>Sign in</h1>
        <Button
          variant="outlined"
          onClick={handleLoginWithGoogleClick}
          className={LOGIN_WITH_GOOGLE_BUTTON_CLASS}
        >
          <GoogleGLogo className={GOOGLE_G_LOGO_CLASS} />
          Login With Google
        </Button>
        <TextField
          value={email}
          onChange={onChangeEmail}
          label="Email"
          type="email"
        />
        <TextField
          value={password}
          onChange={onChangePassword}
          label="Password"
          type="password"
          autoComplete="on"
        />
        <Button
          variant="contained"
          onClick={handleUserPasswordLoginClick}
          className={SIGN_IN_BUTTON_CLASS}
        >
          Login
        </Button>
        {authError && (
          <Alert variant="outlined" severity="error">
            {`Authentication Error: ${authError}`}
          </Alert>
        )}
      </FormControl>
    </LoginContainer>
  );
};

export default Login;
