import { RootState } from "../../../types/redux-types";

export const selectClaims = (state: RootState) => state.claims.claims;
export const selectNewClaimModalOpen = (state: RootState) =>
  state.claims.createClaimModal.isOpen;
export const selectConnectPolicyModal = (state: RootState) =>
  state.claims.connectPolicyModal;
export const selectPagination = (state: RootState) => state.claims.pagination;
export const selectClaimSearchText = (state: RootState) =>
  state.claims.claimSearchText;
export const selectSelectedClaim = (state: RootState) =>
  state.claims.selectedClaim;
export const selectSelectedClaimPolicy = (state: RootState) =>
  state.claims.selectedClaimPolicy;
export const selectSelectedClaimQuote = (state: RootState) =>
  state.claims.selectedClaimQuote;
export const selectSelectedClaimUser = (state: RootState) =>
  state.claims.selectedClaimUser;
export const selectSelectedClaimChat = (state: RootState) =>
  state.claims.selectedClaimChat;
export const selectSelectedClaimComments = (state: RootState) =>
  state.claims.selectedClaimComments;
export const selectSelectedClaimPayments = (state: RootState) =>
  state.claims.selectedClaimPayments;
export const selectSelectedClaimHistory = (state: RootState) =>
  state.claims.selectedClaimHistory;
export const selectSelectedClaimDocuments = (state: RootState) =>
  state.claims.selectedClaimDocuments;
export const selectSelectedDocumentLink = (state: RootState) =>
  state.claims.currentDocument;
export const selectSelectedPolicyPDF = (state: RootState) =>
  state.claims.selectedClaimPolicyPDF;
export const selectClaimCommentText = (state: RootState) =>
  state.claims.claimCommentText;
export const tabIsLoading = (state: RootState) => state.claims.tabIsLoading;
