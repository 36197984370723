import { createSlice } from "@reduxjs/toolkit";

interface IModalSlice {
  genericModal: IGenericModal | null;
}
interface IGenericModal {
  confirmText?: string;
  rejectText?: string;
  message: string;
  title?: string;
  type?: string;
}

const initialState: IModalSlice = {
  genericModal: null
};

const modalSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    openGenericModal: (
      state: IModalSlice,
      { payload }: { payload: IGenericModal }
    ) => {
      state.genericModal = payload;
    },
    closeGenericModal: (state: IModalSlice) => {
      state.genericModal = null;
    }
  }
});

export const { openGenericModal, closeGenericModal } = modalSlice.actions;

export default modalSlice.reducer;
