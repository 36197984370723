import React, { useEffect, useState } from "react";
import classnames from "classnames";
import styled from "styled-components";
import TabPanel from "../../../../TabPanel";
import { IUserPolicyInfoDto } from "../../../../../types/DTO/IUsersInfoDto";
import {
  useAppDispatch,
  useAppSelector
} from "../../../../../hooks/redux-hooks";
import { selectSelectedClaimHistory } from "../../../../claims/slice/selectors";
import { fetchClaimHistory } from "../../../../claims/slice/thunks";
import {
  History,
  HistoryMetaData
} from "../../../../claims/ClaimPreview/Tabs/HistoryTab";
import HistoryItem from "../../../../claims/ClaimPreview/Tabs/HistoryTab/HistoryItem";
import { Box } from "@mui/material";

const HistoryTabContainer = styled(TabPanel)`
  &.history-tab {
    overflow: auto;
    & > .history-list {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 10px;
    }
  }
`;

interface UserHistoryTabProps {
  selectedPolicy: IUserPolicyInfoDto;
}

const HistoryTab: React.FC<UserHistoryTabProps> = ({ selectedPolicy }) => {
  const customerHistory: History[] = useAppSelector(selectSelectedClaimHistory);
  const [historyMetaData, setHistoryMetaData] =
    useState<HistoryMetaData | null>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      fetchClaimHistory({ tableId: selectedPolicy.userId, eventType: "user" })
    );
  }, [dispatch, selectedPolicy]);

  useEffect(() => {
    if (!customerHistory || customerHistory.length <= 0)
      setHistoryMetaData(null);
    else if (customerHistory.length > 0) {
      setHistoryMetaData(customerHistory[0].metaData);
    }
  }, [customerHistory]);

  return (
    <HistoryTabContainer
      currTabIndex={4}
      tabIndex={4}
      isHidden={Boolean(!selectedPolicy)}
      className={classnames("history-tab", "tab-container")}
    >
      <Box className={"history-list"}>
        {historyMetaData ? (
          Object.keys(historyMetaData).map((key) => {
            return (
              <HistoryItem
                metaData={historyMetaData[key]}
                name={key}
                key={key}
              />
            );
          })
        ) : (
          <></>
        )}
      </Box>
    </HistoryTabContainer>
  );
};

export default HistoryTab;
