import React from "react";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import moment from "moment";
import {
  selectSelectedClaimPolicy,
  selectSelectedClaimUser
} from "../slice/selectors";
import { Skeleton } from "@mui/material";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux-hooks";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import { setDisplayPolicy, setSelectedPolicy } from "components/policies/slice";
import { POLICIES_URL } from "../../../Routes/constants";
import { IUserPolicyInfoDto } from "../../../types/DTO/IUsersInfoDto";
import { resetSelectedClaim } from "../slice";

interface UserSectionProps {}

const ViewPolicyButton = styled(Button)`
  text-transform: none;
  align-self: start;
  :hover {
    background-color: unset;
  }
`;

const UserSectionContainer = styled(Container)`
  &.user-section {
    grid-area: user-section;
    display: flex;
    padding: 0;

    & .user-avatar {
      width: 40px;
      height: 40px;
      margin-top: 5px;
    }

    & .user-section-details {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 14px;

      & .user-name-title {
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
`;

const PlaceHolder = () => {
  return (
    <UserSectionContainer className="user-section">
      <Skeleton variant="circular" className="user-avatar" />
      <Box className="user-section-details">
        <Box>
          <Skeleton className="user-name-title" />
          <Skeleton variant="text" />
        </Box>
      </Box>
    </UserSectionContainer>
  );
};

const UserSection: React.FC<UserSectionProps> = () => {
  const selectedClaimUser = useAppSelector(selectSelectedClaimUser);
  const selectedClaimPolicy = useAppSelector(selectSelectedClaimPolicy);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const redirectToPolicyPage = () => {
    dispatch(setSelectedPolicy(selectedClaimPolicy as IUserPolicyInfoDto));
    dispatch(resetSelectedClaim());
    dispatch(setDisplayPolicy(true));
    history.push(POLICIES_URL);
  };

  if (!selectedClaimUser) {
    return <PlaceHolder />;
  }

  const button = selectedClaimPolicy ? (
    <ViewPolicyButton onClick={redirectToPolicyPage} variant="text">
      Go to Policy
    </ViewPolicyButton>
  ) : null;

  return (
    <UserSectionContainer className="user-section">
      <Avatar
        src={selectedClaimUser?.profilePicture!}
        className="user-avatar"
      />
      <Box className="user-section-details">
        <Box>
          <h3 className="user-name-title">{selectedClaimUser?.nickName}</h3>
          <label className="user-joined-details">
            {`Joined ${moment(selectedClaimUser?.created).format(
              "MMM Do, YYYY"
            )}`}
          </label>
        </Box>
        {button}
      </Box>
    </UserSectionContainer>
  );
};

export default UserSection;
