import React from "react";
import classnames from "classnames";
import styled from "styled-components";
import Box from "@mui/material/Box";
import { TABLE_ROW_HEIGHT } from "../common";
import { selectSelectedRowIndex } from "../../../../components/collectionTable/selectors";
import { useAppSelector } from "../../../../hooks/redux-hooks";

const ClaimIdContainer = styled(Box)`
  height: ${TABLE_ROW_HEIGHT}px; !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: normal;
  
  & label {
    font-size: 13px;
    line-height: 19px;
    
    &.cell-value {
      color: #959493;
      font-weight: 500;
    }

  }
  
  &.selected-row{
    & label.cell-value {
      color: #434140;
    }
  }
`;

interface CommonCellRendererProps {
  value: any;
  rowIndex: number;
}

const CommonCellRenderer: React.FC<CommonCellRendererProps> = ({
  value,
  rowIndex
}) => {
  const selectedRowIndex = useAppSelector(selectSelectedRowIndex);
  return (
    <ClaimIdContainer
      className={classnames({ "selected-row": selectedRowIndex === rowIndex })}
    >
      <label className="cell-value">{value}</label>
    </ClaimIdContainer>
  );
};
export default React.memo(CommonCellRenderer);
