import React from "react";
import styles from "./SpinerLoader.module.css";
import { ILoaderDTO } from "../ILoaderDTO";
function SpinnerLoader(props: ILoaderDTO) {
  const loader = props.isLoading ? (
    <div className={styles.spinnerLoader}></div>
  ) : null;
  return loader;
}

export default SpinnerLoader;
