import React, { useState } from "react";
import styled from "styled-components";
import Container from "@mui/material/Container";
import { Box } from "@mui/material";
import { IWeatherTile } from "../../types/DTO/IMobileLocationTilesDto";
import WeatherLines from "./WeatherLine";

interface WeatherWidgetProps {
  weather: IWeatherTile;
}

const WeatherWidgetContainer = styled(Container)`
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.13);
  border-radius: 12px;
  padding: 16px;

  & .forecast-days {
    color: gray;
    margin-top: 16px;
    padding-bottom: 16px;
  }

  & .weather-pointer {
    cursor: pointer;
  }
`;

const WeatherWidget: React.FC<WeatherWidgetProps> = ({ weather }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <WeatherWidgetContainer>
      {!isOpen && (
        <Box className="weather-pointer" onClick={() => setIsOpen(true)}>
          <Box className="temperature">
            {weather.weatherInfo[0].temperature.fahrenheit}&#176;F
          </Box>
          <Box className="forecast-days">
            {weather.forecastDays}-days forecast
          </Box>
        </Box>
      )}

      {isOpen && (
        <Box className="weather-pointer" onClick={() => setIsOpen(false)}>
          <WeatherLines weatherInfos={weather.weatherInfo}></WeatherLines>
        </Box>
      )}
    </WeatherWidgetContainer>
  );
};

export default React.memo(WeatherWidget);
