import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import styled from "styled-components";
import { IDocDto } from "types/DTO/IDocDto";
import { format } from "date-fns";
import { openDocument } from "components/claims/slice/thunks";
import { useAppDispatch } from "hooks/redux-hooks";
import DownloadIcon from "@mui/icons-material/Download";

interface SingleDocProps {
  doc: IDocDto;
}

const SingleDocContainer = styled(Box)`
  &.single-doc-container {
    display: flex;
    flex-direction: column;
    gap: 4px;

    & .single-doc {
      display: flex;
      font-size: 12px;
      align-items: center;
      padding: 0 16px;
      width: calc(100% - 1.2em);

      & .doc-item {
        align-self: flex-start;
        flex: 1;
      }
    }
  }
`;

function openInNewTab(url: string) {
  const win = window.open(url, "_blank");
  if (win !== null) {
    win.focus();
  }
}

const SingleDoc: React.FC<SingleDocProps> = ({ doc }) => {
  const dispatch = useAppDispatch();

  const getDocumentLink = () => {
    dispatch(openDocument({ Key: doc?.media?.key })).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        openInNewTab(res.payload);
      }
    });
  };

  return (
    <SingleDocContainer className="single-doc-container">
      <Box className="single-doc">
        <Typography className="doc-item">
          {format(new Date(doc?.created), "MM/dd/yyyy HH:mm")}
        </Typography>
        <Typography className="doc-item">
          {doc?.media?.key}
          {!!doc.id && (
            <IconButton className={"open-document"} onClick={getDocumentLink}>
              <DownloadIcon />
            </IconButton>
          )}
        </Typography>
      </Box>
    </SingleDocContainer>
  );
};

export default SingleDoc;
