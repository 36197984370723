import { IUserPolicyInfoDto } from "../types/DTO/IUsersInfoDto";
import { getTimeZonelessDate, stringDateToDisplayFormat } from "./dates";
import ApiWrapper from "./ApiWrapper";

enum userTripStatusEnum {
  inTrip = "inTrip",
  futureTrip = "futureTrip",
  noFutureTrip = "noFutureTrip",
  postTrip = "postTrip"
}

const userTripStatusDict = {
  inTrip: "in-trip",
  futureTrip: "pre-trip",
  noFutureTrip: "post-trip",
  postTrip: "post-trip"
};

export const createCustomerDetailsFromPolicy = (policy: IUserPolicyInfoDto) => {
  let customer = {
    id: 0,
    firstName: "Unknown",
    lastName: "Unknown",
    dateOfBirth: "",
    tripStatus: "",
    email: "",
    phone: "",
    profilePicture: {
      Bucket: "",
      ETag: "",
      Key: "",
      key: "",
      url: ""
    },
    lastLogin: "never",
    address: {
      zip: "",
      city: "",
      street: "",
      country: "",
      state: ""
    }
  };
  if (!!policy.user && !!policy.user.id) {
    const {
      id,
      firstName,
      lastName,
      email,
      dateOfBirth,
      mobile: phoneNumber,
      userTripStatus: tripStatus
    } = policy.user;

    const address = {
      zip: !!policy.user?.zip ? policy.user.zip : "",
      city: !!policy.user?.city ? policy.user.city : "",
      street: !!policy.user?.street ? policy.user.street : "",
      country: !!policy?.user?.country ? policy.user.country : "",
      state: !!policy.user?.state ? policy.user.state : ""
    };
    customer = {
      id,
      firstName,
      lastName,
      email,
      dateOfBirth: dateOfBirth
        ? stringDateToDisplayFormat(getTimeZonelessDate(new Date(dateOfBirth)))
        : "",
      phone: !!phoneNumber ? phoneNumber : "",
      address,
      tripStatus: userTripStatusDict[tripStatus],
      profilePicture: {
        Bucket: "",
        ETag: "",
        Key: "",
        key: "",
        url: policy?.imgUrl?.url ? policy.imgUrl.url : ""
      },
      lastLogin: "never"
    };
  } else if (policy.traveler) {
    const firstName = !!policy.traveler?.firstName
      ? policy.traveler?.firstName
      : "unknown";
    const lastName = !!policy.traveler?.lastName
      ? policy.traveler?.lastName
      : "unknown";
    const dateOfBirth = !!policy.traveler?.dateOfBirth
      ? stringDateToDisplayFormat(
          getTimeZonelessDate(new Date(policy.traveler.dateOfBirth))
        )
      : "";
    const tripStatus = policy?.tripStatus
      ? policy.tripStatus.replaceAll(" ", "-").toLowerCase()
      : "in-trip";
    customer = {
      ...customer,
      firstName,
      lastName,
      dateOfBirth: dateOfBirth,
      tripStatus
    };
  }
  return customer;
};

export const convertToTripStatusToProperFormat = (
  tripStatus: userTripStatusEnum
) => {
  return userTripStatusDict[tripStatus];
};

export const getAllImgUrlFromImgObjArr = async (arr: IUserPolicyInfoDto[]) => {
  const modifiedArr = [...arr];
  for (const objWithImg of modifiedArr) {
    if (!!objWithImg.imgUrl && !!objWithImg.imgUrl.key) {
      objWithImg.imgUrl["url"] = await getPictureUrl(objWithImg.imgUrl.key);
    }
  }
  return modifiedArr;
};

export const getPictureUrl = async (url: string): Promise<string> => {
  try {
    return await ApiWrapper.request<string>(
      `/file-manager/file/signed/${url}`,
      "GET"
    );
  } catch (e) {
    return "";
  }
};

export const fetchGroupedPolicies = async (
  policyId: number
): Promise<IUserPolicyInfoDto[] | undefined> => {
  try {
    return await ApiWrapper.request<IUserPolicyInfoDto[]>(
      `/policy/userPolicy/group/${policyId}`,
      "GET"
    );
  } catch (e) {
    return;
  }
};
