export const validateEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validateLettersAndNumbers = (str: string) => {
  const re = /^[a-zA-Z0-9]+$|^[a-zA-Z]+$/;
  return re.test(String(str).toLowerCase());
};

export const validateNumbers = (str: string) => {
  const re = /^[0-9]*$/;
  return re.test(str);
};

export const validateBirthYearStr = (str: string) => {
  if (!str || !str.length) {
    return false;
  }
  if (+str < 1920 || +str > +new Date().getFullYear()) {
    return false;
  }
  return true;
};
export const validateDayStr = (str: string) => {
  if (!str || !str.length) {
    return false;
  }
  if (+str < 1 || +str > 31) {
    return false;
  }
  return true;
};
export const validateMonthStr = (str: string) => {
  if (!str || !str.length) {
    return false;
  }
  if (+str < 1 || +str > 12) {
    return false;
  }
  return true;
};
export const validateName = (str: string) => {
  return !!str && str.length >= 2;
};
