import React, { useState } from "react";
import styled from "styled-components";
import Container from "@mui/material/Container";
import { Box, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { ICovidResponse } from "../../types/DTO/IMobileLocationTilesDto";

interface AverageProps {
  covids: ICovidResponse[];
}

const CovidWidgetContainer = styled(Container)`
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.13);
  border-radius: 12px;
  padding-top: 16px;

  & .summary {
    cursor: pointer;
    & .title {
      & .information {
        color: gray;
        font-size: 14px;
      }
    }

    & .general-summary {
      margin-top: 16px;
      font-size: 14px;
    }
  }

  & .details {
    & .info {
      cursor: pointer;

      & .information {
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        row-gap: 8px;
      }

      & .buttons {
        display: flex;
        flex-direction: row;
        column-gap: 16px;
        margin-top: 16px;

        & .type {
          padding: 4px;
          border: 1px solid black;
          background-color: #ffffff;
        }
      }
    }

    & .from-to {
      display: flex;
      flex-direction: row;
      column-gap: 16px;

      & .destination {
        border: 1px solid black;
        padding: 8px 16px;
        cursor: pointer;
      }

      & .selected {
        background-color: #c8c8c8;
      }
    }
  }
`;

const CovidWidget: React.FC<AverageProps> = ({ covids }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isVaccinated, setIsVaccinated] = useState(false);
  const [selectedDestination, setSelectedDestination] = useState(0);
  const [selectedTab, setSelectedTab] = useState("General");

  const getGeneralSummary = () => {
    const covid = covids[0].tile;
    if (covid.vaccinatedInfo) {
      const general = covid.vaccinatedInfo.find((vc) => {
        return vc.name === "General";
      });
      if (general) {
        return general.summary;
      }
    }
    if (covid.unvaccinatedInfo) {
      const general = covid.unvaccinatedInfo.find((vc) => {
        return vc.name === "General";
      });
      if (general) {
        return general.summary;
      }
    }
  };

  const getDisplay = (type: string) => {
    const covid = covids[selectedDestination].tile;
    let section;
    if (isVaccinated) {
      section = covid.vaccinatedInfo?.find((vc) => {
        return vc.name === selectedTab;
      });
    } else {
      section = covid.unvaccinatedInfo?.find((vc) => {
        return vc.name === selectedTab;
      });
    }
    if (section) {
      if (type === "required" && section) {
        return section.required === undefined
          ? ""
          : section.required
          ? "Required"
          : " Not Required";
      }
      if (type === "summary" && section) {
        return section.summary;
      }
      if (type === "details" && section) {
        return section.details;
      }
      if (type === "forms" && section.forms && section) {
        return section.forms[0].document_url;
      }
    }
    return "";
  };

  const handleIsVaccinatedChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsVaccinated((event.target as HTMLInputElement).value === "true");
  };

  return (
    <CovidWidgetContainer>
      {!isOpen && (
        <Box className="summary" onClick={() => setIsOpen(true)}>
          <Box className="title">
            COVID-19
            <Box className="information">Information</Box>
          </Box>
          <Box className="general-summary">{getGeneralSummary()}</Box>
        </Box>
      )}
      {isOpen && (
        <Box className="details">
          <Box className="from-to">
            <Box
              className={`destination ${
                selectedDestination === 0 ? "selected" : ""
              }`}
              onClick={() => {
                setSelectedDestination(0);
              }}
            >
              From {covids[0].origin} to {covids[0].destination}
            </Box>
            <Box
              className={`destination ${
                selectedDestination === 1 ? "selected" : ""
              }`}
              onClick={() => {
                setSelectedDestination(1);
              }}
            >
              From {covids[1].origin} to {covids[1].destination}
            </Box>
          </Box>
          <Box className="info">
            <Box>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={isVaccinated}
                onChange={handleIsVaccinatedChange}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="Vaccinated"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="UnVaccinated"
                />
              </RadioGroup>
            </Box>
            <Box className="buttons">
              <Box
                className={`type ${selectedTab === "" ? "selected" : "Test"}`}
                onClick={() => {
                  setSelectedTab("Test");
                }}
              >
                Test
              </Box>
              <Box
                className={`type ${
                  selectedTab === "" ? "selected" : "MedicalProof"
                }`}
                onClick={() => {
                  setSelectedTab("MedicalProof");
                }}
              >
                Medical
              </Box>
              <Box
                className={`type ${
                  selectedTab === "" ? "selected" : "General"
                }`}
                onClick={() => {
                  setSelectedTab("General");
                }}
              >
                General
              </Box>
              <Box
                className={`type ${
                  selectedTab === "" ? "selected" : "Quarantine"
                }`}
                onClick={() => {
                  setSelectedTab("Quarantine");
                }}
              >
                Quarantine
              </Box>
              <Box
                className={`type ${selectedTab === "" ? "selected" : "Forms"}`}
                onClick={() => {
                  setSelectedTab("Forms");
                }}
              >
                Forms
              </Box>
            </Box>
            <Box onClick={() => setIsOpen(false)} className="information">
              <Box>{selectedTab}</Box>
              <Box>{getDisplay("required")}</Box>
              <Box>{getDisplay("summary")}</Box>
              <Box>{getDisplay("details")}</Box>
              <Box>
                <a href={getDisplay("forms")}>{getDisplay("forms")}</a>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </CovidWidgetContainer>
  );
};

export default React.memo(CovidWidget);
