import React from "react";
import styled from "styled-components";
import { useAppDispatch } from "../../../../../hooks/redux-hooks";
import { Box, IconButton, Typography } from "@mui/material";
import {
  deleteDocument,
  fetchClaimDocuments,
  openDocument
} from "../../../slice/thunks";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";

const DocumentContainer = styled(Box)`
  &.document {
    box-shadow: 0 4px 20px rgb(0 0 0 / 13%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    & > .icon-label {
      font-size: 13px;
      font-weight: bold;
    }
  }
`;

interface DocumentProps {
  Key: string;
  id: number;
  claimId: number;
}

function openInNewTab(url: string) {
  const win = window.open(url, "_blank");
  if (win !== null) {
    win.focus();
  }
}

const SingleDocument: React.FC<DocumentProps> = ({ Key, id, claimId }) => {
  const dispatch = useAppDispatch();
  const getDocumentLink = () => {
    dispatch(openDocument({ Key })).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        openInNewTab(res.payload);
      }
    });
  };

  return (
    <DocumentContainer className={"document"}>
      <Typography className={"icon-label"}>{Key}</Typography>
      <Box>
        <IconButton className={"open-document"} onClick={getDocumentLink}>
          <DownloadIcon />
        </IconButton>

        <IconButton
          onClick={() =>
            dispatch(deleteDocument({ docsId: id })).then((res) => {
              if (res.meta.requestStatus === "fulfilled") {
                dispatch(fetchClaimDocuments({ claimId }));
              }
            })
          }
          className={"delete-icon"}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
    </DocumentContainer>
  );
};

export default SingleDocument;
