import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Typography
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux-hooks";
import { cancelPolicyModal } from "../slice/selectors";
import LoadingButton from "@mui/lab/LoadingButton";
import { deleteCustomerPolicy, fetchPolicyTrips } from "../slice/thunks";
import { closeCancelPolicyModal } from "../slice";
import { ITravellerDTO } from "../../../types/DTO/ITravellerDto";
import ApiWrapper from "../../../utils/ApiWrapper";

const CancelPolicyModalContainer = styled(Dialog)`
  & .dialog-container {
    min-width: 600px;
    min-height: 200px;
  }

  & .MuiDialogContent-root {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  & .MuiDialogActions-root {
    padding-top: 20px;
  }
`;

interface CancelPolicyProps { }

const CancelPolicyModal: React.FC<CancelPolicyProps> = () => {
  const dispatch = useAppDispatch();
  const { isOpen, policy, error, requestCompleted } =
    useAppSelector(cancelPolicyModal);
  const [isLoading, setIsLoading] = useState(false);
  const [text, setText] = useState("");

  const [reasonForDisabled, setReasonForDisabled] = useState<string[]>([]);
  const [errorsCheckCompleted, setErrorsCheckCompleted] = useState<boolean>(false);


  const cancelPolicy = async () => {
    setIsLoading(true);
    await dispatch(
      deleteCustomerPolicy({
        policyId: policy.id
      })
    );
    setErrorsCheckCompleted(true);
    setReasonForDisabled([]);
    setIsLoading(false);
    setText('');
  };
  const mainTraveler = useMemo(
    () => {
      const traveler = policy?.travellers?.find((traveler: ITravellerDTO) => traveler.mainTraveller);
      return traveler ?? (policy?.travellers?.length > 0 ? policy.travellers[0] : null);
    }
    ,
    [policy]
  );


  const fetchErrors = useCallback(async (policyId: number) => {
    const errors = await ApiWrapper.request<string[]>(
      `policy/amendment/cancel/${policyId}/validate`,
      "GET"
    );
    return errors;
  }, []);

  useEffect(() => {
    setReasonForDisabled([]);
    setIsLoading(false);
    setText('');
    setErrorsCheckCompleted(false);
    if (policy?.id) {
      fetchErrors(policy.id).then((errors) => {
        setReasonForDisabled(errors);
        setErrorsCheckCompleted(true);
      });
    }
  }, [fetchErrors, policy]);


  const closeModal = () => {
    if (isLoading) {
      return;
    }
    if (requestCompleted && !error) {
      dispatch(fetchPolicyTrips({ userId: policy.user_id }));
    }
    setText("");
    dispatch(closeCancelPolicyModal());
  };
  const refundAmount = !!policy ? policy?.cost : null;
  useEffect(() => {
    if (errorsCheckCompleted) {
      const textForDisplay = reasonForDisabled?.length > 0
        ? "Policy validation errors: "
        : requestCompleted && !error
          ? "Policy successfully canceled."
          : !!error
            ? "Error unable to cancel policy: "
            : `Canceling this trip will issue a refund of $${refundAmount} to ${mainTraveler?.firstName} ${mainTraveler?.lastName}.`;
      setText(textForDisplay);
    }
  }, [
    policy,
    reasonForDisabled,
    error,
    requestCompleted,
    mainTraveler,
    refundAmount,
    errorsCheckCompleted
  ]);

  const buttons = requestCompleted && errorsCheckCompleted ? (
    <Button variant="contained" onClick={closeModal}>
      close
    </Button>
  ) : (
    <>
      <Button disabled={isLoading} onClick={closeModal} variant="contained">
        Do not cancel
      </Button>
      <LoadingButton
        variant="contained"
        onClick={cancelPolicy}
        loading={isLoading}
        disabled={isLoading}
      >
        {`Cancel & refund $${refundAmount ? Number(refundAmount).toFixed(2) : ''}`}
      </LoadingButton>
    </>
  );

  return (
    <CancelPolicyModalContainer open={isOpen} onClose={closeModal}>
      {!!policy && text?.length > 0 && (
        <div className="dialog-container">
          <DialogTitle>
            Cancel policy {policy?.userPolicyUniqueUUID}
          </DialogTitle>
          <DialogContent>
            <Typography component="div">
              <div>{text}</div>
              {reasonForDisabled?.length > 0 && (
                reasonForDisabled
                  .map((err: any, index: number) => <div key={index}>{err}</div>)

              )}
            </Typography>
            {!error && !requestCompleted && !reasonForDisabled && (
              <Typography>Are you sure?</Typography>
            )}
            {!!error && requestCompleted && <Typography>{error}</Typography>}
            <DialogActions>{buttons}</DialogActions>
          </DialogContent>
        </div>
      )}
    </CancelPolicyModalContainer>
  );
};

export default CancelPolicyModal;
