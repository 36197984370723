import axios, { AxiosRequestConfig, AxiosResponse, Method } from "axios";

const API_URL = process.env.REACT_APP_API_URL;
const REFRESH_TOKEN_URL = `https://securetoken.googleapis.com/v1/token?key=${process.env.REACT_APP_FIREBASE_API_KEY}`;

export const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json"
  }
});

export const axiosRefreshTokenInstance = axios.create({
  baseURL: REFRESH_TOKEN_URL
});

class ApiWrapper {
  Authorization = "";

  getHeaders = (options?: AxiosRequestConfig) => {
    const authorization = this.Authorization ?? options?.headers?.Authorization;
    const headers = authorization
      ? { ...options?.headers, ...{ Authorization: authorization } }
      : options?.headers;
    return { headers };
  };

  put = async (path: string, options?: AxiosRequestConfig) => {
    const { data, ...rest } = { data: {}, ...options };
    return await axiosInstance.put(path, data, rest);
  };

  post = async (path: string, options?: AxiosRequestConfig) => {
    const { data, ...rest } = { data: {}, ...options };
    return await axiosInstance.post(path, data, rest);
  };

  get = async (path: string, options?: AxiosRequestConfig) => {
    const { data, ...rest } = { data: {}, ...options };
    return await axiosInstance.get(path, { ...rest, data });
  };

  delete = async (path: string, options?: AxiosRequestConfig) => {
    const { data } = { data: {}, ...options };
    return await axiosInstance.delete(path, data);
  };

  patch = async (path: string, options?: AxiosRequestConfig) => {
    const { data, ...rest } = { data: {}, ...options };
    return await axiosInstance.patch(path, { ...rest, data });
  };

  async request<T>(
    path: string,
    method: Method,
    options: AxiosRequestConfig = this.getHeaders({})
  ) {
    if (options?.headers?.Authorization && !this.Authorization) {
      this.Authorization = options.headers.Authorization;
    }
    const response: AxiosResponse<T> = await // @ts-ignore
    this[method.toLowerCase()](path, options);
    return response.data;
  }
}

export default new ApiWrapper();
