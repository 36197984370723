import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import SingleDocs from "../single-doc";
import { IUserPolicyInfoDto } from "types/DTO/IUsersInfoDto";
import { useAppDispatch, useAppSelector } from "hooks/redux-hooks";
import {
  documentsCreationStatuses,
  selectSelectedPolicyDocs
} from "../../../../slice/selectors";
import { IDocDto } from "types/DTO/IDocDto";
import { fetchPolicyDocs } from "../../../../slice/thunks";

interface DocsListProps {
  selectedCustomer?: IUserPolicyInfoDto;
}

const DocsListContainer = styled(Box)`
  &.doc-list-container {
    display: flex;
    flex-direction: column;
    gap: 4px;

    & > h4 {
      font-size: 12px;
      font-weight: bold;
    }

    & > .doc-list-headers {
      display: flex;
      align-items: center;
      padding: 0 16px;
      width: calc(100% - 1.2em);

      & > * {
        font-size: 12px;
        font-weight: bold;
        flex: 1;
        align-self: flex-start;
      }
    }
  }
`;

const DocsList: React.FC<DocsListProps> = ({ selectedCustomer }) => {
  const dispatch = useAppDispatch();
  const docs = useAppSelector(selectSelectedPolicyDocs);
  const tempDocumentsStatuses = useAppSelector(documentsCreationStatuses);
  const [needsUpdate, setNeedsUpdate] = useState(false);
  const [tempDocuments, setTempDocuments] = useState<IDocDto[]>([]);

  useEffect(() => {
    const finishLoading = !tempDocumentsStatuses.find(
      (tempDoc) => tempDoc.status === "uploading"
    );
    const type = ["summery", "wording"];
    if (!!docs && !!tempDocumentsStatuses.length && !finishLoading) {
      const statusDictionary = {
        success: " successfully uploaded",
        failed: " failed to uploaded",
        uploading: " is being uploaded.."
      };
      const tempDocs = tempDocumentsStatuses.map((tempDoc, index) => {
        return {
          id: 0,
          created: new Date(),
          media: {
            key: `${type[index % 2]} ${statusDictionary[tempDoc.status]}`
          },
          type: `${Math.random()}`
        };
      });
      setTempDocuments(tempDocs);
      setNeedsUpdate(true);
    } else {
      if (!!selectedCustomer && finishLoading && needsUpdate) {
        setTempDocuments([]);
        setNeedsUpdate(false);
        dispatch(fetchPolicyDocs({ userId: selectedCustomer.userId }));
      }
    }
  }, [dispatch, needsUpdate, docs, tempDocumentsStatuses, selectedCustomer]);

  if (!tempDocuments.length && (!docs || docs.length <= 0))
    return (
      <DocsListContainer className="doc-list-container">
        <Typography variant="h4">No documents</Typography>
      </DocsListContainer>
    );

  return (
    <DocsListContainer className="doc-list-container">
      <Box className="doc-list-headers">
        <Typography>Create</Typography>
        <Typography>File Name</Typography>
      </Box>
      {tempDocuments?.map((doc) => {
        return <SingleDocs key={doc.type + doc.id} doc={doc} />;
      })}
      {!!docs &&
        docs?.map((doc: any) => {
          return <SingleDocs key={doc.id} doc={doc} />;
        })}
    </DocsListContainer>
  );
};

export default DocsList;
