import React from "react";
import { GRID_AREA_SECTION_VIEW } from "../../constants/common";
import Box from "@mui/material/Box";
import { useAppSelector } from "../../hooks/redux-hooks";
import { selectSelectedUser } from "./slice/selectors";
import styled from "styled-components";
import Container from "@mui/material/Container";
import UserCard from "./AdvisorCard";

const CLASS_CUSTOMER_VIEW_CONTAINER = "customer-view-container";

interface AdvisorViewProps {}

const AdvisorViewContainer = styled(Container)`
  &.${CLASS_CUSTOMER_VIEW_CONTAINER} {
    height: 100%;
    padding: 15px 26px;
    gap: 20px;
    display: flex;
    flex-direction: column;
  }
`;

const AdvisorView: React.FC<AdvisorViewProps> = () => {
  const selectedUser = useAppSelector(selectSelectedUser);

  return (
    <Box className={GRID_AREA_SECTION_VIEW}>
      {selectedUser && (
        <AdvisorViewContainer className={CLASS_CUSTOMER_VIEW_CONTAINER}>
          <UserCard />
        </AdvisorViewContainer>
      )}
    </Box>
  );
};

export default React.memo(AdvisorView);
