import { IClaim } from "../types/general";
import moment from "moment";
import { IClaimDTO } from "../types/DTO/IClaimDTO";

export const mapClaimDtoToAppClaim = (claim: IClaimDTO): IClaim => ({
  claim: { id: claim.claimId, identifier: claim.claimIdentifier, subject:claim.subject },
  dateCreated: moment(claim.claimCreated).format("LL"),
  name: claim.name,
  subject: claim.subject,
  policyCode: claim.policyCode ?? "",
  searchString: claim.searchString,
  claimStatus: claim.claim_status,
  policyId: claim.policy_id,
  userId: claim.user_id,
  intent: claim.intent
});
