import React, { useEffect } from "react";
import classnames from "classnames";
import styled from "styled-components";
import TabPanel from "../../../../TabPanel";
import { IClaim } from "../../../../../types/general";
import {
  useAppDispatch,
  useAppSelector
} from "../../../../../hooks/redux-hooks";
import { fetchClaimPayments } from "../../../slice/thunks";
import {
  selectSelectedClaimPayments,
  tabIsLoading
} from "../../../slice/selectors";
import { Box } from "@mui/system";
import SinglePayment from "./SinglePayment";
import SpinnerLoader from "../../../../ui/loaders/SpinnerLoader/SpinnerLoader";

const PaymentsTabContainer = styled(TabPanel)`
  &.payments-tab {
    & > .payments-list {
      display: flex;
      flex-direction: column;
      gap: 5px;
      padding: 30px 10px;
      margin-bottom: 20px;
    }
  }
`;

type Payment = {
  amount: string;
  claimId: number;
  created: string;
  currency: string;
  id: number;
  modifierId: number;
  paymentType: string;
  reason: string;
  state: string;
  update: string;
  user_id: number;
};

interface PaymentsTabProps {
  selectedClaim: IClaim;
}

const PaymentsTab: React.FC<PaymentsTabProps> = ({ selectedClaim }) => {
  const dispatch = useAppDispatch();
  const claimPayments = useAppSelector(selectSelectedClaimPayments);
  const isLoading = useAppSelector(tabIsLoading);

  const content = isLoading ? (
    <SpinnerLoader isLoading={true} />
  ) : (
    <Box className="payments-list">
      {claimPayments &&
        claimPayments.map((payment: Payment) => (
          <SinglePayment key={payment.id} payment={payment} />
        ))}
    </Box>
  );

  useEffect(() => {
    dispatch(fetchClaimPayments({ claimId: selectedClaim.claim.id }));
  }, [selectedClaim, dispatch]);

  return (
    <PaymentsTabContainer
      currTabIndex={5}
      tabIndex={5}
      isHidden={Boolean(!selectedClaim)}
      className={classnames("payments-tab", "tab-container")}
    >
      {content}
    </PaymentsTabContainer>
  );
};

export default PaymentsTab;
