import { createSlice } from "@reduxjs/toolkit";
import { Nullable } from "../../../types/general";
import {
  AppUserAuthData,
  AppUserData,
  signInUsingEmailPassword,
  signInWithGoogle
} from "./thunks";
import TokenService from "../../../utils/TokenService";
import { getClaimsPage } from "../../claims/slice/thunks";
import { fetchPolicyPage } from "../../policies/slice/thunks";
import tokenService from "../../../utils/TokenService";

interface IAuthenticationState {
  user: Nullable<AppUserData>;
  authError: Nullable<string>;
}

const initialState: IAuthenticationState = {
  user: TokenService.getUser() as Nullable<AppUserData>,
  authError: null
};

const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    refreshUserTokenState: (
      state: IAuthenticationState,
      { payload: { authData } }: { payload: { authData: AppUserAuthData } }
    ) => {
      state.user!.authData = authData;
    },
    logout: (state: IAuthenticationState) => {
      tokenService.removeUser();
      state.user = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      signInUsingEmailPassword.fulfilled,
      (state: IAuthenticationState, { payload }) => {
        state.user = payload;
      }
    );
    builder.addCase(
      signInWithGoogle.fulfilled,
      (state: IAuthenticationState, { payload }) => {
        state.user = payload;
      }
    );
    builder.addCase(
      signInWithGoogle.rejected,
      (state: IAuthenticationState, { error }) => {
        console.log("error: ", error);
      }
    );
    builder.addCase(
      getClaimsPage.rejected,
      (state: IAuthenticationState, { error }) => {
        state.user = initialState.user;
        state.authError = error.message ?? "Authentication Error";
      }
    );
    builder.addCase(
      fetchPolicyPage.rejected,
      (state: IAuthenticationState, { error }) => {
        state.user = initialState.user;
        state.authError = error.message ?? "Authentication Error";
      }
    );
  }
});

export const { refreshUserTokenState, logout } = authenticationSlice.actions;

export default authenticationSlice.reducer;
