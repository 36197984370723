import React, { useState } from "react";
import styled from "styled-components";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TripsTab from "./Tabs/TripsTab";
import { useAppSelector } from "hooks/redux-hooks";
import { selectSelectedPolicy } from "../slice/selectors";
import PaymentsTab from "./Tabs/PaymentsTab";
import HistoryTab from "./Tabs/HistoryTab";
import UserDocumentsTab from "./Tabs/DocumentsTab";
import UserCommentsTab from "./Tabs/CommentsTab";
import UserClaimsTab from "./Tabs/ClaimsTab";
import Container from "@mui/material/Container";

interface PolicyPreviewProps {}

const PolicyPreviewContainer = styled(Container)`
  &.policy-preview-container {
    padding: 20px 20px 0;

    & button:first-child {
      border-radius: 4px 0 0 4px;
    }

    & button:last-child {
      border-radius: 0 4px 4px 0;
    }
  }

  & .MuiTab-root {
    font-style: normal;
    font-weight: 350;
    font-size: 13px;
    line-height: 14px;
    text-align: center;
    text-transform: capitalize;
    background: #ffffff;
    border: 1px solid #bdbdbd;
    border-radius: 0;
    padding: 0;
    min-height: 32px;
    height: 32px;
  }
  & .selected {
    border: 1px solid var(--primary-color);
    background-color: var(--secondary-color);
  }

  & .MuiTabs-scroller {
    height: 32px;
  }
`;

const NotAUserTextContainer = styled.div`
  margin: 10%;
`;

const PolicyPreview: React.FC<PolicyPreviewProps> = () => {
  const selectedPolicy = useAppSelector(selectSelectedPolicy);
  const [tabIndex, setTabIndex] = useState(0);

  if (selectedPolicy && selectedPolicy.id && selectedPolicy.userId) {
    return (
      <PolicyPreviewContainer className="policy-preview-container">
        <Tabs
          value={tabIndex}
          textColor="primary"
          indicatorColor="primary"
          variant="fullWidth"
          TabIndicatorProps={{
            hidden: true
          }}
        >
          <Tab
            className={`${tabIndex === 0 ? "selected" : ""}`}
            onClick={() => setTabIndex(0)}
            label="Trips"
          />
          <Tab
            className={`${tabIndex === 1 ? "selected" : ""}`}
            onClick={() => setTabIndex(1)}
            label="Claims"
          />
          <Tab
            className={`${tabIndex === 2 ? "selected" : ""}`}
            onClick={() => setTabIndex(2)}
            label="Docs"
          />
          <Tab
            className={`${tabIndex === 3 ? "selected" : ""}`}
            onClick={() => setTabIndex(3)}
            label="Comments"
          />
          <Tab
            className={`${tabIndex === 4 ? "selected" : ""}`}
            onClick={() => setTabIndex(4)}
            label="History"
          />
          <Tab
            className={`${tabIndex === 5 ? "selected" : ""}`}
            onClick={() => setTabIndex(5)}
            label="Payments"
          />
        </Tabs>
        <div className="tabs-content-container">
          {Boolean(selectedPolicy) &&
            (tabIndex === 0 ? (
              <TripsTab
                selectedPolicy={selectedPolicy!}
                selectedTabIndex={0}
                tabIndex={0}
              />
            ) : tabIndex === 1 ? (
              <UserClaimsTab selectedPolicy={selectedPolicy!} />
            ) : tabIndex === 2 ? (
              <UserDocumentsTab selectedPolicy={selectedPolicy!} />
            ) : tabIndex === 3 ? (
              <UserCommentsTab selectedPolicy={selectedPolicy!} />
            ) : tabIndex === 4 ? (
              <HistoryTab selectedPolicy={selectedPolicy!} />
            ) : tabIndex === 5 ? (
              <PaymentsTab selectedPolicy={selectedPolicy!} />
            ) : (
              <div></div>
            ))}
        </div>
      </PolicyPreviewContainer>
    );
  } else {
    return (
      <NotAUserTextContainer>
        <p>No info to display because this customer is not a user</p>
      </NotAUserTextContainer>
    );
  }
};

export default PolicyPreview;
