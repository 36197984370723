import {createSlice} from "@reduxjs/toolkit";
import {fetchDashboard} from "./thunks";
import {IDashboardDto} from "../../../types/DTO/IDashboardDto";
import {Nullable} from "../../../types/general";

interface IDashboardSlice {
    dashboard: Nullable<IDashboardDto>;
}

const initialState: IDashboardSlice = {
    dashboard: null
}

const DashboardSlice = createSlice({
    name: "Dashboard",
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(
            fetchDashboard.fulfilled,
            (state: IDashboardSlice, { payload }) => {
                state.dashboard = payload;
            }
        );
    }
});

/*
export const {

} = DashboardSlice.actions;
*/
export default DashboardSlice.reducer;
