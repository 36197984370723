import React, { useState } from "react";
import { GRID_AREA_SECTION_COLLECTION } from "constants/common";
import styled from "styled-components";
import Container from "@mui/material/Container";
import { Box } from "@mui/material";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId
} from "react-google-places-autocomplete";
import { useAppDispatch, useAppSelector } from "hooks/redux-hooks";
import {
  getWidgets,
  isWidgetsLoading
} from "components/widgets/slice/selectors";
import { fetchWidgetsPerLocationPage } from "components/widgets/slice/thunks";
import { IWidgetsRequest } from "types/DTO/IMobileLocationTilesDto";
import { ClaimDestination } from "types/quote-types";
import Weather from "components/widgets/WeatherWidget";
import Covid from "components/widgets/CovidWidget";
import CreateEmergencyContactFormDialog from "components/widgets/CreateEmergencyContactDialog";
import EmergencyContactWidget from "components/widgets/EmergencyContactWidget";
import SpinnerLoader from "components/ui/loaders/SpinnerLoader/SpinnerLoader";

interface WidgetsPageProps {}

const Widgets = styled(Container)`
  &.${GRID_AREA_SECTION_COLLECTION} {
    font-family: "TWK Lausanne";
    max-height: 93vh;
    max-width: 96vw;
    grid-area: ${GRID_AREA_SECTION_COLLECTION};
    display: grid !important;
    padding: 16px;

    & .tiles {
      display: flex;
      flex-direction: row;
      column-gap: 16px;
    }
  }
`;

const WidgetsPage: React.FC<WidgetsPageProps> = () => {
  const dispatch = useAppDispatch();
  const widgets = useAppSelector(getWidgets);
  const isLoading = useAppSelector(isWidgetsLoading);

  const [searchValue, setSearchValue] = useState(null);

  const addEmergencyContactBtn =
    !isLoading && widgets && !widgets.contactDetails ? (
      <CreateEmergencyContactFormDialog contactDetails={null} />
    ) : null;
  const setCityAndCountry = async (val: any) => {
    let city = "";
    let country = "";
    setSearchValue(val);
    const results = await geocodeByPlaceId(val.value.place_id).catch((error) =>
      console.error(error)
    );
    if (results && results[0] && results[0].address_components) {
      results[0].address_components.forEach((component) => {
        if (component.types.includes("country")) {
          country = component.short_name;
        } else if (
          component.types.includes("postal_town") ||
          component.types.includes("locality")
        ) {
          city = component.long_name;
        }
      });
      if (!!country && !!city) {
        const residency: ClaimDestination = {
          label: "",
          country: "US",
          googleId: "",
          locality: "",
          areaLevel1: ""
        };
        const destination: ClaimDestination = {
          label: "",
          country: country,
          googleId: val.value.place_id,
          locality: city,
          areaLevel1: ""
        };
        const req: IWidgetsRequest = {
          residency: residency,
          destinations: [destination]
        };
        dispatch(fetchWidgetsPerLocationPage({ request: req }));
      }
    }
  };

  return (
    <Widgets className={GRID_AREA_SECTION_COLLECTION}>
      <Box className="search-container">
        <GooglePlacesAutocomplete
          apiKey={process.env.REACT_APP_GOOGLE_PLACES_API_KEY!}
          selectProps={{
            searchValue,
            onChange: setCityAndCountry
          }}
        />
        {addEmergencyContactBtn}
        <Box className="tiles">
          {widgets?.covid && <Covid covids={widgets.covid} />}
          {widgets?.weather.weatherInfo.length && (
            <Weather weather={widgets.weather} />
          )}
          {widgets?.contactDetails && (
            <EmergencyContactWidget contactDetails={widgets.contactDetails} />
          )}
        </Box>
        <SpinnerLoader isLoading={isLoading} />
      </Box>
    </Widgets>
  );
};

export default React.memo(WidgetsPage);
