import React from "react";
import styled from "styled-components";
import Container from "@mui/material/Container";
import { Box } from "@mui/material";
import { MIN_WIDTH } from "../../constants/common";

interface DashboardDataProps {
  text: string;
  amount: number | string;
  delta: string | number;
}

const DashboardDataContainer = styled(Container)`
  &.dashboard-data-container {
    padding-left: 0;

    & .data-description {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 13px;
      margin-bottom: 8px;
    }

    & .amounts-container {
      display: flex;
      flex-direction: row;

      & .amount {
        font-weight: 600;
        color: #4726d1;
        padding-left: 8px;
      }

      & .delta {
        border-radius: 12px;
        padding: 2px 8px;
        height: 22px;
        margin: 0;
        font-size: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @media (min-width: ${MIN_WIDTH}px) {
          font-size: 16px;
          line-height: 18px;
          width: 55px;
          max-width: 55px;
          height: 28px;
          padding: 4px 12px;
        }
      }

      & .delta-positive {
        color: #009d65;
        background-color: #c0ffca;
      }

      & .delta-negative {
        color: #9d0000;
        background-color: #ffcbd7;
      }
    }
  }
`;

const DashboardData: React.FC<DashboardDataProps> = ({
  text,
  amount,
  delta
}) => {
  return (
    <DashboardDataContainer className="dashboard-data-container">
      <Box className="data-description">{text}</Box>
      <Box className="amounts-container">
        <div
          className={`delta ${delta > 0 ? "delta-positive" : "delta-negative"}`}
        >
          <div>{delta}%</div>
        </div>
        <div className="amount">{amount}</div>
      </Box>
    </DashboardDataContainer>
  );
};

export default React.memo(DashboardData);
