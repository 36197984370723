import React from "react";
import styled from "styled-components";
import Container from "@mui/material/Container";
import Skeleton from "@mui/material/Skeleton";
import { SECTION_USER_DETAILS } from "../../constants/common";

const CLASS_SKELETON_BIG_TEXT = "skeleton-big-text";
const CLASS_SKELETON_REGULAR_TEXT = "skeleton-regular-text";

const PlaceHolderContainer = styled(Container)`
  &.${SECTION_USER_DETAILS} {
    & > * {
      width: 70%;
    }
    & .${CLASS_SKELETON_BIG_TEXT} {
      height: 18px;
      margin-bottom: 16px;
    }
    & .${CLASS_SKELETON_REGULAR_TEXT} {
      height: 12px;
      &:not(:last-child) {
        margin-bottom: 14px;
      }
    }
  }
`;

const PlaceHolder: React.FC = () => {
  return (
    <PlaceHolderContainer className={SECTION_USER_DETAILS}>
      <Skeleton component="h3" className={CLASS_SKELETON_BIG_TEXT} />
      <Skeleton component="h3" className={CLASS_SKELETON_REGULAR_TEXT} />
      <Skeleton component="h3" className={CLASS_SKELETON_REGULAR_TEXT} />
    </PlaceHolderContainer>
  );
};

export default PlaceHolder;
