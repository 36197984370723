import { useEffect, useState } from "react";
import { Nullable } from "../types/general";
import { FirebaseApp } from "firebase/app";
import { Analytics } from "firebase/analytics";
import { Auth, GoogleAuthProvider } from "firebase/auth";
import {
  getAnalyticsInstance,
  getAuthInstance,
  getFirebaseInstance,
  getGoogleAuthProviderInstance
} from "../integrations/firebase";

const useFirebase = () => {
  const [firebaseInstance, setFirebaseInstance] =
    useState<Nullable<FirebaseApp>>();
  const [firebaseAnalyticsInstance, setFirebaseAnalyticsInstance] =
    useState<Nullable<Analytics>>();
  const [firebaseAuthInstance, setFirebaseAuthInstance] =
    useState<Nullable<Auth>>();
  const [googleAuthProvider, setGoogleAuthProvider] =
    useState<Nullable<GoogleAuthProvider>>();

  useEffect(() => {
    if (!firebaseInstance) {
      const instance = getFirebaseInstance();
      setFirebaseInstance(instance);
    }
  }, [firebaseInstance]);

  useEffect(() => {
    if (firebaseInstance && !firebaseAnalyticsInstance) {
      const instance = getAnalyticsInstance();
      setFirebaseAnalyticsInstance(instance);
    }
    if (firebaseInstance && !firebaseAuthInstance) {
      const instance = getAuthInstance();
      setFirebaseAuthInstance(instance);
    }
  }, [firebaseInstance, firebaseAnalyticsInstance, firebaseAuthInstance]);

  useEffect(() => {
    if (firebaseAuthInstance && !googleAuthProvider) {
      const instance = getGoogleAuthProviderInstance();
      setGoogleAuthProvider(instance);
    }
  }, [firebaseAuthInstance, googleAuthProvider]);

  return { auth: firebaseAuthInstance, analytics: firebaseAnalyticsInstance };
};

export default useFirebase;
