import React from "react";
import styled from "styled-components";
import Container from "@mui/material/Container";
import { Box } from "@mui/material";
import { IWeatherInfo } from "../../types/DTO/IMobileLocationTilesDto";

interface WeatherLineProps {
  weatherInfos: IWeatherInfo[];
}

const WeatherLineContainer = styled(Container)`
  padding-left: 0 !important;

  & .weather-line-container {
    display: flex;
    flex-direction: row;

    & div {
      flex: 1;
      padding-right: 16px;
    }

    & .temperature {
      display: flex;
      flex-direction: row;
    }

    & .max-fahrenheit {
      margin-left: 8px;
      color: gray;
    }
  }
`;

const WeatherLines: React.FC<WeatherLineProps> = ({ weatherInfos }) => {
  const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const getDayOfWeek = (date: string | Date) => {
    return weekday[new Date(date).getDay()];
  };
  const getFormattedDate = (date: string | Date) => {
    const currentDate = new Date(date);
    return currentDate
      .toLocaleDateString("en-us", { month: "short", day: "numeric" })
      .replace(" ", ", ");
  };
  return (
    <WeatherLineContainer>
      <Box>{weatherInfos[0].suggestion}</Box>
      {weatherInfos?.map((weatherInfo) => (
        <Box
          className="weather-line-container"
          key={new Date(weatherInfo.date).getTime()}
        >
          <Box className="day">{getDayOfWeek(weatherInfo.date)}</Box>
          <Box className="date">{getFormattedDate(weatherInfo.date)}</Box>
          <Box className="icon">{weatherInfo.description}</Box>
          <Box className="temperature">
            {parseInt(weatherInfo.temperature.minFahrenheit.toString())}
            <Box className="max-fahrenheit">
              {parseInt(weatherInfo.temperature.maxFahrenheit.toString())}
            </Box>
          </Box>
        </Box>
      ))}
    </WeatherLineContainer>
  );
};

export default React.memo(WeatherLines);
