import React from "react";
import { IDashboardPoliciesAndRevenues } from "../../types/DTO/IDashboardDto";
import styled from "styled-components";
import Container from "@mui/material/Container";
import { Box } from "@mui/material";
import DashboardData from "./DashboardData";
import {
  calcRatio,
  numberCurrencyFormat,
  safeToFixed
} from "./dashboard-helper";

interface AverageTileProps {
  className: string;
  averagesInfo: IDashboardPoliciesAndRevenues;
}

const AverageTileContainer = styled(Container)`
  & .info {
    display: flex;
  }
`;

const AverageTile: React.FC<AverageTileProps> = ({
  className,
  averagesInfo
}) => {
  return (
    <AverageTileContainer className={className}>
      <Box className={"inner-container inner-container-average"}>
        <Box className="title">
          <span className="tile-name">Average per purchase</span>
        </Box>
        <Box className="amount">
          <span className="tile-amount">
            $
            {numberCurrencyFormat(
              safeToFixed(averagesInfo.allRevenue / averagesInfo.cnt)
            )}
          </span>
        </Box>
        <Box className="info">
          <DashboardData
            text={"Last 7 days"}
            amount={averagesInfo.sevenDays}
            delta={calcRatio(
              averagesInfo.previousSevenDays,
              averagesInfo.sevenDays
            )}
          ></DashboardData>
          <DashboardData
            text={"24hr"}
            amount={averagesInfo.lastDay}
            delta={calcRatio(averagesInfo.sevenDays / 7, averagesInfo.lastDay)}
          ></DashboardData>
        </Box>
      </Box>
    </AverageTileContainer>
  );
};

export default React.memo(AverageTile);
