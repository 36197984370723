import React from "react";
import { IconButton } from "@mui/material";
import { copyToClipboard } from "../../utils/general";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";

interface CopyToClipboardButtonProps {
  value: string;
}

const CopyToClipboardButton: React.FC<CopyToClipboardButtonProps> = ({
  value
}) => {
  const isClipboardApiAvailable = navigator?.clipboard?.writeText;

  if (!isClipboardApiAvailable) return null;

  return (
    <IconButton
      disabled={!value}
      onClick={() => {
        void copyToClipboard(value);
      }}
    >
      <ContentCopyOutlinedIcon />
    </IconButton>
  );
};

export default CopyToClipboardButton;
