import styled from "styled-components";
import Box from "@mui/material/Box";
import { Link, useLocation } from "react-router-dom";
import {
  CLAIMS_URL,
  POLICIES_URL,
  DASHBOARD,
  ADVISORS_URL,
  WIDGETS
} from "../../Routes/constants";

import { ReactComponent as WidgetsIcon } from "../../assets/svg/WidgetsIcon.svg";
import { ReactComponent as DashboardIcon } from "../../assets/svg/DashboardIcon.svg";
import { ReactComponent as AdvisorsIcon } from "../../assets/svg/AdvisorsIcon.svg";
import { ReactComponent as ClaimsIcon } from "../../assets/svg/ClaimsIcon.svg";
import { ReactComponent as PoliciesIcon } from "../../assets/svg/PoliciesIcon.svg";
import { useAppSelector } from "../../hooks/redux-hooks";
import { selectUser } from "../authentication/selectors";

const SideNavContainer = styled((props) => <Box component="div" {...props} />)`
  background-color: var(--navbar-background);
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-row-start: 2;
  grid-row-end: 5;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  clip-path: inset(0px -15px 0px 0px);

  & .selected {
    background-color: var(--secondary-color);
  }

  & .icon {
    border-radius: 50%;
  }

  & .link {
    margin: 20px auto 0 auto;
  }
`;

const SideNav = () => {
  const location = useLocation();
  const userLoggedIn = useAppSelector(selectUser);

  const component = !!userLoggedIn ? (
    <SideNavContainer className="side-nav">
      <Link to={POLICIES_URL} className="link">
        <PoliciesIcon
          className={
            location.pathname === POLICIES_URL ? "selected icon" : "icon"
          }
        />
      </Link>
      <Link to={CLAIMS_URL} className="link">
        <ClaimsIcon
          className={
            location.pathname === CLAIMS_URL ? "selected icon" : "icon"
          }
        />
      </Link>
      <Link to={ADVISORS_URL} className="link">
        <AdvisorsIcon
          className={location.pathname === ADVISORS_URL ? "selected icon" : "icon"}
        />
      </Link>
      <Link to={DASHBOARD} className="link">
        <DashboardIcon
          className={location.pathname === DASHBOARD ? "selected icon" : "icon"}
        />
      </Link>
      <Link to={WIDGETS} className="link">
        <WidgetsIcon
          className={location.pathname === WIDGETS ? "selected icon" : "icon"}
        />
      </Link>
    </SideNavContainer>
  ) : (
    <></>
  );
  return component;
};

export default SideNav;
