import ApiWrapper from "../../../utils/ApiWrapper";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ITilesDto,
  IWidgetsRequest
} from "../../../types/DTO/IMobileLocationTilesDto";
import apiWrapper from "../../../utils/ApiWrapper";
import {
  ICovidWidgetDto,
  IEmergencyContactDto,
  IEmergencyContactWidget,
  IWeatherWidgetDto,
  IWidgets,
  IWidgetsTileDto
} from "../../../types/DTO/IWidgetsDto";

interface IWidgetObj {
  [key: string]:
    | IEmergencyContactDto
    | ICovidWidgetDto
    | IWeatherWidgetDto
    | IEmergencyContactWidget;
}
interface IWidgetsForDisplay {
  [key: string]: IWidgetsTileDto;
}

export const fetchWidgetsPerLocationPage = createAsyncThunk<
  IWidgets,
  { request: IWidgetsRequest }
>("homePageTilesInfo", async ({ request }) => {
  const response = await ApiWrapper.request<ITilesDto>(
    "/mobile-home/location",
    "POST",
    {
      data: request
    }
  );
  const widgetsForDisplay = getWidgetForDisplay(response);
  const widgets = await fetchWidgetsData(widgetsForDisplay);
  return {
    widgets,
    country: request.destinations[0].country
  };
});

const getWidgetForDisplay = (widgetsInfo: ITilesDto) => {
  const widgetsForDisplay: IWidgetsForDisplay = {};
  widgetsInfo.homePageTiles.forEach((widget) => {
    if (
      widget?.tiles &&
      widget.tiles[0].display &&
      widget.tiles[0].data &&
      widget.type !== "toDo"
    ) {
      widgetsForDisplay[widget.type] = widget.tiles[0].data;
    }
  });
  return widgetsForDisplay;
};

const fetchWidgetsData = async (widgetsForDisplay: IWidgetsForDisplay) => {
  const widgets: IWidgetObj = {};
  const widgetsNameArr = Object.keys(widgetsForDisplay);
  for (let i = 0; i < widgetsNameArr.length; i++) {
    let path =
      widgetsNameArr[i] === "covid"
        ? `/v1/mobile-home/${widgetsNameArr[i]}/tile`
        : `/mobile-home/${widgetsNameArr[i]}/tile`;
    try {
      widgets[widgetsNameArr[i]] = await apiWrapper.request(path, "POST", {
        data: widgetsForDisplay[widgetsNameArr[i]]
      });
    } catch (err) {
      console.error(`Unable to fetch ${widgetsNameArr[i]} widget.`, err);
    }
  }
  if (widgets.contactDetails) {
    widgets.contactDetails = (
      widgets.contactDetails as IEmergencyContactDto
    ).contactDetails;
  }
  return widgets;
};

export const createOrEditEmergencyContact = createAsyncThunk<
  IEmergencyContactWidget | void,
  { payload: IEmergencyContactWidget }
>("createEmergencyContact", async ({ payload }) => {
  try {
    return await ApiWrapper.request<IEmergencyContactWidget>(
      "/contact-details-widget",
      "PUT",
      {
        data: payload
      }
    );
  } catch (err) {
    console.error("Unable to create/edit emergency contact", err);
  }
});

export const fetchEmergencyContact = createAsyncThunk<
  IEmergencyContactWidget | void,
  { country: string }
>("fetchEmergencyContact", async ({ country }) => {
  try {
    return await ApiWrapper.request<IEmergencyContactWidget>(
      `/contact-details-widget/${country}`,
      "GET"
    );
  } catch (err) {
    console.error("Unable to fetch emergency contact", err);
  }
});
