import { RootState } from "../../../types/redux-types";

export const selectPolicies = (state: RootState) => state.policies.policies;
export const selectSelectedPolicy = (state: RootState) =>
  state.policies.selectedPolicy;
export const displayPolicy = (state: RootState) =>
  state.policies.displaySelectedPolicy;
export const tripsAreLoading = (state: RootState) =>
  state.policies.tripsAreLoading;
export const claimsAreLoading = (state: RootState) =>
  state.policies.claimsAreLoading;
export const docsAreLoading = (state: RootState) =>
  state.policies.docsAreLoading;
export const commentsAreLoading = (state: RootState) =>
  state.policies.commentsAreLoading;
export const paymentsAreLoading = (state: RootState) =>
  state.policies.paymentsAreLoading;
export const selectPoliciesPagination = (state: RootState) =>
  state.policies.pagination;
export const selectPoliciesSearchText = (state: RootState) =>
  state.policies.policiesSearchText;
export const policiesLoading = (state: RootState) =>
  state.policies.policiesLoading;
export const selectSelectedCustomerDetails = (state: RootState) =>
  state.policies.selectedCustomerDetails;
export const selectSelectedPolicyChat = (state: RootState) =>
  state.policies.selectedPolicyChat;
export const selectSelectedPolicyPayments = (state: RootState) =>
  state.policies.selectedPolicyPayments;
export const selectSelectedPolicyTrips = (state: RootState) =>
  state.policies.selectedPolicyTrips;
export const selectSelectedPolicyComments = (state: RootState) =>
  state.policies.selectedPolicyComments;
export const selectSelectedPolicyClaims = (state: RootState) =>
  state.policies.selectedPolicyClaims;
export const selectPolicyCommentText = (state: RootState) =>
  state.policies.policyCommentText;
export const selectChangeDatesModalOpen = (state: RootState) =>
  state.policies.changeDatesModal;
export const saveUserMessageModalOpen = (state: RootState) =>
  state.policies.saveUserMessageModal;
export const selectSelectedPolicyDocs = (state: RootState) =>
  state.policies.selectedPolicyDocs;
export const backButtonLoading = (state: RootState) =>
  state.policies.backButtonIsLoading;
export const editCustomerModal = (state: RootState) =>
  state.policies.editCustomerModal;
export const cancelPolicyModal = (state: RootState) =>
  state.policies.cancelPolicyModal;
export const documentsCreationStatuses = (state: RootState) =>
  state.policies.documentsCreationStatuses;
