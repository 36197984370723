import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { FC } from "react";
import { useAppDispatch, useAppSelector } from "hooks/redux-hooks";
import { getGenericModal } from "./slice/selectors";
import { closeGenericModal } from "./slice";

interface GenericModalProps {}

const GenericModal: FC<GenericModalProps> = () => {
  const modal = useAppSelector(getGenericModal);
  const dispatch = useAppDispatch();

  const messageParts = modal?.message
    ?.split("\\n")
    .map((part, index) => (
      <DialogContentText key={index}>{part.trim()}</DialogContentText>
    ));
  const confirmBtn = !!modal?.confirmText && (
    <Button
      onClick={() => {
        dispatch(closeGenericModal());
      }}
    >
      {modal.confirmText}
    </Button>
  );
  const rejectBtn = !!modal?.rejectText && (
    <Button
      onClick={() => {
        dispatch(closeGenericModal());
      }}
    >
      {modal.rejectText}
    </Button>
  );

  return (
    <Dialog
      open={!!modal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {!!modal?.title && (
        <DialogTitle id="alert-dialog-title">{modal.title}</DialogTitle>
      )}
      <DialogContent>{messageParts}</DialogContent>
      <DialogActions>
        {confirmBtn}
        {rejectBtn}
      </DialogActions>
    </Dialog>
  );
};

export default GenericModal;
