export function calcRatio(previousValue: number, currentValue: number) {
    let ratio = ((currentValue - previousValue)/currentValue) * 100;
    if(Number(currentValue) === 0){
        return -100;
    }
    return `${parseInt(ratio.toString())}`
}


export function safeToFixed(number: string | number) {
    try {
        return Number(number).toFixed(2);
    } catch (e) {
        return number;
    }
}

export function numberCurrencyFormat(number: string | number){
    let formatter = Intl.NumberFormat('en', { notation: 'compact' });
    return formatter.format(Number(number));
}
