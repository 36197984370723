import React, { useEffect } from "react";
import classnames from "classnames";
import styled from "styled-components";
import TabPanel from "../../../../TabPanel";
import { IUserPolicyInfoDto } from "types/DTO/IUsersInfoDto";
import DocsList from "./docs-list";
import { useAppDispatch, useAppSelector } from "hooks/redux-hooks";
import { fetchPolicyDocs } from "components/policies/slice/thunks";
import { docsAreLoading } from "components/policies/slice/selectors";
import SpinnerLoader from "components/ui/loaders/SpinnerLoader/SpinnerLoader";

const DocumentsTabContainer = styled(TabPanel)`
  &.documents-tab {
    min-height: 100%;
    padding: 24px 24px 100px 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & > .document-list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      padding: 10px;
      gap: 10px;
    }

    & > .upload-document {
    }
  }
`;

interface DocumentsTabProps {
  selectedPolicy: IUserPolicyInfoDto;
}

const UserDocumentsTab: React.FC<DocumentsTabProps> = ({ selectedPolicy }) => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(docsAreLoading);

  useEffect(() => {
    dispatch(fetchPolicyDocs({ userId: selectedPolicy.userId }));
  }, [selectedPolicy, dispatch]);

  const component = isLoading ? (
    <SpinnerLoader isLoading={true} />
  ) : (
    <DocumentsTabContainer
      currTabIndex={2}
      tabIndex={2}
      isHidden={Boolean(!selectedPolicy)}
      className={classnames("documents-tab", "tab-container")}
    >
      <DocsList selectedCustomer={selectedPolicy} />
    </DocumentsTabContainer>
  );

  return <>{component}</>;
};

export default UserDocumentsTab;
