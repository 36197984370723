import React, { useCallback, useEffect, useState } from "react";
import { IUserPolicyInfoDto } from "../../types/DTO/IUsersInfoDto";
import styled from "styled-components";
import Container from "@mui/material/Container";
import arrowRight from "../../assets/images/arrow-right.png";
import smallPhone from "../../assets/svg/small-phone.svg";
import ConnectedPolicies from "./conected-policies";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { setSelectedPolicy, setDisplayPolicy } from "./slice";
import { selectPoliciesSearchText } from "./slice/selectors";
import Avatar from "../ui/Avatar";
import { fetchAppLastLogIn, fetchCustomerDetails } from "./slice/thunks";

interface PolicyLineProps {
  policy: IUserPolicyInfoDto;
}

const PolicyLineContainer = styled(Container)`
  padding-left: 0 !important;
  padding-right: 0 !important;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.13);

  & .parent-row {
    margin-bottom: 8px;
  }

  & .row-container {
    color: #434140;
    background-color: #ffffff;
    font-weight: 400;
    font-size: 13px;
    line-height: 14px;

    & .border-open {
      border-left: 2px solid #4726d1;
    }

    & .row {
      cursor: pointer;

      & div {
        margin: auto 0;
      }

      padding: 12px 0;

      & .user-name-container {
        color: #434140;
        font-weight: 600;
        text-transform: capitalize;
      }

      & .status-container {
        padding: 4px 8px;
        font-size: 13px;
        line-height: 14px;
        color: #ffffff;
        border-radius: 12px;
        width: 84px;
        height: 18px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      & .Pretrip {
        background: #8acd85;
      }

      & .Intrip {
        background: #ef5da8;
      }

      & .Posttrip {
        background: #7b61ff;
      }

      & .arrow {
        background-image: url("${arrowRight}");
        width: 10px;
        height: 14px;
        transition-duration: 500ms;
        position: absolute;
        right: 40%;
      }

      & .small-phone {
        background-image: url("${smallPhone}");
        width: 10px;
        height: 16px;
      }

      & .toggle-right {
      }

      & .toggle-down {
        transform: rotate(90deg);
      }
    }

    & .expend {
      max-height: 1000px;
      transition: 250ms ease-in;
      opacity: 1;
    }

    & .close {
      max-height: 0;
      transition: 250ms ease-out;
      overflow: hidden;
      opacity: 0;
    }

    & .hide-arrow {
      visibility: hidden;
    }

    .overlap-avatar {
      display: flex;
      align-content: center;
      padding-left: 8px;
    }

    & .main-avatar {
      z-index: 1;
    }
  }

  & .row {
    margin: 0 auto !important;
    padding-bottom: 20px !important;
  }

  & .selected-row {
    background-color: #faeaf7;
  }
`;

const PolicyLine: React.FC<PolicyLineProps> = ({ policy }) => {
  const searchText = useAppSelector(selectPoliciesSearchText);
  const [isOpen, setIsOpen] = useState(false);
  const [isSelectedRow, setIsSelectedRow] = useState(false);
  const dispatch = useAppDispatch();

  const handleRowSelected = useCallback(async () => {
    dispatch(setSelectedPolicy(policy));

    if (policy.user.id) {
      dispatch(fetchCustomerDetails({ userId: policy.user.id }));
      dispatch(fetchAppLastLogIn({ userId: policy.user.id }));
    }
    dispatch(setDisplayPolicy(true));
  }, [dispatch, policy]);

  useEffect(() => {
    let open = false;
    if (!!searchText.length && !!policy.relatedPolicies) {
      policy.relatedPolicies.forEach((policy) => {
        if (policy.email?.toLowerCase().includes(searchText?.toLowerCase())) {
          open = true;
        }
        if (
          policy.userName?.toLowerCase().includes(searchText?.toLowerCase())
        ) {
          open = true;
        }
        if (policy.code?.toLowerCase().includes(searchText?.toLowerCase())) {
          open = true;
        }
      });
    }
    setIsOpen(open);
    setIsSelectedRow(open);
  }, [isSelectedRow, searchText, policy]);

  return (
    <PolicyLineContainer>
      <div className="row-container parent-row">
        <div
          className={`row ${isOpen ? "border-open" : ""} ${
            !isOpen && isSelectedRow ? "selected-row" : ""
          }`}
        >
          <div
            onClick={() => handleRowSelected()}
            className="img-url-container"
          >
            <div className="overlap-avatar">
              {!isOpen &&
                policy.extraTravelersInfo?.map((extraTraveller, index) => (
                  <Avatar
                    index={index}
                    key={index}
                    isMainAvatar={false}
                    imgUrl={
                      !!extraTraveller.imgUrl?.url
                        ? extraTraveller.imgUrl.url
                        : ""
                    }
                    name={extraTraveller.name}
                  />
                ))}
              <div className="main-avatar">
                <Avatar
                  isMainAvatar={true}
                  imgUrl={!!policy.imgUrl?.url ? policy.imgUrl.url : ""}
                  name={policy.userName}
                />
              </div>
            </div>
          </div>
          <div
            onClick={() => handleRowSelected()}
            className="user-name-container"
          >
            {policy.userName}
          </div>
          <div onClick={() => handleRowSelected()} className="email-container">
            {policy.email}
          </div>
          <div onClick={() => handleRowSelected()} className="code-container">
            {policy.code}
          </div>
          <div onClick={() => handleRowSelected()} className="mobile-container">
            <div className={`${policy.hadLogin ? "small-phone" : ""}`}></div>
          </div>
          <div
            onClick={() => handleRowSelected()}
            className="trip-status-container"
          >
            <div
              className={`status-container ${policy.tripStatus.replace(
                " ",
                ""
              )}`}
            >
              <div>{policy.tripStatus}</div>
            </div>
          </div>
          <div
            onClick={() => setIsOpen(!isOpen)}
            className={`handler-container ${
              policy.noPoliciesInGroup && Number(policy.noPoliciesInGroup) > 1
                ? ""
                : "hide-arrow"
            }`}
          >
            <div
              className={`arrow ${isOpen ? "toggle-down" : "toggle-right"}`}
            />
          </div>
        </div>
        <div className={`expand-row  ${isOpen ? "expend" : "close"}`}>
          {policy.relatedPolicies
            ?.map((groupPolicy, index) => (
              <ConnectedPolicies
                key={groupPolicy.id}
                policy={groupPolicy}
                index={index}
              />
            ))
            .reverse()}
        </div>
      </div>
    </PolicyLineContainer>
  );
};

export default PolicyLine;
