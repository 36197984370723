import React from "react";
import ClaimsList from "components/claims/ClaimsList";
import ClaimView from "components/claims/ClaimView";
import Drawer from "@mui/material/Drawer";
import { resetSelectedClaim } from "../components/claims/slice";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import { selectSelectedClaim } from "../components/claims/slice/selectors";
import Button from "@mui/material/Button";
import WestIcon from "@mui/icons-material/West";
import styled from "styled-components";

interface ClaimsProps {}

const ClaimDrawer = styled(Drawer)`
  & .MuiDrawer-paper {
    box-sizing: border-box;
    width: 97%;
    flex-shrink: 0;
    margin-top: 72px;
    overflow-y: auto;
  }

  & .claims-back-button {
    justify-content: start;
    margin: 1em;
    text-transform: unset;
    max-width: 2em;
  }
  & .claims-back-button-container {
    width: 100%;
    position: fixed;
    background-color: #fff;
    z-index: 1;
  }
`;
const Claims: React.FC<ClaimsProps> = () => {
  const dispatch = useAppDispatch();
  const open = !!useAppSelector(selectSelectedClaim);
  return (
    <>
      <ClaimDrawer
        onClose={() => dispatch(resetSelectedClaim())}
        anchor={"right"}
        hideBackdrop={true}
        open={open}
      >
        <div className="claims-back-button-container">
          <Button
            fullWidth={false}
            variant="text"
            onClick={() => dispatch(resetSelectedClaim())}
            className="claims-back-button"
            size={"small"}
            startIcon={<WestIcon />}
          >
            Back
          </Button>
        </div>
        <ClaimView />
      </ClaimDrawer>
      <ClaimsList />
    </>
  );
};

export default Claims;
