import React from "react";
import { IDashboardPurchases } from "../../types/DTO/IDashboardDto";
import styled from "styled-components";
import Container from "@mui/material/Container";
import { Box } from "@mui/material";
import DashboardData from "./DashboardData";
import { calcRatio, numberCurrencyFormat } from "./dashboard-helper";

interface PurchasesTileProps {
  className: string;
  purchasesInfo: IDashboardPurchases;
}

const PurchasesTileContainer = styled(Container)`
  & .inner-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    & .info {
      display: flex;
    }
  }
`;

const PurchasesTile: React.FC<PurchasesTileProps> = ({
  className,
  purchasesInfo
}) => {
  return (
    <PurchasesTileContainer className={className}>
      <Box className="inner-container">
        <Box>
          <span className="tile-name">Purchases</span>{" "}
          <span className="tile-description">buying of policy group</span>
        </Box>
        <Box>
          <span className="tile-amount">
            {numberCurrencyFormat(purchasesInfo.all)}
          </span>
        </Box>
        <Box className="info">
          <DashboardData
            text={"Last 7 days"}
            amount={purchasesInfo.sevenDays}
            delta={calcRatio(
              purchasesInfo.previousSevenDays,
              purchasesInfo.sevenDays
            )}
          ></DashboardData>
          <DashboardData
            text={"24hr"}
            amount={purchasesInfo.lastDay}
            delta={calcRatio(
              purchasesInfo.sevenDays / 7,
              purchasesInfo.lastDay
            )}
          ></DashboardData>
        </Box>
      </Box>
    </PurchasesTileContainer>
  );
};

export default React.memo(PurchasesTile);
