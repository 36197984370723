import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { Box } from "@mui/system";
import { OutlinedInput, Stack, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import IconButton from "@mui/material/IconButton";
import NewReleasesSharpIcon from "@mui/icons-material/NewReleasesSharp";
import { useAppDispatch } from "../../../../../hooks/redux-hooks";
import { addClaimComment, deleteClaimComment } from "../../../slice/thunks";
import { format } from "date-fns";

const CommentContainer = styled(Box)`
  &.single-comment {
    box-shadow: 0 4px 20px rgb(0 0 0 / 13%);
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-radius: 20px;

    & > .comment-note {
      font-size: 13px;
      font-weight: bold;
      padding: 8px 15px;
      border-radius: 20px;

      & > input {
        padding: 0;
      }
    }
    & > .comment-top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      & > .comment-icons {
        flex-direction: row;
        color: #434140;
      }
      & > .comment-dates {
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px;
        padding: 0 8px;
        & > .agent-name {
          font-size: 16px;
          font-weight: bold;
        }
        & > .comment-info {
          font-size: 14px;
          color: #434140;
          font-weight: bold;
          display: flex;
          align-items: center;
          gap: 3px;
          color: rgba(0, 0, 0, 0.54);
        }
      }
    }
  }
`;

interface CommentProps {
  comment: {
    id: number;
    created: string;
    updated: string;
    note: string;
    userId: number;
    claimId: number;
    name: string;
  };
}

const Comment: React.FC<CommentProps> = ({ comment }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [changedCommentText, setChangedCommentText] = useState(comment.note);
  const dispatch = useAppDispatch();
  const inputRef = useRef<null | HTMLElement>(null);

  useEffect(() => {
    if (isEditing) {
      inputRef.current!.focus();
    }
  }, [isEditing]);

  const edit = () => {
    dispatch(
      addClaimComment({
        id: comment.id,
        claimId: comment.claimId,
        userId: comment.userId,
        created: comment.created,
        updated: new Date().toString(),
        note: changedCommentText
      })
    ).then((response) => {
      if (response.meta.requestStatus === "fulfilled") setIsEditing(false);
    });
  };

  return (
    <CommentContainer className="single-comment">
      <Stack className="comment-top">
        <Stack className="comment-dates">
          <Typography className="comment-info">{comment.name}</Typography>
          <Typography className="comment-info">
            {format(new Date(comment.created), "MM/dd/yyyy HH:mm")}
          </Typography>
          {comment.updated !== comment.created && (
            <Typography className="comment-info">
              <NewReleasesSharpIcon />
              {format(new Date(comment.updated), "MM/dd/yyyy HH:mm")}
            </Typography>
          )}
        </Stack>
        <Stack className="comment-icons">
          {isEditing && (
            <>
              <IconButton onClick={edit}>
                <SaveIcon />
              </IconButton>
              <IconButton onClick={() => setIsEditing(false)}>
                <CancelIcon />
              </IconButton>
            </>
          )}
          <IconButton onClick={() => setIsEditing(!isEditing)}>
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() =>
              dispatch(
                deleteClaimComment({
                  noteId: comment.id,
                  claimId: comment.claimId
                })
              )
            }
          >
            <DeleteIcon />
          </IconButton>
        </Stack>
      </Stack>
      <OutlinedInput
        multiline
        inputRef={inputRef}
        disabled={!isEditing}
        onChange={(e) => setChangedCommentText(e.target.value)}
        value={!isEditing ? comment.note : changedCommentText}
        className="comment-note"
      />
    </CommentContainer>
  );
};

export default Comment;
