import React from "react";
import styled from "styled-components";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { Options } from "../../../../../types/general";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CreditCardIcon from "@mui/icons-material/CreditCard";

const SinglePaymentContainer = styled(Box)`
  &.single-payment {
    box-shadow: 0 4px 20px rgb(0 0 0 / 13%);
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-radius: 20px;

    & > .single-payment-top {
      & > .payment-reason {
        font-size: 14px;
        font-weight: bold;
      }
      & > .single-payment-dates {
        flex-direction: row;
        & > .single-payment-date {
          font-size: 13px;
          font-weight: bold;
          color: rgba(0, 0, 0, 0.54);
        }
      }
      & > .payment-amount {
        display: flex;
        gap: 2px;
        font-size: 13px;
        font-weight: bold;
      }
    }
    & > .payment-details {
      & > .payment-details-bank-credit {
        font-size: 14px;
        font-weight: bold;
        display: flex;
        gap: 3px;
        align-items: center;
        & > .MuiSvgIcon-root {
          font-size: 17px;
        }
      }
      & > .bank-details-owner {
        & > .billing-name {
          font-size: 14px;
          font-weight: bold;
        }
        & > .bank-details {
          flex-direction: row;
          gap: 5px;
          & > p {
            font-size: 13px;
            font-weight: bold;
          }
        }
      }
    }
  }
`;

type BillingInfo = {
  name: string;
  bankId: string;
  bankName: string;
  bankAccount: string;
};

interface SinglePaymentProps {
  payment: {
    amount: string;
    claimId: number;
    created: string;
    currency: string;
    id: number;
    modifierId: number;
    paymentType: string;
    reason: string;
    state: string;
    update: string;
    user_id: number;
    billingInfo?: BillingInfo;
  };
}

const SinglePayment: React.FC<SinglePaymentProps> = ({ payment }) => {
  const options: Options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric"
  };

  return (
    <SinglePaymentContainer className="single-payment">
      <Stack className="single-payment-top">
        <Stack className="single-payment-dates">
          <Typography className="single-payment-date">
            {new Date(payment.created).toLocaleDateString("en-US", options)}{" "}
          </Typography>
          {payment.created !== payment.update && (
            <Typography className="single-payment-date">
              {payment.update}
            </Typography>
          )}
        </Stack>
        <Typography className="payment-amount">
          {Math.trunc(parseInt(payment.amount))}
          <span>{payment.currency}</span>
        </Typography>
        <Typography className="payment-reason">{payment.reason}</Typography>
      </Stack>
      <Stack className="payment-details">
        <Typography className="payment-details-bank-credit">
          {payment.paymentType === "stripe" ? (
            <CreditCardIcon />
          ) : (
            <AccountBalanceIcon />
          )}

          {payment.paymentType === "stripe" ? "Credit Card" : "Bank"}
        </Typography>
        {payment.billingInfo && (
          <Stack className="bank-details-owner">
            <Typography className="billing-name">
              Name: {payment.billingInfo.name}
            </Typography>
            <Stack className="bank-details">
              <Typography>Bank: {payment.billingInfo.bankName}</Typography>
              <Divider orientation="vertical" flexItem />
              <Typography>
                Account: {payment.billingInfo.bankAccount}
              </Typography>
              <Divider orientation="vertical" flexItem />

              <Typography>Bank Number: {payment.billingInfo.bankId}</Typography>
            </Stack>
          </Stack>
        )}
      </Stack>
    </SinglePaymentContainer>
  );
};

export default SinglePayment;
