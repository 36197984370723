import React from "react";

const NotFound404 = () => {
  return (
    <h1>
      404
      <h2>Oops...we couldn't find your requested page</h2>
    </h1>
  );
};

export default NotFound404;
