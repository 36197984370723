import React, { useCallback, useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { abortNewClaimModal } from "../slice";
import styled from "styled-components";
import { Autocomplete, Button, TextField } from "@mui/material";
import { uniqBy } from "lodash";
import { AutocompleteItem, Nullable } from "../../../types/general";
import useInput from "../../../hooks/useInput";
import Container from "@mui/material/Container";
import { createClaim } from "../slice/thunks";
import { useAppDispatch } from "../../../hooks/redux-hooks";
import {
  getClaimIntents,
  getClaimSubIntents,
  getPolicyGroupsByUserId,
  getUserNames
} from "../../../app/common/API-Requests";
import { IUserPolicyInfoDto } from "../../../types/DTO/IUsersInfoDto";
import { fetchPolicyClaims } from "../../policies/slice/thunks";

const MODAL_CONTENT_CLASS = "modal-content";

interface AddClaimModalProps {
  isOpen: boolean;
  policyHolder?: IUserPolicyInfoDto;
}

const AddClaimModalContainer = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;

  & .${MODAL_CONTENT_CLASS} {
    padding: 20px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
    border-radius: 8px;
  }
`;

const AddClaimModal: React.FC<AddClaimModalProps> = ({
  isOpen,
  policyHolder
}) => {
  const dispatch = useAppDispatch();
  const {
    value: subject,
    onChange: onChangeSubject,
    resetInput
  } = useInput("");
  const [users, setUsers] = useState<AutocompleteItem[]>([]);
  const mainUser = policyHolder
    ? ({
        label: `${policyHolder.user.firstName} ${policyHolder.user.lastName} - ${policyHolder.user.email}`,
        id: policyHolder.userId
      } as AutocompleteItem)
    : null;
  const [selectedUser, setSelectedUser] = useState<AutocompleteItem | null>(
    mainUser
  );
  const [policies, setPolicies] = useState<AutocompleteItem[]>([]);
  const [selectedPolicy, setSelectedPolicy] =
    useState<Nullable<AutocompleteItem>>(null);
  const [intents, setIntents] = useState<AutocompleteItem[]>([]);
  const [selectedIntent, setSelectedIntent] =
    useState<Nullable<AutocompleteItem>>(null);
  const [subIntents, setSubIntents] = useState<AutocompleteItem[]>([]);
  const [selectedSubIntent, setSelectedSubIntent] =
    useState<Nullable<AutocompleteItem>>(null);
  const handleSelectUser = useCallback((_: any, newValue: any) => {
    setSelectedUser(newValue);
  }, []);
  const handleSelectPolicy = useCallback((_: any, newValue: any) => {
    setSelectedPolicy(newValue);
  }, []);
  const handleSelectIntent = useCallback((_: any, newValue: any) => {
    setSelectedIntent(newValue);
  }, []);
  const handleSelectSubIntent = useCallback((_: any, newValue: any) => {
    setSelectedSubIntent(newValue);
  }, []);
  const handleCloseAddClaimModal = useCallback(() => {
    dispatch(abortNewClaimModal());
  }, [dispatch]);

  const handleCreateClaimClick = useCallback(async () => {
    await dispatch(
      createClaim({
        customerId: +selectedUser!.id,
        policyId: +selectedPolicy!.id,
        subject: subject as string,
        intent: selectedIntent!.id as string,
        subIntent: selectedSubIntent!.id as string
      })
    );
    if (policyHolder) {
      dispatch(fetchPolicyClaims({ userId: policyHolder.userId }));
    }
  }, [
    policyHolder,
    dispatch,
    selectedUser,
    selectedPolicy,
    selectedIntent,
    selectedSubIntent,
    subject
  ]);

  const fetchUsers = useCallback(async () => {
    const response = await getUserNames();
    const mappedCustomers = response.map((user) => ({
      label: `${user.name} - ${user.email}`,
      id: user.id
    }));
    setUsers(uniqBy(mappedCustomers, "label"));
  }, []);

  const fetchPolicy = useCallback(async (customerId) => {
    const response = await getPolicyGroupsByUserId(customerId);
    setPolicies(
      response.map((policy) => ({
        label: `${policy.userPolicyUniqueUUID} ${policy.travellers[0].firstName} ${policy.travellers[0].lastName}`,
        id: policy.id
      }))
    );
    await getClaimIntents();
  }, []);

  const fetchIntents = useCallback(async () => {
    const response = await getClaimIntents();
    setIntents(
      response.map((intent) => ({
        label: intent.description,
        id: intent.key
      }))
    );
  }, []);

  const fetchSubIntents = useCallback(async () => {
    const response = await getClaimSubIntents(selectedIntent!.id as string);
    setSubIntents(
      response.map((subIntent) => ({
        label: subIntent.description,
        id: subIntent.key
      }))
    );
  }, [selectedIntent]);

  useEffect(() => {
    if (!policyHolder) {
      fetchUsers().then();
    }
  }, [policyHolder, fetchUsers]);

  useEffect(() => {
    setSelectedPolicy(null);
    resetInput();
    if (selectedUser) {
      fetchPolicy(selectedUser.id).then();
    }
  }, [selectedUser, fetchPolicy, resetInput]);

  useEffect(() => {
    resetInput();
    if (selectedPolicy) {
      fetchIntents().then();
    }
  }, [selectedPolicy, fetchIntents, resetInput]);
  useEffect(() => {
    resetInput();
    if (selectedIntent) {
      fetchSubIntents().then();
    }
  }, [selectedIntent, fetchSubIntents, resetInput]);
  return (
    <AddClaimModalContainer open={isOpen} onClose={handleCloseAddClaimModal}>
      <Container className={MODAL_CONTENT_CLASS}>
        <Autocomplete
          value={selectedUser}
          disablePortal
          disabled={!!policyHolder}
          options={users}
          onChange={handleSelectUser}
          fullWidth
          renderInput={(params) => (
            <TextField {...params} label="Policy Holder" />
          )}
        />
        <br />
        <Autocomplete
          value={selectedPolicy}
          disabled={policies.length === 0}
          disablePortal
          options={policies}
          onChange={handleSelectPolicy}
          fullWidth
          renderInput={(params) => <TextField {...params} label="Policy" />}
        />
        <br />
        <Autocomplete
          value={selectedIntent}
          disabled={intents.length === 0}
          disablePortal
          options={intents}
          onChange={handleSelectIntent}
          fullWidth
          renderInput={(params) => <TextField {...params} label="Type" />}
        />
        <br />
        <Autocomplete
          value={selectedSubIntent}
          disabled={subIntents.length === 0}
          disablePortal
          options={subIntents}
          onChange={handleSelectSubIntent}
          fullWidth
          renderInput={(params) => <TextField {...params} label="Sub type" />}
        />
        <br />
        <TextField
          disabled={!(selectedPolicy && selectedUser)}
          value={subject}
          onChange={onChangeSubject}
          label="Description"
          fullWidth
        />
        <br />
        <Button
          variant="contained"
          disabled={
            !(
              selectedUser &&
              selectedPolicy &&
              selectedIntent &&
              selectedSubIntent &&
              subject
            )
          }
          onClick={handleCreateClaimClick}
        >
          Create a Claim
        </Button>
      </Container>
    </AddClaimModalContainer>
  );
};

export default AddClaimModal;
