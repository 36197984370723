import React from "react";
import Accordion from "@mui/material/Accordion";
import { useState } from "react";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { IEmergencyContactDTO } from "../../types/DTO/IEmergencyContactDTO";
import { IContact } from "../../types/DTO/IContactDTO";
import TextField from "@mui/material/TextField";
import Index from "./CreateEmergencyContactDialog";
import styled from "styled-components";

const EmergencyContactWidgetContainer = styled(Accordion)`
  box-shadow: 0 4px 20px rgb(0 0 0 / 13%);
  border-radius: 12px !important;
  padding: 16px;
  margin-top: 2px !important;
  width: 60%;
  z-index: 0;
`;

interface EmergencyContactWidgetProps {
  contactDetails: IEmergencyContactDTO;
}

const EmergencyContactWidget: React.FC<EmergencyContactWidgetProps> = ({
  contactDetails
}) => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const contacts = contactDetails.contacts.map((contact: IContact, index) => {
    let label: string =
      contact.type === "email"
        ? `${contact.caption} ${contact.type}`
        : contact.caption;
    return (
      <TextField
        key={`${label}`}
        id={`${index}`}
        label={label}
        defaultValue={contact.value}
        variant="outlined"
        margin={"dense"}
        disabled
        fullWidth
        InputProps={{
          readOnly: true
        }}
      />
    );
  });
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <EmergencyContactWidgetContainer
      expanded={expanded === "panel1"}
      onChange={handleChange("panel1")}
    >
      <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
        <TextField
          key={contactDetails.emergencyNumber.value}
          id="emergencyContact"
          label={contactDetails.emergencyNumber.caption}
          defaultValue={contactDetails.emergencyNumber.value}
          variant="outlined"
          fullWidth
          margin={"dense"}
          disabled
          InputProps={{
            readOnly: true
          }}
        />
      </AccordionSummary>
      <AccordionDetails>
        {contacts}
        <Index contactDetails={contactDetails} />
      </AccordionDetails>
    </EmergencyContactWidgetContainer>
  );
};

export default EmergencyContactWidget;
