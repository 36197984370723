import React from "react";
import { IDashboardPoliciesAndRevenues } from "../../types/DTO/IDashboardDto";
import styled from "styled-components";
import { Box } from "@mui/material";
import DashboardData from "./DashboardData";
import { calcRatio, numberCurrencyFormat } from "./dashboard-helper";

interface PoliciesTileProps {
  className: string;
  policiesInfo: IDashboardPoliciesAndRevenues;
}

const PoliciesTileContainer = styled.div`
  background: #f7fcff;
  & .info {
    display: flex;
  }
`;

const PoliciesTile: React.FC<PoliciesTileProps> = ({
  className,
  policiesInfo
}) => {
  return (
    <PoliciesTileContainer className={className}>
      <Box className="inner-container">
        <Box>
          <span className="tile-name">Policies</span>{" "}
          <span className="tile-description">policies total (travelers)</span>
        </Box>
        <Box>
          <span className="tile-amount">
            {numberCurrencyFormat(policiesInfo.cnt)}
          </span>
        </Box>
        <Box className="info">
          <DashboardData
            text={"Last 7 days"}
            amount={policiesInfo.sevenDays}
            delta={calcRatio(
              policiesInfo.previousSevenDays,
              policiesInfo.sevenDays
            )}
          ></DashboardData>
          <DashboardData
            text={"24hr"}
            amount={policiesInfo.lastDay}
            delta={calcRatio(policiesInfo.sevenDays / 7, policiesInfo.lastDay)}
          ></DashboardData>
        </Box>
      </Box>
    </PoliciesTileContainer>
  );
};

export default React.memo(PoliciesTile);
