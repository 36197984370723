import React from "react";
import styled from "styled-components";

interface PolicyLineProps {
  imgUrl: string;
  name: string;
  isMainAvatar: boolean;
  index?: number;
}

const AvatarContainer = styled.div`
  & .user-avatar {
    border-radius: 50%;
    margin: auto;
    height: 22px;
    width: 22px;
  }

  & .MuiSkeleton-root {
    background-color: #bdbdbd;
  }

  & .color-shift {
    border-radius: 50%;
    height: 24px;
    width: 24px;
    animation: color-shift-keyframe 2s infinite;
    background-color: #bdbdbd;
  }

  @keyframes color-shift-keyframe {
    0% {
      width: 0;
      height: 0;
    }
    100% {
      width: inherit;
      height: inherit;
    }
  }

  & .avatar {
    border-radius: 50%;
    height: 24px;
    width: 24px;
    display: flex;

    & .avatar_image {
      border-radius: 50%;
      height: inherit;
      width: inherit;
    }

    & .hide {
      visibility: hidden;
      max-width: 0;
    }

    & .avatar_name-container {
      border-radius: 50%;
      height: 24px;
      width: 24px;
      display: flex;

      & .avatar_name {
        margin: auto;
        color: #ffffff;
      }
    }
  }

  & .move-right {
    margin-right: -10px !important;
  }

  & .main-avatar-background {
    background-color: var(--purple);
    border: 1px solid var(--primary-color);
  }

  & .purple-background {
    background-color: var(--purple);
  }

  & .blue-background {
    background-color: var(--blue);
  }

  & .green-background {
    background-color: var(--green);
  }

  & .pink-background {
    background-color: var(--pink);
  }

  & .orange-background {
    background-color: var(--orange);
  }

  & .yellow-background {
    background-color: var(--yellow);
  }

  & .gray-background {
    background-color: var(--gray);
  }
`;

const getBackgroundColor = (number: number | undefined): string => {
  switch (number) {
    case 0: {
      return "green-background";
    }
    case 1: {
      return "yellow-background";
    }
    case 2: {
      return "blue-background";
    }
    case 3: {
      return "orange-background";
    }
    case 4: {
      return "pink-background";
    }
    default: {
      return "gray-background";
    }
  }
};

const Avatar: React.FC<PolicyLineProps> = ({
  imgUrl,
  name,
  isMainAvatar,
  index
}) => {
  return (
    <AvatarContainer>
      <div className={isMainAvatar ? "avatar" : "avatar move-right"}>
        {imgUrl && (
          <>
            <img
              src={imgUrl}
              alt="Avatar"
              className={`${imgUrl ? "avatar_image" : "hide"}`}
            />{" "}
            {!imgUrl && (
              <div
                className={`${imgUrl ? "avatar_image" : "color-shift"}`}
              ></div>
            )}
          </>
        )}
        {!imgUrl && (
          <div
            className={`avatar_name-container ${
              !isMainAvatar
                ? getBackgroundColor(index)
                : "main-avatar-background"
            }`}
          >
            <div className="avatar_name">{name.substring(0, 1)}</div>
          </div>
        )}
      </div>
    </AvatarContainer>
  );
};

export default Avatar;
