import React, { useEffect } from "react";
import classnames from "classnames";
import styled from "styled-components";
import TabPanel from "../../../../TabPanel";
import { IClaim } from "../../../../../types/general";
import {
  useAppDispatch,
  useAppSelector
} from "../../../../../hooks/redux-hooks";
import { fetchClaimComments } from "../../../slice/thunks";
import {
  selectSelectedClaimComments,
  tabIsLoading
} from "../../../slice/selectors";
import AddComment from "./AddComment";
import { Box } from "@mui/system";
import Comment from "./Comment";
import SpinnerLoader from "../../../../ui/loaders/SpinnerLoader/SpinnerLoader";

const CommentsTabContainer = styled(TabPanel)`
  &.comments-tab {
    display: flex;
    flex-direction: column;

    & > .comment-list {
      display: flex;
      flex-direction: column-reverse;
      gap: 5px;
      padding: 10px 10px 100px 10px;
    }
  }
`;

type CommentType = {
  id: number;
  created: string;
  updated: string;
  note: string;
  userId: number;
  claimId: number;
  name: string;
};

interface CommentsTabProps {
  selectedClaim: IClaim;
}

const CommentsTab: React.FC<CommentsTabProps> = ({ selectedClaim }) => {
  const dispatch = useAppDispatch();
  const claimComments = useAppSelector(selectSelectedClaimComments);
  const isLoading = useAppSelector(tabIsLoading);

  const content = isLoading ? (
    <SpinnerLoader isLoading={true} />
  ) : (
    <>
      <Box className="comment-list">
        {claimComments &&
          claimComments.map((comment: CommentType) => (
            <Comment key={comment.id} comment={comment} />
          ))}
      </Box>
      <AddComment selectedClaim={selectedClaim} />
    </>
  );

  useEffect(() => {
    dispatch(
      fetchClaimComments({
        claimId: selectedClaim.claim.id
      })
    );
  }, [selectedClaim, dispatch]);

  return (
    <CommentsTabContainer
      currTabIndex={4}
      tabIndex={4}
      isHidden={Boolean(!selectedClaim)}
      className={classnames("comments-tab", "tab-container")}
    >
      {content}
    </CommentsTabContainer>
  );
};

export default CommentsTab;
