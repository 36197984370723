import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { IconButton, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { SECTION_USER_DETAILS } from "constants/common";
import styled from "styled-components";
import Avatar from "components/ui/Avatar";
import { useAppDispatch, useAppSelector } from "hooks/redux-hooks";
import { selectSelectedCustomerDetails } from "./slice/selectors";
import { ReactComponent as Pencil } from "assets/svg/Pencil.svg";
import Container from "@mui/material/Container";
import { openEditCustomerModal } from "./slice";

interface CustomerDetailsProps {}

const CustomerDetailsContainer = styled.div`
  background-color: #fff;

  & .MuiContainer-root {
    padding: 0 20px;
    width: inherit;
  }
`;

const CardContainer = styled(Container)`
  padding: 0;
  width: inherit;

  & .details-avatar {
    display: flex;
    padding: 50px 20px 20px 0;
    z-index: 1;
    position: fixed;
    background-color: #fff;
    max-width: 1160px;
    width: calc(100% - 70px);
    border-radius: 0 0 20px 20px;

    & .name-trip-status-container {
      flex: 8;

      & .name {
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;
        text-transform: capitalize;
        color: #434140;
      }
    }

    & .trip-status-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 32px;
    }

    & .avatar-container {
      width: 92px;
    }

    & .avatar {
      border-radius: 50%;
      height: 60px;
      width: 60px;
      filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.2));
      display: flex;
      background-color: #bdbdbd;

      & .avatar_image {
        border-radius: 50%;
        margin: auto;
        width: 100%;
        height: 100%;
      }

      & .avatar_name {
        border-radius: 50%;
        margin: auto;
        color: #ffffff;
      }

      & .avatar_name-container {
        height: 60px;
        width: 60px;
      }
    }

    & .status-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      padding: 4px 8px;
      border-radius: 12px;
      width: 58px;

      font-weight: 400;
      font-size: 12px;
      line-height: 13px;
      color: #ffffff;
      white-space: nowrap;
    }

    & .pre-trip {
      background: var(--trip-status-pre-trip);
      text-transform: capitalize;
    }

    & .in-trip {
      background: var(--trip-status-in-trip);
      text-transform: capitalize;
    }

    & .post-trip {
      background: var(--trip-status-post-trip);
      text-transform: capitalize;
    }
  }

  & .input-container {
    font-weight: 400;
    font-size: 13px;
    line-height: 14px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    transform: translateY(-8px);
    margin-bottom: 8px;

    & .MuiTypography-root {
      font-weight: 600;
      font-size: 10px;
      line-height: 18px;
      width: 80px;
    }

    & .MuiInput-root {
      line-height: 18px;
      font-size: inherit;
      font-weight: inherit;
      min-width: 300px;
    }

    & .input {
      font-size: 12px;
      border-color: #f7446d;
    }
  }

  & .hide {
    display: none;
  }

  & .field-title {
    width: 120px;
    color: #959493;
  }

  & .top-section {
    padding-top: 150px;
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .Mui-disabled {
    -webkit-text-fill-color: var(--primary-text-color);

    :before {
      border-bottom-style: none;
    }
  }

  & .MuiButtonBase-root {
    padding: 0;
  }

  & .lastLogin {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 100%;
    color: #999999;
  }
`;

const CustomerDetails: React.FC<CustomerDetailsProps> = () => {
  const dispatch = useAppDispatch();
  const {
    firstName,
    lastName,
    email,
    dateOfBirth,
    lastLogin,
    phone,
    tripStatus,
    address,
    profilePicture
  } = useAppSelector(selectSelectedCustomerDetails);
  const [isVisible, setIsVisible] = useState(true);

  const { street, city, country, state, zip } = address;

  const addressForDisplay =
    street && city && state && country && zip
      ? `${street}, ${city}, ${state}, ${country}, ${zip}`
      : "";

  useEffect(() => {
    const policyViewComponent = window.document.getElementById("policy-view");

    if (!policyViewComponent) {
      return;
    }

    let animationFrame = 0;

    const listenToScroll = () => {
      if (animationFrame) {
        return;
      }

      animationFrame = window.requestAnimationFrame(() => {
        const scrollTop = policyViewComponent.scrollTop;

        if (scrollTop && scrollTop > 140) {
          isVisible && setIsVisible(false);
        } else {
          !isVisible && setIsVisible(true);
        }

        animationFrame = 0;
      });
    };

    policyViewComponent.addEventListener(
      "scroll",
      listenToScroll
    );

    return () => {
      if (animationFrame) {
        window.cancelAnimationFrame(animationFrame);
      }

      policyViewComponent.removeEventListener(
        "scroll",
        listenToScroll
      );
    }
  }, [isVisible, dispatch]);

  const tripStatusForDisplay = tripStatus
    ? tripStatus.replaceAll("-", " ")
    : "";
  const openCustomerModalEdit = () => {
    dispatch(
      openEditCustomerModal({
        firstName,
        lastName,
        email,
        dateOfBirth,
        lastLogin,
        phone,
        tripStatus,
        address,
        profilePicture
      })
    );
  };

  return (
    <CustomerDetailsContainer className={SECTION_USER_DETAILS}>
      <CardContainer>
        <div className="details-avatar">
          <div className="avatar-container">
            <Avatar
              isMainAvatar={true}
              imgUrl={profilePicture.url ? profilePicture.url : ""}
              name={`${firstName} ${lastName}`}
            />
          </div>
          <div className="name-trip-status-container">
            <div className="trip-status-container">
              <div className={`status-container ${tripStatus}`}>
                <div>{tripStatusForDisplay}</div>
              </div>
              {isVisible && (
                <IconButton onClick={openCustomerModalEdit}>
                  <Pencil />
                </IconButton>
              )}
            </div>

            <div className="name">
              {firstName} {lastName}
            </div>
            <span className="lastLogin">Last app login: {lastLogin}</span>
          </div>
        </div>
        <div className="top-section">
          <Box className="input-container">
            <Typography component="label" className="field-title">
              Email
            </Typography>
            <TextField
              placeholder="fill email"
              variant="standard"
              className="input"
              value={email}
              disabled
              error={email === ""}
            />
          </Box>
          <Box className="input-container">
            <Typography component="label" className="field-title">
              DoB
            </Typography>

            <TextField
              placeholder="fill date of birth"
              value={dateOfBirth}
              variant={"standard"}
              disabled
              error={dateOfBirth === ""}
            />
          </Box>
          <Box className="input-container">
            <Typography component="label" className="field-title">
              Address
            </Typography>
            <TextField
              placeholder="fill address"
              variant="standard"
              className="input"
              value={addressForDisplay}
              disabled
              error={addressForDisplay === ""}
            />
          </Box>
          <Box className="input-container">
            <Typography component="label" className="field-title">
              Phone
            </Typography>
            <TextField
              placeholder="fill phone"
              variant="standard"
              className="input"
              value={phone}
              disabled
              error={phone === ""}
            />
          </Box>
        </div>
      </CardContainer>
    </CustomerDetailsContainer>
  );
};
export default CustomerDetails;
