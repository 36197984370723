import React from "react";
import { Box } from "@mui/system";
import styled from "styled-components";
import { Typography } from "@mui/material";

const ChatContainer = styled(Box)`
  &.chat-owner {
    display: flex;
    background-color: #0f7173;
    padding: 6px;
    border-radius: 5px;
    position: relative;
    color: #e7ecef;
    box-shadow: 0 4px 20px rgb(0 0 0 / 13%);

    & > h6 {
      font-size: 12px;
      font-weight: bold;
    }
  }
  &.agent-message {
    justify-content: flex-end;
    background-color: #faa613;
    color: #272932;
  }
`;

type ChatMedia = {
  name: string;
  text: string;
  time: string;
  amount: string;
};

interface ChatProps {
  chat: {
    id: number;
    created: string;
    updated: string;
    message: string;
    mediaType: string;
    claimIdentifier: string;
    media: ChatMedia;
    isAlertMessage: boolean;
    type: string;
    status: string;
    recipient_id: number;
    sender_id: number | null;
  };
}

const MessageBox: React.FC<ChatProps> = ({ chat }) => {
  return (
    <ChatContainer
      className={`chat-owner ${
        chat.recipient_id === chat.sender_id ? "agent-message" : ""
      }`}
    >
      <Typography variant="h6">{chat.message}</Typography>
    </ChatContainer>
  );
};

export default MessageBox;
