import React, { useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import useInput from "../hooks/useInput";
import { useDebouncedValueUpdate } from "../hooks/useDebouncedValueUpdate";
import { useAppDispatch } from "../hooks/redux-hooks";
import { CLASS_COLLECTION_FILTER_ELEMENT } from "../constants/common";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";

interface FilterProps {
  label: string;
  searchText: string;
  // @ts-ignore
  updateFilterFunction: ActionCreatorWithoutPayload<string, string>;
  // @ts-ignore
  fetchResultsFunction: ActionCreatorWithoutPayload<string, string>;
}

const FilterContainer = styled(FormControl)`
  &.${CLASS_COLLECTION_FILTER_ELEMENT} {
    margin: 20px auto;
    font-size: 13px;
    width: 75%;
    & > .filter-input {
      border-radius: 42px;
    }
    & .MuiIconButton-root {
      padding: 0;

      & .MuiSvgIcon-root {
        width: 20px;
        height: 20px;
      }
    }
  }
`;

const Filter: React.FC<FilterProps> = ({
  searchText,
  label,
  updateFilterFunction,
  fetchResultsFunction
}) => {
  const dispatch = useAppDispatch();
  const { value: searchTerm, onChange, resetInput } = useInput("");

  const debouncedSearchTerm = useDebouncedValueUpdate(
    searchTerm.trim() as string,
    500
  );

  useEffect(() => {
    dispatch(updateFilterFunction(debouncedSearchTerm));
  }, [updateFilterFunction, debouncedSearchTerm, dispatch]);

  useEffect(() => {
    dispatch(fetchResultsFunction(0));
  }, [searchText, dispatch, fetchResultsFunction]);

  return (
    <FilterContainer
      variant="outlined"
      className={CLASS_COLLECTION_FILTER_ELEMENT}
    >
      <InputLabel>{label}</InputLabel>
      <OutlinedInput
        className="filter-input"
        type="text"
        value={searchTerm}
        onChange={onChange}
        endAdornment={
          <InputAdornment position="end">
            {Boolean(searchTerm.length) && (
              <IconButton onClick={resetInput} edge="end">
                <CloseIcon />
              </IconButton>
            )}
            <SearchIcon />
          </InputAdornment>
        }
        label={label}
      />
    </FilterContainer>
  );
};

export default React.memo(Filter);
