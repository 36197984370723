import React, { useState, useRef } from "react";
import { Button, FormControl, OutlinedInput } from "@mui/material";
import styled from "styled-components";
import { useAppDispatch } from "../../../../../hooks/redux-hooks";
import {
  fetchClaimDocuments,
  uploadClaimDocument
} from "../../../slice/thunks";

const UploadDocumentContainer = styled(FormControl)`
  &.upload-document {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;

interface UploadDocumentProps {
  claimId: string;
  userId: string;
}

const UploadDocument: React.FC<UploadDocumentProps> = ({ claimId, userId }) => {
  const dispatch = useAppDispatch();
  const [file, setFile] = useState<File | null>(null);
  const handleFileChange = function (e: React.ChangeEvent<HTMLInputElement>) {
    const fileList = e.target.files;

    if (fileList) setFile(fileList[0]);
  };

  const inputRef = useRef<HTMLInputElement | null>(null);

  return (
    <UploadDocumentContainer className={"upload-document"}>
      <OutlinedInput ref={inputRef} onChange={handleFileChange} type={"file"} />
      <Button
        onClick={() =>
          dispatch(uploadClaimDocument({ claimId, userId, file })).then(
            (res) => {
              if (res.meta.requestStatus === "fulfilled") {
                if (inputRef.current !== null) {
                  inputRef.current.value = "";
                }
                dispatch(fetchClaimDocuments({ claimId: parseInt(claimId) }));
              }
            }
          )
        }
        variant={"contained"}
      >
        Create Document
      </Button>
    </UploadDocumentContainer>
  );
};

export default UploadDocument;
