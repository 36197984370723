import React from "react";
import styled from "styled-components";
import CustomerDetails from "./CustomerDetails";
import PolicyPreview from "./PolicyPreview";
import PolicyModals from "./modals";

interface PolicyViewProps {}

const PolicyViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const PolicyView: React.FC<PolicyViewProps> = () => {
  return (
    <PolicyViewContainer id="policy-view">
      <CustomerDetails />
      <PolicyPreview />
      <PolicyModals />
    </PolicyViewContainer>
  );
};

export default React.memo(PolicyView);
