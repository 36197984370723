import React from "react";
import moment from "moment";
import Button from "@mui/material/Button";
import styled from "styled-components";
import { ReactComponent as Arrow } from "assets/svg/ArrowLeft.svg";
import { useAppDispatch, useAppSelector } from "hooks/redux-hooks";
import { selectSelectedPolicy } from "components/policies/slice/selectors";
import { removeFlight } from "components/policies/slice/thunks";
import { useState } from "react";

interface SingleFlightProps {
  flight: any;
  policyId: number;
  isActive: boolean;
}

const SingleFlightContainer = styled.div`
  flex-shrink: 1;
  margin: 10px 0;
  display: flex;
  align-items: center;
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  height: 50px;

  & .section {
    display: flex;
    align-items: center;
    margin: 0 20px;
    justify-content: space-around;
  }

  & .btn {
    text-transform: capitalize;
    color: var(--primary-color);
  }
`;
const SingleFlight: React.FC<SingleFlightProps> = ({
  flight,
  policyId,
  isActive
}) => {
  const selectedPolicy = useAppSelector(selectSelectedPolicy);
  const [requestPending, setRequestPending] = useState(false);
  const departureDate = moment(new Date(flight.departure_date)).format(
    "MM/DD/yyyy"
  );
  const departureTime = moment(new Date(flight.departure_date)).format(
    "hh:mm A"
  );
  const dispatch = useAppDispatch();

  const cancelFlight = () => {
    setRequestPending(true);
    dispatch(
      removeFlight({
        userId: selectedPolicy!.userId,
        flightId: flight.id,
        policyId
      })
    );
  };

  return (
    <SingleFlightContainer>
      <span className="section">{departureDate}</span>
      <span className="section">
        {flight.operating_carrier}
        {flight.flight_no}
      </span>
      <span className="section">{departureTime}</span>

      <div className="section">
        <span>{flight.origin}</span>
        <Arrow className="section" />
        <span>{flight.destination}</span>
      </div>
      {isActive && (
        <Button
          disabled={requestPending}
          onClick={cancelFlight}
          className="btn"
          variant="text"
        >
          Remove
        </Button>
      )}
    </SingleFlightContainer>
  );
};
export default SingleFlight;
