import { createSlice } from "@reduxjs/toolkit";
import { IPageMeta, Nullable } from "types/general";
import {
  thunkChangeDates,
  fetchPolicyChat,
  fetchPolicyClaims,
  fetchPolicyComments,
  fetchPolicyDocs,
  fetchPolicyPayments,
  fetchPolicyPage,
  fetchRelatedFlights,
  fetchRelatedPolicies,
  fetchSelectedUserProfile,
  fetchPolicyTrips,
  updateUser,
  removeFlight,
  deleteCustomerPolicy,
  fetchAppLastLogIn,
  fetchCustomerDetails,
  createPolicyPdf
} from "./thunks";
import { IUserPolicyInfoDto } from "types/DTO/IUsersInfoDto";
import { IDocDto } from "types/DTO/IDocDto";
import { IChangeDatesDTO, IUserPolicyDto } from "types/DTO/IQuoteDTO";
import { ICustomerDetails } from "types/DTO/ICustomerDetails";
import { createCustomerDetailsFromPolicy } from "utils/policies";
import moment from "moment";

enum statusDictionary {
  success = "success",
  failed = "failed",
  uploading = "uploading"
}

interface IPoliciesSlice {
  policies: Nullable<IUserPolicyInfoDto[]>;
  policiesLoading: boolean;
  changeDatesModal: {
    isOpen: boolean;
    policy: any | null;
    tripStartDate: string;
    tripEndDate: string;
    userId: number;
    saveMessage: string;
    isSaveError: boolean;
  };
  saveUserMessageModal: {
    isOpen: boolean;
    isError: boolean;
    message: string;
    policies: IUserPolicyDto[];
    email: string;
  };
  cancelPolicyModal: {
    isOpen: boolean;
    policy: any;
    error: null | string;
    requestCompleted: boolean;
  };
  editCustomerModal: {
    isOpen: boolean;
    error: string;
    customer: ICustomerDetails | null;
  };
  displaySelectedPolicy: boolean;
  selectedPolicyDocs: Nullable<IDocDto[]>;
  selectedPolicy: null | IUserPolicyInfoDto;
  isSelectedCustomerEdit: {
    isOn: boolean;
    updatingInProcess: boolean;
    error?: any;
  };
  selectedCustomerDetails: ICustomerDetails;
  selectedPolicyChat: any;
  selectedPolicyPayments: any;
  customersFetchingInProcessAmount: number;
  policiesSearchText: string;
  pagination?: IPageMeta;
  selectedPolicyTrips: any;
  selectedPolicyComments: any;
  policyCommentText: string;
  selectedPolicyClaims: any;
  customerError: string;
  tripsAreLoading: boolean;
  claimsAreLoading: boolean;
  docsAreLoading: boolean;
  commentsAreLoading: boolean;
  paymentsAreLoading: boolean;
  backButtonIsLoading: boolean;
  documentsCreationStatuses: {
    status: statusDictionary;
    policyId: number;
  }[];
}

const initialState: IPoliciesSlice = {
  policies: null,
  isSelectedCustomerEdit: {
    isOn: false,
    updatingInProcess: false
  },
  policiesLoading: true,
  selectedPolicyDocs: null,
  policiesSearchText: "",
  customersFetchingInProcessAmount: 0,
  displaySelectedPolicy: false,
  selectedPolicy: null,
  selectedPolicyChat: null,
  selectedPolicyPayments: null,
  selectedPolicyComments: null,
  policyCommentText: "",
  selectedPolicyClaims: [],
  selectedPolicyTrips: [],
  customerError: "",
  changeDatesModal: {
    isOpen: false,
    policy: null,
    tripStartDate: "",
    tripEndDate: "",
    userId: -1,
    saveMessage: "",
    isSaveError: true
  },
  saveUserMessageModal: {
    isOpen: false,
    isError: false,
    message: "",
    policies: [],
    email: ""
  },
  cancelPolicyModal: {
    isOpen: false,
    policy: null,
    error: null,
    requestCompleted: false
  },
  editCustomerModal: {
    isOpen: false,
    error: "",
    customer: null
  },
  tripsAreLoading: false,
  claimsAreLoading: false,
  docsAreLoading: false,
  commentsAreLoading: false,
  paymentsAreLoading: false,
  backButtonIsLoading: false,
  documentsCreationStatuses: [],
  selectedCustomerDetails: {
    id: 0,
    firstName: "Unknown",
    lastName: "Unknown",
    email: "",
    dateOfBirth: "",
    phone: "",
    lastLogin: "Never",
    tripStatus: "inTrip",
    profilePicture: {
      Bucket: "",
      ETag: "",
      Key: "",
      key: "",
      url: ""
    },
    address: {
      zip: "",
      city: "",
      street: "",
      country: "",
      state: ""
    }
  }
};

const policiesSlice = createSlice({
  name: "policies",
  initialState,
  reducers: {
    setDisplayPolicy: (
      state: IPoliciesSlice,
      { payload }: { payload: boolean }
    ) => {
      state.displaySelectedPolicy = payload;
    },
    setSelectedPolicy: (
      state: IPoliciesSlice,
      { payload: policy }: { payload: IUserPolicyInfoDto }
    ) => {
      state.selectedPolicy = policy;
      state.selectedCustomerDetails = createCustomerDetailsFromPolicy(policy);
      state.isSelectedCustomerEdit = initialState.isSelectedCustomerEdit;
    },

    updatePolicySearchText: (
      state: IPoliciesSlice,
      { payload }: { payload: string }
    ) => {
      state.policiesSearchText = payload;
    },
    updatePolicyCommentText: (
      state: IPoliciesSlice,
      { payload }: { payload: string }
    ) => {
      state.policyCommentText = payload;
    },
    resetSelectedPolicy: (state: IPoliciesSlice) => {
      state.selectedPolicy = null;
      state.selectedCustomerDetails = initialState.selectedCustomerDetails;
    },
    resetDocumentsCreationStatuses: (state: IPoliciesSlice) => {
      state.documentsCreationStatuses = [];
    },
    openDatesModal: (
      state: IPoliciesSlice,
      { payload: changeDatesDTO }: { payload: IChangeDatesDTO }
    ) => {
      state.changeDatesModal = {
        isOpen: true,
        userId: state.selectedPolicy!.userId,
        policy: changeDatesDTO.policy,
        tripStartDate: changeDatesDTO.tripStartDate,
        tripEndDate: changeDatesDTO.tripEndDate,
        saveMessage: "",
        isSaveError: true
      };
    },
    abortChangeDatesModal: (state: IPoliciesSlice) => {
      state.changeDatesModal = {
        isOpen: false,
        userId: -1,
        policy: null,
        tripStartDate: "",
        tripEndDate: "",
        saveMessage: "",
        isSaveError: true
      };
    },
    abortSaveUserMessageModal: (state: IPoliciesSlice) => {
      state.saveUserMessageModal = {
        isOpen: false,
        isError: false,
        message: "",
        policies: [],
        email: ""
      };
    },
    openCancelPolicyModal: (
      state: IPoliciesSlice,
      { payload }: { payload: any }
    ) => {
      state.cancelPolicyModal = {
        isOpen: true,
        policy: payload,
        error: null,
        requestCompleted: false
      };
    },
    closeCancelPolicyModal: (state: IPoliciesSlice) => {
      state.cancelPolicyModal = {
        isOpen: false,
        policy: null,
        error: null,
        requestCompleted: false
      };
    },

    openEditCustomerModal: (
      state: IPoliciesSlice,
      { payload }: { payload: ICustomerDetails }
    ) => {
      state.editCustomerModal.isOpen = true;
      state.editCustomerModal.customer = payload;
    },
    closeEditCustomerModal: (state: IPoliciesSlice) => {
      state.editCustomerModal = initialState.editCustomerModal;
    }
  },

  extraReducers: (builder) => {
    builder.addCase(
      fetchPolicyPage.fulfilled,
      (state: IPoliciesSlice, { payload }) => {
        if (payload !== undefined) {
          state.policies = payload.policies;
          state.pagination = payload.pageMeta;
        }
        state.customersFetchingInProcessAmount =
          state.customersFetchingInProcessAmount - 1;
        state.policiesLoading = false;
      }
    );
    builder.addCase(fetchPolicyPage.rejected, (state: IPoliciesSlice) => {
      state.policiesLoading = false;
      state.customersFetchingInProcessAmount--;
    });
    builder.addCase(fetchPolicyPage.pending, (state: IPoliciesSlice) => {
      state.policiesLoading = true;
      state.customersFetchingInProcessAmount =
        state.customersFetchingInProcessAmount + 1;
    });
    builder.addCase(
      fetchPolicyTrips.fulfilled,
      (state: IPoliciesSlice, { payload }) => {
        state.selectedPolicyTrips = payload;
        state.tripsAreLoading = false;
      }
    );
    builder.addCase(fetchPolicyTrips.rejected, (state: IPoliciesSlice) => {
      state.selectedPolicyTrips = null;
      state.tripsAreLoading = false;
    });
    builder.addCase(fetchPolicyTrips.pending, (state: IPoliciesSlice) => {
      state.tripsAreLoading = true;
    });
    builder.addCase(
      fetchPolicyClaims.fulfilled,
      (state: IPoliciesSlice, { payload }) => {
        state.selectedPolicyClaims = payload;
        state.claimsAreLoading = false;
      }
    );
    builder.addCase(fetchPolicyClaims.pending, (state: IPoliciesSlice) => {
      state.claimsAreLoading = true;
    });
    builder.addCase(fetchPolicyClaims.rejected, (state: IPoliciesSlice) => {
      state.selectedPolicyClaims = null;
      state.claimsAreLoading = false;
    });

    builder.addCase(
      fetchPolicyComments.fulfilled,
      (state: IPoliciesSlice, { payload }) => {
        state.selectedPolicyComments = payload;
        state.commentsAreLoading = false;
      }
    );
    builder.addCase(fetchPolicyComments.pending, (state: IPoliciesSlice) => {
      state.commentsAreLoading = true;
    });
    builder.addCase(fetchPolicyComments.rejected, (state: IPoliciesSlice) => {
      state.selectedPolicyComments = null;
      state.commentsAreLoading = false;
    });
    builder.addCase(
      fetchRelatedPolicies.fulfilled,
      (state: IPoliciesSlice, { payload, meta }) => {
        const { policyId } = meta.arg;
        if (payload.length <= 0) return;
        state.selectedPolicyTrips = state.selectedPolicyTrips.map(
          (trip: any) => {
            if (trip.id === policyId) {
              return { ...trip, policies: payload };
            }
            return trip;
          }
        );
      }
    );
    builder.addCase(
      fetchRelatedFlights.fulfilled,
      (state: IPoliciesSlice, { payload, meta }) => {
        const { policyId } = meta.arg;
        if (payload.length <= 0) return;
        state.selectedPolicyTrips = state.selectedPolicyTrips.map(
          (trip: any) => {
            if (trip.id === policyId) {
              return { ...trip, flights: payload };
            }
            return trip;
          }
        );
      }
    );

    builder.addCase(
      fetchSelectedUserProfile.fulfilled,
      (state: IPoliciesSlice, { payload }) => {
        state.selectedCustomerDetails = payload;
      }
    );
    builder.addCase(
      fetchPolicyChat.fulfilled,
      (state: IPoliciesSlice, { payload }) => {
        state.selectedPolicyChat = payload;
      }
    );

    builder.addCase(fetchPolicyChat.rejected, (state: IPoliciesSlice) => {
      state.selectedPolicyChat = null;
    });
    builder.addCase(
      fetchPolicyPayments.fulfilled,
      (state: IPoliciesSlice, { payload }) => {
        state.selectedPolicyPayments = payload;
        state.paymentsAreLoading = false;
      }
    );
    builder.addCase(fetchPolicyPayments.pending, (state: IPoliciesSlice) => {
      state.paymentsAreLoading = true;
    });
    builder.addCase(fetchPolicyPayments.rejected, (state: IPoliciesSlice) => {
      state.selectedPolicyPayments = null;
      state.paymentsAreLoading = false;
    });
    builder.addCase(
      updateUser.fulfilled,
      (state: IPoliciesSlice, { payload }) => {
        state.selectedCustomerDetails = payload;
      }
    );
    builder.addCase(updateUser.rejected, (state: IPoliciesSlice, { error }) => {
      state.editCustomerModal.error = !!error?.message
        ? error.message
        : "Please contact R&D";
    });
    builder.addCase(
      fetchPolicyDocs.fulfilled,
      (state: IPoliciesSlice, { payload }) => {
        state.selectedPolicyDocs = payload;
        state.docsAreLoading = false;
      }
    );
    builder.addCase(fetchPolicyDocs.pending, (state: IPoliciesSlice) => {
      state.docsAreLoading = true;
    });
    builder.addCase(fetchPolicyDocs.rejected, (state: IPoliciesSlice) => {
      state.selectedPolicyDocs = null;
      state.docsAreLoading = false;
    });
    builder.addCase(
      thunkChangeDates.rejected,
      (state: IPoliciesSlice, { error }) => {
        state.changeDatesModal.isSaveError = true;
        state.changeDatesModal.saveMessage = error.message
          ? error.message + "."
          : "Something went wrong, could not change dates. please contact R&D.";
      }
    );
    builder.addCase(thunkChangeDates.fulfilled, (state: IPoliciesSlice) => {
      state.changeDatesModal.isSaveError = false;
      state.changeDatesModal.saveMessage = "Yay! dates where changed";
    });
    builder.addCase(deleteCustomerPolicy.fulfilled, (state: IPoliciesSlice) => {
      state.cancelPolicyModal.requestCompleted = true;
    });
    builder.addCase(
      deleteCustomerPolicy.rejected,
      (state: IPoliciesSlice, { error }) => {
        state.cancelPolicyModal.requestCompleted = true;
        state.cancelPolicyModal.error = error.message
          ? error.message + "."
          : "Something went wrong, please contact R&D.";
      }
    );
    builder.addCase(
      removeFlight.fulfilled,
      (state: IPoliciesSlice, { meta }) => {
        const removedFlightId = meta.arg.flightId;
        const policyId = meta.arg.policyId;
        state.selectedPolicyTrips.forEach((trip: any) => {
          let flights = trip.flights;
          if (trip.id === policyId) {
            flights = trip.flights.filter(
              (flight: any) => flight.id !== removedFlightId
            );
          }
          trip.flights = flights;
        });
      }
    );
    builder.addCase(
      fetchAppLastLogIn.fulfilled,
      (state: IPoliciesSlice, { payload }) => {
        if (state.selectedCustomerDetails) {
          state.selectedCustomerDetails.lastLogin = moment(
            payload.created
          ).format("ddd, MMM D,  YYYY, hh:MM A");
        }
      }
    );
    builder.addCase(
      fetchCustomerDetails.fulfilled,
      (state: IPoliciesSlice, { payload }) => {
        state.selectedCustomerDetails = payload;
      }
    );
    builder.addCase(
      createPolicyPdf.fulfilled,
      (state: IPoliciesSlice, { meta }) => {
        if (!!state.documentsCreationStatuses.length) {
          state.documentsCreationStatuses.shift();
          state.documentsCreationStatuses.shift();
          state.documentsCreationStatuses.push({
            status: statusDictionary.success,
            policyId: meta.arg.policyId
          });
          state.documentsCreationStatuses.push({
            status: statusDictionary.success,
            policyId: meta.arg.policyId
          });
          const documentsAreLoading = !!state.documentsCreationStatuses.find(
            (tempDoc) => tempDoc.status === statusDictionary.uploading
          );
          if (!documentsAreLoading) {
            state.documentsCreationStatuses = [];
          }
        }
      }
    );
    builder.addCase(
      createPolicyPdf.pending,
      (state: IPoliciesSlice, { meta }) => {
        state.documentsCreationStatuses.push({
          status: statusDictionary.uploading,
          policyId: meta.arg.policyId
        });
        state.documentsCreationStatuses.push({
          status: statusDictionary.uploading,
          policyId: meta.arg.policyId
        });
      }
    );
    builder.addCase(
      createPolicyPdf.rejected,
      (state: IPoliciesSlice, { meta }) => {
        if (!!state.documentsCreationStatuses.length) {
          state.documentsCreationStatuses.shift();
          state.documentsCreationStatuses.shift();
          state.documentsCreationStatuses.push({
            status: statusDictionary.failed,
            policyId: meta.arg.policyId
          });
          state.documentsCreationStatuses.push({
            status: statusDictionary.failed,
            policyId: meta.arg.policyId
          });
        }
      }
    );
  }
});

export const {
  setSelectedPolicy,
  setDisplayPolicy,
  updatePolicySearchText,
  resetSelectedPolicy,
  updatePolicyCommentText,
  openDatesModal,
  abortChangeDatesModal,
  abortSaveUserMessageModal,
  resetDocumentsCreationStatuses,
  openCancelPolicyModal,
  closeCancelPolicyModal,
  openEditCustomerModal,
  closeEditCustomerModal
} = policiesSlice.actions;

export default policiesSlice.reducer;
