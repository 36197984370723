import { IClaim } from "../../../../types/general";
import React from "react";
import styled from "styled-components";
import Box from "@mui/material/Box";
import { TABLE_ROW_HEIGHT } from "../common";
import { selectSelectedRowIndex } from "../../../../components/collectionTable/selectors";
import classnames from "classnames";
import { useAppSelector } from "../../../../hooks/redux-hooks";

const ClaimIdContainer = styled(Box)`
  height: ${TABLE_ROW_HEIGHT}px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: normal;

  & label {
    font-size: 13px;
    line-height: 19px;
    color: #959493;

    &.id {
      font-weight: 500;
    }
    &.name {
      font-weight: 600;
    }
  }

  &.selected-row {
    & label.name {
      color: #434140;
    }
  }
`;

interface ClaimIdRendererProps {
  data: IClaim;
  rowIndex: number;
}
const ClaimIdRenderer: React.FC<ClaimIdRendererProps> = ({
  data: {
    claim: { id, identifier }
  },
  rowIndex
}) => {
  const selectedRowIndex = useAppSelector(selectSelectedRowIndex);

  return (
    <ClaimIdContainer
      className={classnames({ "selected-row": selectedRowIndex === rowIndex })}
    >
      <label className="id">{id}</label>
      <label className="name">{identifier}</label>
    </ClaimIdContainer>
  );
};
export default React.memo(ClaimIdRenderer);
