import React, { useState } from "react";

const useFormInput = (initialValue: string, validateValue: any, manipulateValue?: (value: string) => string) => {
  const [value, setValue] = useState(initialValue);
  const [wasTouched, setWasTouched] = useState(false);
  const [wasChanged, setWasChanged] = useState(false);
  const isValid = validateValue(value);
  const hasError = !isValid && wasTouched;

  const changeValueHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWasChanged(true);
    if (manipulateValue) {
      setValue(manipulateValue(event.target.value));
    } else {
      setValue(event.target.value);
    }
  };
  const inputBlurHandler = () => {
    setWasTouched(true);
  };

  const resetInput = () => {
    setValue(initialValue);
    setWasTouched(false);
  };

  return {
    value,
    hasError,
    isValid,
    changeValueHandler,
    inputBlurHandler,
    resetInput,
    wasChanged
  };
};

export default useFormInput;
