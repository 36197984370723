import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiWrapper from "../../../utils/ApiWrapper";
import { RootState } from "../../../types/redux-types";
import { IClaimSearchDto } from "../../../types/DTO/IClaimSearchDto";
import { IClaimDTO } from "../../../types/DTO/IClaimDTO";
import {
  EventType,
  IPageMeta,
  Nullable,
  StatusType
} from "../../../types/general";
import { IClaimCreateDetails } from "../../../types/DTO/IClaimCreateDto";
import IQuoteDTO from "../../../types/DTO/IQuoteDTO";
import { getUserById } from "../../../app/common/API-Requests";
import { IUserDto } from "../../../types/DTO/IUserDto";
import TokenService from "../../../utils/TokenService";
import { IUserPolicyInfoDto } from "../../../types/DTO/IUsersInfoDto";
import { searchPolicyPage } from "../../policies/slice/thunks";

type GetClaimsResponse = {
  claims: IClaimDTO[];
  pageMeta?: IPageMeta;
};

const searchClaimsDTO = async (
  search: string,
  page?: { page: number }
): Promise<GetClaimsResponse> => {
  const searchClaimsQueryDTO: IClaimSearchDto = {
    search,
    sort: "created",
    order: "down",
    ...page
  };
  const response = await ApiWrapper.request<GetClaimsResponse>(
    "/claims/search",
    "POST",
    {
      data: searchClaimsQueryDTO
    }
  );
  return response;
};

export const getClaimsPage = createAsyncThunk<
  { claims: IClaimDTO[]; pageMeta?: IPageMeta } | undefined,
  number,
  { state: RootState }
>("claims/getClaimsPage", async (page, { getState }) => {
  const { accessToken } = getState().authentication.user!.authData;
  const { claimSearchText, claimsFetchingInProcessAmount } = getState().claims;

  if (claimsFetchingInProcessAmount > 1) {
    return;
  }

  if (!accessToken) {
    return { claims: [] };
  }

  let response;
  try {
    response = await searchClaimsDTO(claimSearchText, { page });
  } catch (error: any) {
    TokenService.removeUser();
    throw new Error(error.response.data.message);
  }
  return response;
});

export const createClaim = createAsyncThunk<
  any,
  IClaimCreateDetails,
  { state: RootState }
>(
  "claims/createClaim",
  async (
    { customerId, policyId, subject, intent, subIntent },
    { getState, dispatch }
  ) => {
    const { accessToken } = getState().authentication.user!.authData;
    if (!accessToken) {
      return { claims: [] };
    }

    const response = await ApiWrapper.request<any>("/claims/create", "POST", {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        user_id: customerId,
        userPolicyId: policyId,
        subject,
        intent,
        subIntent
      }
    });
    dispatch(getClaimsPage(0));
    return response;
  }
);

export const editClaimSubject = createAsyncThunk<
  any,
  {
    claimId: number;
    subject: string;
    oldSubject: string;
    selectedClaimPage: number;
  },
  { state: RootState }
>(
  "claims/editClaimSubject",
  async ({ claimId, subject, oldSubject, selectedClaimPage }, { dispatch }) => {
    if (subject === oldSubject) return;
    const response = await ApiWrapper.request<any>(`/claims/subject`, "PUT", {
      data: { claimId, subject }
    });
    dispatch(getClaimsPage(selectedClaimPage));
    return response;
  }
);

export const fetchClaimChat = createAsyncThunk<
  any,
  { userId: number; claimIdentifier?: string },
  { state: RootState }
>("claims/fetchChatByUserId", async ({ userId, claimIdentifier }) => {
  return await ApiWrapper.request<any>(
    `/chat/user/${userId}/${claimIdentifier}`,
    "GET"
  );
});

export const fetchClaimComments = createAsyncThunk<
  any,
  { claimId: number },
  { state: RootState }
>("claims/fetchCommentsByClaimId", async ({ claimId }) => {
  return await ApiWrapper.request<any>(`/claim-notes/claim/${claimId}`, "GET");
});

export const fetchClaimPayments = createAsyncThunk<
  any,
  { claimId: number },
  { state: RootState }
>("claims/fetchPaymentsByClaimId", async ({ claimId }) => {
  return await ApiWrapper.request<any>(
    `/bank/transfers/claim/${claimId}`,
    "GET"
  );
});

export const fetchClaimDocuments = createAsyncThunk<
  any,
  { claimId: number },
  { state: RootState }
>("claims/fetchDocumentsByClaimId", async ({ claimId }) => {
  return await ApiWrapper.request<any>(`/docs/claim/${claimId}`, "GET");
});

export const uploadClaimDocument = createAsyncThunk<
  any,
  { claimId: string; userId: string; file: any },
  { state: RootState }
>("claims/uploadClaimDocument", async ({ claimId, userId, file }) => {
  const formData = new FormData();
  formData.append("file", file, file.name);
  formData.append("userId", userId);
  formData.append("claimId", claimId);
  const res = await ApiWrapper.request<any>(`/docs/upload`, "POST", {
    data: formData
  });
  return res;
});

export const openDocument = createAsyncThunk<
  any,
  { Key: string },
  { state: RootState }
>("docs/openDocument", async ({ Key }) => {
  const res = await ApiWrapper.request<any>(`/docs/download/${Key}`, "GET");
  return res;
});

export const deleteDocument = createAsyncThunk<
  any,
  { docsId: number },
  { state: RootState }
>("docs/openDocument", async ({ docsId }) => {
  const res = await ApiWrapper.request<any>(`/docs/${docsId}`, "DELETE");
  return res;
});

export const fetchClaimHistory = createAsyncThunk<
  any,
  { tableId: number; eventType: EventType },
  { state: RootState }
>("claims/fetchHistoryByClaimId", async ({ tableId, eventType }) => {
  return await ApiWrapper.request<any>(
    `/event-log/search/eventType/${eventType}/tableId/${tableId}`,
    "GET"
  );
});

export const fetchPolicyPDF = createAsyncThunk<
  string,
  { policyId: number },
  { state: RootState }
>("claims/fetchPolicyPDF", async ({ policyId }) => {
  const response = await ApiWrapper.request<any>(
    `/quote/policy/${policyId}`,
    "GET",
    { responseType: "arraybuffer" }
  );
  return URL.createObjectURL(new Blob([response], { type: "application/pdf" }));
});

export const changeClaimStatus = createAsyncThunk<
  any,
  { claimId: number; status: StatusType; selectedClaimPage: number },
  { state: RootState }
>(
  "claims/changeClaimStatus",
  async ({ claimId, status, selectedClaimPage }, { dispatch }) => {
    const res = await ApiWrapper.request<any>(
      `/claims/claim/${claimId}/status/${status}`,
      "PUT"
    );
    dispatch(getClaimsPage(selectedClaimPage));
    return res;
  }
);

export const addClaimComment = createAsyncThunk<
  any,
  {
    claimId: number;
    userId: number;
    created: string;
    updated?: string;
    note: string;
    id?: number;
  },
  { state: RootState }
>(
  "claims/addCommentToClaim",
  async ({ claimId, userId, created, updated, note, id }, { dispatch }) => {
    if (!updated) updated = created;
    const response = await ApiWrapper.request<any>(
      `/claim-notes/addOrUpdateUser`,
      "POST",
      { data: { claimId, userId, created, updated, note, id } }
    );
    dispatch(fetchClaimComments({ claimId }));
    return response;
  }
);

export const deleteClaimComment = createAsyncThunk<
  any,
  { noteId: number; claimId: number },
  { state: RootState }
>("claims/deleteComment", async ({ noteId, claimId }, { dispatch }) => {
  const response = await ApiWrapper.request<any>(
    `/claim-notes/${noteId}`,
    "DELETE"
  );
  dispatch(fetchClaimComments({ claimId }));
  return response;
});

export const fetchSelectedClaimQuote = createAsyncThunk<
  Nullable<IQuoteDTO>,
  Nullable<number>,
  { state: RootState }
>("claims/fetchPolicyById", async (policyId) => {
  if (!policyId) {
    return null;
  }
  return await ApiWrapper.request<any>(`/policy/userPolicy/${policyId}`, "GET");
});

export const fetchSelectedClaimUserProfile = createAsyncThunk<
  IUserDto,
  number,
  { state: RootState }
>("claims/fetchUserById", async (userId) => {
  return getUserById(userId.toString());
});

export const connectClaimToPolicy = createAsyncThunk<
  any,
  number,
  { state: RootState }
>("claims/connectClaimToPolicy", async (policyId, { getState, dispatch }) => {
  const {
    claim: { id: claimId },
    userId
  } = getState().claims.selectedClaim!;
  const response = await ApiWrapper.request<any>(
    `/claims/connect/user-policy`,
    "PUT",
    { data: { claimId, policyId, userId } }
  );
  if (response === 201) {
    dispatch(getClaimsPage(0));
    return 201;
  } else {
    throw new Error(response.message);
  }
});

export const fetchSelectedClaimPolicy = createAsyncThunk<
  IUserPolicyInfoDto | null,
  string,
  { state: RootState }
>("claims/getClaimPolicy", async (policyCode) => {
  const policiesPage = await searchPolicyPage(policyCode, 0);
  if (policiesPage) {
    return policiesPage.policies[0] as IUserPolicyInfoDto;
  }
  return null;
});
