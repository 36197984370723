import React, { useEffect } from "react";
import classnames from "classnames";
import styled from "styled-components";
import TabPanel from "../../../../TabPanel";
import { IUserPolicyInfoDto } from "types/DTO/IUsersInfoDto";
import { useAppDispatch, useAppSelector } from "hooks/redux-hooks";
import { Box } from "@mui/system";
import { fetchPolicyPayments } from "components/policies/slice/thunks";
import {
  paymentsAreLoading,
  selectSelectedPolicy,
  selectSelectedPolicyPayments
} from "components/policies/slice/selectors";
import SingleCustomerPayment from "./SingleCustomerPayment";
import SpinnerLoader from "components/ui/loaders/SpinnerLoader/SpinnerLoader";
import CreateNewPayment from "./CreateNewPayment";

const PaymentsTabContainer = styled(TabPanel)`
  &.payments-tab {
    padding: 24px 24px 100px 24px;
    & > .payment-list {
      display: flex;
      flex-direction: column;
      gap: 5px;
      padding: 5px;
    }

    & .payment-header {
      display: flex;
      justify-content: space-between;
    }

    & .payments-title {
      font-weight: 600;
      font-size: 18px;
      line-height: 20px;
    }
  }
`;

type Payment = {
  amount: string;
  claimId: number;
  created: string;
  currency: string;
  id: number;
  modifierId: number;
  paymentType: string;
  reason: string;
  state: string;
  update: string;
  user_id: number;
};

interface PaymentTabProps {
  selectedPolicy: IUserPolicyInfoDto;
}

const PaymentsTab: React.FC<PaymentTabProps> = ({ selectedPolicy }) => {
  const dispatch = useAppDispatch();
  const userPolicy = useAppSelector(selectSelectedPolicy);
  const customerPayments = useAppSelector(selectSelectedPolicyPayments);
  const isLoading = useAppSelector(paymentsAreLoading);
  const userFirstName =
    !!userPolicy && !!userPolicy.user && !!userPolicy.user.firstName
      ? userPolicy.user.firstName + "'s"
      : "";
  useEffect(() => {
    dispatch(fetchPolicyPayments({ id: selectedPolicy.userId }));
  }, [selectedPolicy, dispatch]);

  const content =
    !customerPayments || customerPayments.length === 0 ? (
      <div>
        <p>No transactions exist</p>
      </div>
    ) : (
      <Box className="payment-list">
        {customerPayments &&
          customerPayments.map((payment: Payment) => {
            return <SingleCustomerPayment key={payment.id} payment={payment} />;
          })}
      </Box>
    );

  const component = isLoading ? (
    <SpinnerLoader isLoading={true} />
  ) : (
    <PaymentsTabContainer
      currTabIndex={3}
      tabIndex={3}
      isHidden={Boolean(!selectedPolicy)}
      className={classnames("payments-tab", "tab-container")}
    >
      <div className="payment-header">
        <h2 className="payments-title">{userFirstName} transactions</h2>
        <CreateNewPayment />
      </div>
      {content}
    </PaymentsTabContainer>
  );

  return <>{component}</>;
};

export default PaymentsTab;
