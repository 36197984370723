import React, { FocusEvent, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import ApiWrapper from "utils/ApiWrapper";
import InputAdornment from "@mui/material/InputAdornment";
import classnames from "classnames";
import { useAppDispatch, useAppSelector } from "hooks/redux-hooks";
import {
  addPolicyComment,
  fetchPolicyPage,
  fetchPolicyTrips
} from "components/policies/slice/thunks";
import CopyToClipboardButton from "components/ui/CopyToClipboardButton";
import {
  selectPoliciesPagination,
  selectSelectedPolicy
} from "components/policies/slice/selectors";
import styled from "styled-components";

const ChangeTripCostDialogContainer = styled(Dialog)`
  & .MuiPaper-root {
    min-width: 600px;
  }
  & .btn {
    max-width: 343px;
    max-height: 56px;
    border: 1px solid #4726d1;
    box-shadow: 0 4px 10px rgba(165, 165, 165, 0.25);
    border-radius: 4px;
    font-weight: 550;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: unset;
  }

  & .accept {
    color: #ffffff;
    background-color: #4726d1;
  }

  & .accept:hover {
    color: #ffffff;
    background-color: #4726d1;
  }

  .disabled {
    display: none;
  }

  & .cancel {
    color: #4726d1;
    border: 1px solid #4726d1;
  }

  & .contentContainer {
    display: grid;
    grid-template-columns: 2fr 1fr 2fr 1fr 4fr;
  }

  & .rt {
    color: lightgrey;
    display: block;
  }

  & .text {
    display: block;
  }

  & .spacer {
    font-size: 0;
    height: 10px;
    line-height: 0;
  }

  & .input {
    padding: 1%;
  }

  & .input > div > input {
    height: 5px;
  }

  & .input > div {
    margin-top: 10px;
    line-height: 15px;
  }

  & .premium {
    border: none;
  }

  & .dialogActions {
    padding: 16px 24px;
  }

  & .premium > p {
    margin-left: 1px;
  }

  & .conformationAction {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  & .paymentLinkContainer {
    display: flex;
    align-items: center;
  }
`;

type newPremiumType = {
  diff: string;
  newPrice: number;
  oldPrice: number;
};

interface ChangeTripCostDialogProps {
  policyId: number;
  originalTripCost: string;
  openTripCostDialog: (value: boolean) => void;
  originalPremium: string;
  disableCharge: boolean;
  reasonTripCostIsNotAffected: string;
}

const ChangeTripCostDialog: React.FC<ChangeTripCostDialogProps> = ({
  policyId,
  originalTripCost,
  openTripCostDialog,
  originalPremium,
  disableCharge,
  reasonTripCostIsNotAffected
}) => {
  const [newPremium, setNewPremium] = useState<null | newPremiumType>(null);
  const [currentTripCost, setCurrentTripCost] = useState<number>(
    +originalTripCost
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCreatingNewPolicy, setIsCreatingNewPolicy] =
    useState<boolean>(false);
  const [validated, setValidated] = useState<boolean>(true);
  const [confirmationMessage, setConfirmationMessage] = useState<string | null>(
    null
  );
  const [url, setUrl] = useState<string>("");
  const selectedCustomer = useAppSelector(selectSelectedPolicy);
  const pagination = useAppSelector(selectPoliciesPagination);
  const dispatch = useAppDispatch();
  const currentPage = pagination ? pagination.currentPage : 0;

  const handleClose = () => {
    if (!isLoading && !isCreatingNewPolicy) {
      setCurrentTripCost(+originalTripCost);
      setNewPremium(null);
      if (!!confirmationMessage) {
        dispatch(fetchPolicyTrips({ userId: selectedCustomer!.userId }));
        dispatch(fetchPolicyPage(currentPage));
      }
      openTripCostDialog(false);
    }
  };
  const changePolicyPrice = async (event: FocusEvent<HTMLInputElement>) => {
    const newTripCost = +event.target.value;
    if (isTripCostValidated(newTripCost)) {
      setCurrentTripCost(newTripCost);
      if (
        newTripCost !== +originalTripCost &&
        newTripCost !== currentTripCost
      ) {
        setIsLoading(true);
        const newPremium = await getNewPremium(policyId, newTripCost);
        setNewPremium(newPremium);
        setIsLoading(false);
        return;
      }
    } else {
      setNewPremium(null);
    }
  };

  const getNewPremium = async (policyId: number, newTripCost: number) => {
    try {
      return await ApiWrapper.request<newPremiumType>(
        `/policy/amendment/check/tripCost/policyId/${policyId}/cost/${newTripCost}`,
        "GET"
      );
    } catch (_e) {
      setConfirmationMessage("Error getting updated premium");
      return null;
    }
  };

  const isTripCostValidated = (cost: number) => {
    const validated = cost >= 500 && cost <= 40000;
    setValidated(validated);
    return validated;
  };

  const updatePolicyTripCost = async (applyCharge: boolean) => {
    setIsCreatingNewPolicy(true);
    try {
      const res = await ApiWrapper.request<string | number>(
        `/policy/amendment/change/cost`,
        "POST",
        {
          data: {
            policyId,
            cost: currentTripCost,
            isChangePrice: applyCharge
          }
        }
      );
      if (res === 202) {
        setConfirmationMessage("User was successfully refunded");
      } else if (typeof res === "string") {
        dispatch(
          addPolicyComment({
            userId: selectedCustomer!.userId,
            created: new Date().toString(),
            note: `Trip cost was changed from $${Math.floor(
              +originalTripCost
            )} to $${currentTripCost}. Premium was changed from $${
              newPremium?.oldPrice
            } to $${
              newPremium?.newPrice
            } and a payment link was created: ${res}`,
            id: 0,
            tripId: 0
          })
        );
        setUrl(res);
        setConfirmationMessage(
          "*Policy will update as soon as the user finishes payment process."
        );
      }
    } catch (_e) {
      setConfirmationMessage("Updating user policy failed");
    }
    setIsCreatingNewPolicy(false);
  };
  let chargeOrRefundTxt =
    newPremium && +newPremium.diff >= 0
      ? "Apply without charge"
      : "Apply without refund";
  if (disableCharge) {
    if (+originalTripCost > currentTripCost) {
      chargeOrRefundTxt = "Apply without refund";
    }
  }

  useEffect(() => {
    if (+originalTripCost === currentTripCost) {
      setNewPremium(null);
      setConfirmationMessage(null);
    }
  }, [originalTripCost, currentTripCost]);

  const ChargeOrRefundButton =
    !confirmationMessage &&
    newPremium &&
    +newPremium.diff !== 0.0 &&
    Math.abs(+newPremium.diff) >= 0.5 ? (
      +newPremium.diff > 0 ? (
        <Button
          disabled={!newPremium || disableCharge}
          className={
            disableCharge
              ? classnames("btn", "disabled")
              : classnames("btn", "accept")
          }
          onClick={() => {
            updatePolicyTripCost(true);
          }}
        >
          {`  Create a $${newPremium.diff} charge`}
        </Button>
      ) : (
        <Button
          disabled={!newPremium}
          className={classnames("btn", "accept")}
          onClick={() => {
            updatePolicyTripCost(true);
          }}
        >
          {`  Create a $${Math.abs(+newPremium.diff)} refund`}
        </Button>
      )
    ) : null;

  const refundOrPaymentMessage =
    url !== "" ? (
      <>
        <p>Payment link:</p>
        <div className="paymentLinkContainer">
          <a href={`${url}`} target="_blank" rel="noopener noreferrer">
            {url}
          </a>
          <CopyToClipboardButton value={url} />
        </div>
        <p>{confirmationMessage}</p>
        <p>**Payment link is saved in the comment section.</p>
      </>
    ) : (
      <p>{confirmationMessage}</p>
    );

  const actionsSection = isCreatingNewPolicy ? (
    <p className="dialogActions">Loading... </p>
  ) : !!confirmationMessage ? (
    <DialogActions className="dialogActions">
      <Button className={classnames("btn", "cancel")} onClick={handleClose}>
        Close
      </Button>
    </DialogActions>
  ) : (
    <DialogActions className="dialogActions">
      {newPremium && !confirmationMessage && (
        <Button
          className={classnames("btn", "cancel")}
          onClick={async () => {
            setIsCreatingNewPolicy(true);
            await updatePolicyTripCost(false);
            setConfirmationMessage("Successfully update policy");
            setIsCreatingNewPolicy(false);
          }}
        >
          {chargeOrRefundTxt}
        </Button>
      )}
      {ChargeOrRefundButton}
    </DialogActions>
  );

  const refundOrChargeConformationHandler = !!confirmationMessage ? (
    <DialogContent
      className={classnames("dialogActions", "conformationAction")}
    >
      {refundOrPaymentMessage}
    </DialogContent>
  ) : null;

  return (
    <ChangeTripCostDialogContainer
      open={true}
      disableEscapeKeyDown
      onClose={() => {
        !confirmationMessage && handleClose();
      }}
    >
      <DialogTitle>Edit trip cost</DialogTitle>
      <DialogContent>
        <div className="contentContainer">
          <div className="spacer"></div>
          <div className="spacer"></div>
          <p className="rt">Current</p>
          <div className="spacer"></div>
          <p className="rt">Updated</p>
          <div className="spacer"></div>
        </div>
        <div className="contentContainer">
          <p className="text">Trip cost:</p>
          <div className="spacer"></div>
          <p className="text">{`$${originalTripCost}`}</p>
          <p className="rt">{`-->`}</p>
          <TextField
            className="input"
            id="tripCost"
            inputProps={{ max: 40000, min: 500 }}
            onBlur={changePolicyPrice}
            defaultValue={currentTripCost}
            type="number"
            variant="outlined"
            disabled={isLoading || !!confirmationMessage || isCreatingNewPolicy}
            error={!validated}
            helperText={
              !validated ? "Trip cost must be between $500 and $40000" : null
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              )
            }}
          />
          <div className="spacer"></div>
        </div>
        <div className="contentContainer">
          <p className="text">Premium:</p>
          <div className="spacer"></div>
          <p className="text">{`$${originalPremium}`}</p>
          {(newPremium || isLoading) && <p className="rt">{`-->`}</p>}
          {newPremium && !isLoading && (
            <TextField
              className={classnames("input", "premium")}
              defaultValue={newPremium.newPrice}
              id="premium"
              disabled
              type="number"
              variant="outlined"
              helperText={
                !!+newPremium.diff
                  ? +newPremium.diff > 0
                    ? `Premium is $${newPremium.diff} higher`
                    : `Premium is $${Math.abs(+newPremium.diff)} lower`
                  : !!reasonTripCostIsNotAffected.length
                  ? reasonTripCostIsNotAffected
                  : "Premium unchanged"
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                )
              }}
              error
            />
          )}
          {isLoading && <p className="text">Loading...</p>}
          <div className="spacer"></div>
        </div>
      </DialogContent>
      {refundOrChargeConformationHandler}
      {actionsSection}
    </ChangeTripCostDialogContainer>
  );
};
export default ChangeTripCostDialog;
