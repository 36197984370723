import React, { useEffect } from "react";
import {
  CLASS_SECTION_CARD,
  SECTION_USER_DETAILS
} from "../../constants/common";
import styled from "styled-components";
import Container from "@mui/material/Container";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import {
  selectSelectedUser,
  selectSelectedUserDetails,
  selectSelectedUserEdit
} from "./slice/selectors";
import Avatar from "@mui/material/Avatar";
import EditDetailsForm from "./EditDetailsForm";
import { fetchSelectedUserProfile } from "./slice/thunks";
import UserDetails from "./UserDetails";
import PlaceHolder from "./PlaceHolder";
import CardActions, { SECTION_ACTIONS } from "./CardActions";
import Skeleton from "@mui/material/Skeleton";

const SECTION_AVATAR = "card-avatar";

interface AdvisorCardProps {}

const AdvisorCardContainer = styled(Container)`
  &.${CLASS_SECTION_CARD} {
    flex-basis: 30%;

    padding: 36px 34px 24px;
    display: grid;
    grid-template-rows: 54px 26px auto;
    grid-template-columns: 80px 20px 284px auto 76px;
    grid-template-areas:
      "${SECTION_AVATAR} . ${SECTION_USER_DETAILS} . ${SECTION_ACTIONS}"
      "${SECTION_AVATAR} . ${SECTION_USER_DETAILS} .                  ."
      ".                 . ${SECTION_USER_DETAILS} .                  .";

    & .${SECTION_AVATAR} {
      grid-area: ${SECTION_AVATAR};
      width: unset;
      height: unset;
    }
    & .${SECTION_USER_DETAILS} {
      grid-area: ${SECTION_USER_DETAILS};
    }
  }
`;

const AdvisorCard: React.FC<AdvisorCardProps> = () => {
  const dispatch = useAppDispatch();
  const selectedUserDetails = useAppSelector(selectSelectedUserDetails);
  const selectedUserEdit = useAppSelector(selectSelectedUserEdit);
  const selectedUser = useAppSelector(selectSelectedUser);

  useEffect(() => {
    if (selectedUser) {
      if (!selectedUserDetails) {
        dispatch(fetchSelectedUserProfile(selectedUser.id));
      }
    }
  }, [selectedUser, dispatch, selectedUserDetails]);

  return (
    <AdvisorCardContainer className={CLASS_SECTION_CARD}>
      {selectedUser && (
        <>
          {Boolean(selectedUserDetails) ? (
            <Avatar
              className={SECTION_AVATAR}
              src={selectedUserDetails!.profilePicture!}
            />
          ) : (
            <Skeleton variant="circular" className={SECTION_AVATAR} />
          )}
          {!selectedUserDetails && !selectedUserEdit.isOn && <PlaceHolder />}
          {Boolean(selectedUserDetails) && selectedUserEdit.isOn && (
            <EditDetailsForm
              selectedUserDetails={selectedUserDetails!}
              updatingInProgress={selectedUserEdit.updatingInProcess}
            />
          )}
          {Boolean(selectedUserDetails) && !selectedUserEdit.isOn && (
            <UserDetails selectedUserDetails={selectedUserDetails!} />
          )}
          {!selectedUserEdit.isOn && <CardActions />}
        </>
      )}
    </AdvisorCardContainer>
  );
};

export default React.memo(AdvisorCard);
