import {
  signInWithEmailAndPassword,
  UserCredential,
  User,
  signInWithPopup
} from "firebase/auth";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAuthInstance,
  getGoogleAuthProviderInstance
} from "../../../integrations/firebase";
import TokenService from "../../../utils/TokenService";

export type ResponseUser = User & {
  accessToken: string;
  expirationTime: number;
};

export type AppUserAuthData = {
  accessToken?: string;
  refreshToken: string;
  expirationTime: number;
};

export type AppUserData = {
  displayName?: string;
  email?: string;
  photoURL?: string;
  authData: AppUserAuthData;
  uid?: string;
};

const buildAppUserData = ({
  displayName,
  email,
  photoURL,
  accessToken,
  refreshToken,
  expirationTime,
  uid
}: {
  displayName: string;
  email: string;
  photoURL: string;
  accessToken: string;
  refreshToken: string;
  expirationTime: number;
  uid: string;
}) => ({
  displayName,
  email,
  photoURL,
  authData: {
    accessToken,
    refreshToken,
    expirationTime
  },
  uid
});

export const signInUsingEmailPassword = createAsyncThunk<
  AppUserData,
  { email: string; password: string },
  {}
>(
  "authentication/signInUsingEmailPassword",
  async ({ email, password }: { email: string; password: string }) => {
    const auth = getAuthInstance();
    let response: UserCredential = await signInWithEmailAndPassword(
      auth!,
      email,
      password
    );
    const {
      displayName,
      photoURL,
      accessToken,
      refreshToken,
      expirationTime,
      uid
    } = response.user as ResponseUser;
    const userToStore: AppUserData = buildAppUserData({
      displayName: displayName!,
      email: email!,
      photoURL: photoURL!,
      accessToken,
      refreshToken,
      expirationTime,
      uid
    });
    TokenService.setUser(userToStore);

    return userToStore;
  }
);

export const signInWithGoogle = createAsyncThunk<AppUserData, void, {}>(
  "authentication/signInWithGoogle",
  async () => {

    const auth = getAuthInstance();
    const googleAuthProviderInstance = getGoogleAuthProviderInstance();
    const response: UserCredential = await signInWithPopup(
      auth!,
      googleAuthProviderInstance!
    );
    const {
      displayName,
      email,
      photoURL,
      accessToken,
      refreshToken,
      expirationTime,
      uid
    } = response.user as ResponseUser;
    const userToStore: AppUserData = buildAppUserData({
      displayName: displayName!,
      email: email!,
      photoURL: photoURL!,
      accessToken,
      refreshToken,
      expirationTime,
      uid
    });
    TokenService.setUser(userToStore);
    return userToStore;
  }
);
