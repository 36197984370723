import React from "react";
import { IDashboardPoliciesAndRevenues } from "../../types/DTO/IDashboardDto";
import styled from "styled-components";
import Container from "@mui/material/Container";
import { Box } from "@mui/material";
import DashboardData from "./DashboardData";
import {
  calcRatio,
  numberCurrencyFormat,
  safeToFixed
} from "./dashboard-helper";

interface RevenueTileProps {
  className: string;
  revenueInfo: IDashboardPoliciesAndRevenues;
}

const RevenueTileContainer = styled(Container)`
  & .inner-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    & .info {
      display: flex;
    }
  }
`;

const RevenueTile: React.FC<RevenueTileProps> = ({
  className,
  revenueInfo
}) => {
  return (
    <RevenueTileContainer className={className}>
      <Box className="inner-container">
        <Box>
          <span className="tile-name">Revenue</span>{" "}
          <span className="tile-description">income</span>
        </Box>
        <Box>
          <span className="tile-amount">
            ${numberCurrencyFormat(safeToFixed(revenueInfo.allRevenue))}
          </span>
        </Box>
        <Box className="info">
          <DashboardData
            text={"Last 7 days"}
            amount={numberCurrencyFormat(
              safeToFixed(revenueInfo.sevenRevenueDays)
            )}
            delta={calcRatio(
              revenueInfo.previousRevenueSevenDays,
              revenueInfo.sevenRevenueDays
            )}
          ></DashboardData>
          <DashboardData
            text={"24hr"}
            amount={numberCurrencyFormat(
              safeToFixed(revenueInfo.lastRevenueDay)
            )}
            delta={calcRatio(
              revenueInfo.sevenRevenueDays / 7,
              revenueInfo.lastRevenueDay
            )}
          ></DashboardData>
        </Box>
      </Box>
    </RevenueTileContainer>
  );
};

export default React.memo(RevenueTile);
