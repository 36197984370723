import React, { useEffect } from "react";
import classnames from "classnames";
import styled from "styled-components";
import TabPanel from "../../../../TabPanel";
import { IUserPolicyInfoDto } from "types/DTO/IUsersInfoDto";
import { useAppDispatch, useAppSelector } from "hooks/redux-hooks";
import {
  commentsAreLoading,
  selectSelectedPolicyComments
} from "components/policies/slice/selectors";
import Comment from "./Comment";
import { Box } from "@mui/material";
import AddComment from "./AddComment";
import { fetchPolicyComments } from "components/policies/slice/thunks";
import SpinnerLoader from "components/ui/loaders/SpinnerLoader/SpinnerLoader";

const CommentsTabContainer = styled(TabPanel)`
  &.comments-tab {
    display: flex;
    flex-direction: column;
    padding: 24px 0 100px;
    position: relative;

    & > .comment-list {
      display: flex;
      flex-direction: column;
      gap: 5px;
      padding-bottom: 100px;
    }
  }
`;

type CommentType = {
  id: number;
  created: string;
  updated: string;
  note: string;
  userId: number;
  claimId: number;
  name: string;
  modifierName: string;
};

interface CommentsTabProps {
  selectedPolicy: IUserPolicyInfoDto;
}

const UserCommentsTab: React.FC<CommentsTabProps> = ({ selectedPolicy }) => {
  const dispatch = useAppDispatch();
  const comments = useAppSelector(selectSelectedPolicyComments);
  const isLoading = useAppSelector(commentsAreLoading);

  const content =
    !comments || comments.length === 0 ? (
      <div>
        <p>No comments exists</p>
      </div>
    ) : (
      <Box className="comment-list">
        {comments &&
          comments.map((comment: CommentType) => (
            <Comment key={comment.id} comment={comment} />
          ))}
      </Box>
    );
  const component = isLoading ? (
    <SpinnerLoader isLoading={true} />
  ) : (
    <CommentsTabContainer
      currTabIndex={3}
      tabIndex={3}
      isHidden={Boolean(!selectedPolicy)}
      className={classnames("comments-tab", "tab-container")}
    >
      {content}
      <AddComment selectedCustomer={selectedPolicy} />
    </CommentsTabContainer>
  );

  useEffect(() => {
    dispatch(
      fetchPolicyComments({
        userId: selectedPolicy.userId
      })
    );
  }, [selectedPolicy, dispatch]);
  return <>{component}</>;
};

export default UserCommentsTab;
