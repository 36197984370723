import React, { useEffect } from "react";
import classnames from "classnames";
import styled from "styled-components";
import TabPanel from "../../../../TabPanel";
import { IClaim } from "../../../../../types/general";
import {
  useAppDispatch,
  useAppSelector
} from "../../../../../hooks/redux-hooks";
import {
  selectSelectedClaimDocuments,
  tabIsLoading
} from "../../../slice/selectors";
import { fetchClaimDocuments } from "../../../slice/thunks";
import { Box } from "@mui/material";
import UploadDocument from "./UploadDocument";
import SingleDocument from "./SingleDocument";
import SpinnerLoader from "../../../../ui/loaders/SpinnerLoader/SpinnerLoader";

const DocumentsTabContainer = styled(TabPanel)`
  &.documents-tab {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    & > .document-list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      padding: 10px;
      gap: 10px;
    }
    & > .upload-document {
    }
  }
`;

type Document = {
  id: number;
  userId: number;
  claimId: number;
  userPolicyId: number;
  created: string;
  updated: string;
  media: {
    ETag: string;
    Location: string;
    key: string;
    Key: string;
    Bucket: string;
  };
};

interface DocumentsTabProps {
  selectedClaim: IClaim;
}

const DocumentsTab: React.FC<DocumentsTabProps> = ({ selectedClaim }) => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(tabIsLoading);
  const claimDocuments: Document[] = useAppSelector(
    selectSelectedClaimDocuments
  );
  useEffect(() => {
    dispatch(fetchClaimDocuments({ claimId: selectedClaim.claim.id }));
  }, [dispatch, selectedClaim]);

  const content = isLoading ? (
    <SpinnerLoader isLoading={true} />
  ) : (
    <>
      {" "}
      <Box className={"document-list"}>
        {claimDocuments &&
          claimDocuments.map((item) => {
            if (!item.media.Key) return item;
            return (
              <SingleDocument
                key={item.id}
                Key={item.media.Key}
                id={item.id}
                claimId={selectedClaim.claim.id}
              />
            );
          })}
      </Box>
      <Box className={"upload-document"}>
        <UploadDocument
          claimId={selectedClaim.claim.id.toString()}
          userId={selectedClaim.userId.toString()}
        />
      </Box>
    </>
  );

  return (
    <DocumentsTabContainer
      currTabIndex={1}
      tabIndex={1}
      isHidden={Boolean(!selectedClaim)}
      className={classnames("documents-tab", "tab-container")}
    >
      {content}
    </DocumentsTabContainer>
  );
};

export default DocumentsTab;
