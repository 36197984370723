import React, { useEffect } from "react";
import styled from "styled-components";
import TabPanel, { CLASS_TAB_CONTAINER } from "../../../../TabPanel";
import classnames from "classnames";
import { IUserPolicyInfoDto } from "types/DTO/IUsersInfoDto";
import { useAppSelector, useAppDispatch } from "hooks/redux-hooks";
import { fetchPolicyTrips } from "components/policies/slice/thunks";
import {
  selectSelectedPolicyTrips,
  tripsAreLoading
} from "components/policies/slice/selectors";
import TripList from "./TripList";
import SpinnerLoader from "components/ui/loaders/SpinnerLoader/SpinnerLoader";

const CLASS_POLICY_TAB = "policy-tab";

interface PoliciesTabProps {
  selectedTabIndex: number;
  tabIndex: number;
  selectedPolicy: IUserPolicyInfoDto;
}

const PoliciesTabContainer = styled(TabPanel)`
  &.${CLASS_POLICY_TAB} {
    padding: 24px 0 100px 0;
  }
`;

const TripsTab: React.FC<PoliciesTabProps> = ({
  selectedTabIndex,
  tabIndex,
  selectedPolicy
}) => {
  const dispatch = useAppDispatch();
  const policies = useAppSelector(selectSelectedPolicyTrips);
  const isLoading = useAppSelector(tripsAreLoading);
  const userId = selectedPolicy.userId;

  useEffect(() => {
    dispatch(fetchPolicyTrips({ userId }));
  }, [userId, dispatch]);

  const component = isLoading ? (
    <SpinnerLoader isLoading={true} />
  ) : (
    <PoliciesTabContainer
      currTabIndex={selectedTabIndex}
      tabIndex={tabIndex}
      isHidden={Boolean(!selectedPolicy)}
      className={classnames(CLASS_POLICY_TAB, CLASS_TAB_CONTAINER)}
    >
      {<TripList selectedPolicy={selectedPolicy} policies={policies} />}
    </PoliciesTabContainer>
  );

  return component;
};

export default React.memo(TripsTab);
