import React, { useCallback, useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import styled from "styled-components";
import { Box, Button } from "@mui/material";
import Container from "@mui/material/Container";
import {
  thunkChangeDates,
  fetchPolicyTrips,
  fetchPolicyPage
} from "../slice/thunks";
import { useAppDispatch, useAppSelector } from "hooks/redux-hooks";
import { abortChangeDatesModal } from "../slice";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import TextField from "@mui/material/TextField";
import { Nullable } from "types/general";
import {
  selectChangeDatesModalOpen,
  selectPoliciesPagination
} from "../slice/selectors";
import { format } from "date-fns";
import {
  getMaxHawaiiEndDateAccordingToStartDate,
  getMaxEndDateAccordingToStartDate,
  getMaxEndDateFromToday, getTimeZonelessDate
} from "utils/dates";

interface ChangeDatesModalProps {}

const ChangeDatesModalContainer = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;

  & .modal-content {
    padding: 20px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
    border-radius: 8px;
  }

  & .saveError {
    color: red;
  }

  & .saveSuccess {
    color: green;
  }
`;

const ChangeDatesModal: React.FC<ChangeDatesModalProps> = () => {
  const dispatch = useAppDispatch();
  const pagination = useAppSelector(selectPoliciesPagination);
  const {
    isOpen,
    policy,
    tripStartDate,
    tripEndDate,
    userId,
    saveMessage,
    isSaveError
  } = useAppSelector(selectChangeDatesModalOpen);
  const yesterday = new Date(new Date().setHours(-24));
  const [startDate, setStartDate] = useState<Nullable<Date>>(
      getTimeZonelessDate(new Date(tripStartDate))
  );
  const [endDate, setEndDate] = useState<Nullable<Date>>(getTimeZonelessDate(new Date(tripEndDate)));

  const startDateRef = useRef(startDate);
  const endDateRef = useRef(endDate);
  const isResidencyHawaii = policy.residency?.ariaLevel1 === "HI";
  const handleChangeClick = useCallback(() => {
    dispatch(
      thunkChangeDates({
        policyId: policy.id,
        tripStartDate: startDate,
        tripEndDate: endDate
      })
    );
  }, [dispatch, startDate, endDate, policy]);

  const handleCloseChangeDatesModal = useCallback(() => {
    const currentPage = pagination ? pagination.currentPage : 0;
    if (!isSaveError) {
      dispatch(fetchPolicyTrips({ userId }));
      dispatch(fetchPolicyPage(currentPage));
    }
    dispatch(abortChangeDatesModal());
  }, [dispatch, isSaveError, userId, pagination]);

  const preventKeyDown = (e: any) => {
    e.preventDefault();
  };

  return (
    <ChangeDatesModalContainer
      open={isOpen}
      onClose={handleCloseChangeDatesModal}
    >
      <Container className="modal-content">
        <p>
          The user will receive an email canceling the old policy and sending
          the new one to his inbox
        </p>
        <br />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="mm/dd/yyyy"
            value={startDate}
            onChange={(newValue) => {
              setStartDate(newValue);
            }}
            minDate={yesterday}
            maxDate={getMaxEndDateFromToday()}
            renderInput={(params) => (
              <TextField onKeyDown={preventKeyDown} {...params} />
            )}
          />
        </LocalizationProvider>
        <br />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="mm/dd/yyyy"
            value={endDate}
            minDate={startDate}
            maxDate={
              isResidencyHawaii
                ? getMaxHawaiiEndDateAccordingToStartDate(startDate)
                : getMaxEndDateAccordingToStartDate(startDate)
            }
            onChange={(newValue) => {
              setEndDate(newValue);
            }}
            renderInput={(params) => (
              <TextField onKeyDown={preventKeyDown} {...params} />
            )}
          />
        </LocalizationProvider>
        <br />

        {!saveMessage && (
          <Button
            variant="contained"
            disabled={
              format(startDate!, "yyyy-MM-dd") ===
                format(startDateRef.current!, "yyyy-MM-dd") &&
              format(endDate!, "yyyy-MM-dd") ===
                format(endDateRef.current!, "yyyy-MM-dd")
            }
            onClick={handleChangeClick}
          >
            Change Dates
          </Button>
        )}
        {saveMessage && (
          <Button variant="contained" onClick={handleCloseChangeDatesModal}>
            Close
          </Button>
        )}
        <br />
        <Box className={isSaveError ? "saveError" : "saveSuccess"}>
          {saveMessage}
        </Box>
      </Container>
    </ChangeDatesModalContainer>
  );
};

export default ChangeDatesModal;
