import React from "react";
import styled from "styled-components";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import SingleClaim from "../single-claim";
import { IUserPolicyInfoDto } from "../../../../../../types/DTO/IUsersInfoDto";
import { openNewClaimModal } from "../../../../../claims/slice";
import { useAppDispatch } from "../../../../../../hooks/redux-hooks";
import classnames from "classnames";

interface ClaimListProps {
  claims: any;
  selectedCustomer?: IUserPolicyInfoDto;
}

const ClaimListContainer = styled(Box)`
  &.claim-list-container {
    display: flex;
    flex-direction: column;
    gap: 4px;

    & .btn {
      max-width: 20%;
      max-height: 56px;
      border: 1px solid #4726d1;
      box-shadow: 0 4px 10px rgba(165, 165, 165, 0.25);
      border-radius: 4px;
      font-style: normal;
      font-weight: 550;
      font-size: 16px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 0.02em;
      text-transform: unset;
      color: #ffffff;
      background-color: #4726d1;
      margin-bottom: 20px;
    }

    & .margin {
      margin-top: 20px;
    }
    & > h4 {
      font-size: 12px;
      font-weight: bold;
    }
    & > .claim-list-headers {
      display: flex;
      align-items: center;
      padding: 0 16px;
      width: calc(100% - 1.2em);
      & > * {
        font-size: 12px;
        font-weight: bold;
        flex: 1;
        align-self: flex-start;
      }
    }
  }
`;

const ClaimList: React.FC<ClaimListProps> = ({ claims, selectedCustomer }) => {
  const dispatch = useAppDispatch();
  const handleAddClaimClicked = () => {
    dispatch(openNewClaimModal());
  };
  const addClaim = !!claims ? (
    <Button
      onClick={handleAddClaimClicked}
      className={classnames({ margin: claims.length <= 0 }, "btn")}
    >
      Add claim
    </Button>
  ) : null;
  if (!claims || claims.length <= 0) {
    return (
      <ClaimListContainer className="claim-list-container">
        <Typography variant="h4">No connected claims</Typography>
        {addClaim}
      </ClaimListContainer>
    );
  }

  return (
    <ClaimListContainer className="claim-list-container">
      {addClaim}
      <Box className="claim-list-headers">
        <Typography>Identifier</Typography>
        <Typography>Subject</Typography>
        <Typography>Status</Typography>
        <Typography>Requested</Typography>
        <Typography>Total paid</Typography>
      </Box>
      {claims.map((claim: any) => {
        return (
          <SingleClaim
            key={claim.id}
            selectedCustomer={selectedCustomer}
            claim={claim}
          />
        );
      })}
    </ClaimListContainer>
  );
};

export default ClaimList;
