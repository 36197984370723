import React, { useState } from "react";
import { Button } from "@mui/material";
import { useAppDispatch } from "hooks/redux-hooks";
import { fetchPolicyPDF } from "components/policies/slice/thunks";
import styled from "styled-components";
import PersonIcon from "@mui/icons-material/Person";

interface SinglePolicyProps {
  policy: any;
}

const SinglePolicyContainer = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 2fr 2fr;

  & .space {
    margin-right: 60px;
  }

  & .avatar-container {
    width: 38px;
    height: 38px;
    background-color: #bdbdbd;
    border-radius: 50%;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .btn {
    color: var(--primary-color);
    user-select: text;
  }
`;

const SinglePolicy: React.FC<SinglePolicyProps> = ({ policy }) => {
  const dispatch = useAppDispatch();
  const [linkIsLoading, setLinkIsLoading] = useState(false);

  function openInNewTab(url: string) {
    const win = window.open(url, "_blank");
    if (win !== null) {
      win.focus();
    }
  }

  const openPDF = () => {
    setLinkIsLoading(true);
    dispatch(fetchPolicyPDF({ policyId: policy.id })).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        openInNewTab(res.payload);
        setLinkIsLoading(false);
      }
    });
  };

  const avatar = !!policy.traveler ? (
    <span className="avatar">
      {`${
        !!policy.traveler.firstName ? policy.traveler.firstName.charAt(0) : ""
      }${!!policy.traveler.lastName ? policy.traveler.lastName.charAt(0) : ""}`}
    </span>
  ) : (
    <PersonIcon className="avatar" />
  );

  const userName = `${
    !!policy.traveler.firstName ? policy.traveler.firstName : ""
  } ${!!policy.traveler.lastName ? policy.traveler.lastName : ""}`;

  return (
    <SinglePolicyContainer>
      <div className="avatar-container">{avatar}</div>
      <p className="space">{userName}</p>
      <Button disabled={linkIsLoading} className="space btn" onClick={openPDF}>
        {policy.code}
      </Button>
    </SinglePolicyContainer>
  );
};

export default SinglePolicy;
