import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../types/redux-types";
import ApiWrapper from "../../../utils/ApiWrapper";
import { IUserPolicyInfoDto } from "../../../types/DTO/IUsersInfoDto";
import { IPageMeta } from "../../../types/general";
import { IUserSearchDto } from "../../../types/DTO/IUserSearchDto";
import { IUserDto } from "../../../types/DTO/IUserDto";
import { getUserById } from "../../../app/common/API-Requests";
import TokenService from "../../../utils/TokenService";

type GetUsersResponse = {
    usersInfo: IUserPolicyInfoDto[];
    pageMeta?: IPageMeta;
};

const searchUsersPage = async (
    search: string,
    page: number
): Promise<GetUsersResponse> => {
    const UserSearchQueryDto: IUserSearchDto = {
        search,
        page,
        sort: "name",
        order: "down",
        role: "advisor"
    };

    const response = await ApiWrapper.request<GetUsersResponse>(
        "/user/names/agent",
        "POST",
        {
            data: UserSearchQueryDto
        }
    );

    return response;
};

export const fetchUsersPage = createAsyncThunk<
    GetUsersResponse | undefined,
    number,
    { state: RootState }
    >("Users/fetchUsers", async (page, { getState }) => {
    const { UsersSearchText, UsersFetchingInProcessAmount } =
        getState().users;

    if (UsersFetchingInProcessAmount > 1) {
        return;
    }

    let response;
    try {
        response = await searchUsersPage(UsersSearchText, page);
    } catch (error: any) {
        TokenService.removeUser();
        throw new Error(error.response.data.message);
    }
    return response;
});

export const fetchSelectedUserProfile = createAsyncThunk<
    IUserDto,
    number,
    { state: RootState }
    >("Users/fetchUserById", async (userId) => {
    return getUserById(userId.toString());
});

export const updateUser = createAsyncThunk<
    IUserDto,
    { firstName: string; lastName: string; advisorId: string; mobile: string, active: boolean },
    { state: RootState }
    >("Users/updateUser", async ({ firstName, lastName, advisorId, mobile, active}, { getState, dispatch }) => {
    const selectedUserDetails = getState().users.selectedUserDetails!;
    const data = { ...selectedUserDetails, firstName, lastName, advisorId, mobile, active};
    const response = await ApiWrapper.request<IUserDto>("/user/addOrUpdate", "POST", {
        data
    });
    dispatch(fetchUsersPage( 0));
    return response;
});

export const createUser = createAsyncThunk<
    IUserDto,
    { firstName: string; lastName: string; email: string; advisorId: string; mobile: string, role: string },
    { state: RootState }
    >("Users/create", async ({ firstName, lastName, email, advisorId, mobile, role },
                             { dispatch }) => {
    const data = { firstName, lastName, email, advisorId, mobile, role };
    const response =  await ApiWrapper.request<IUserDto>("/advisor/create", "POST", {
        data
    });
    dispatch(fetchUsersPage( 0));
    return response;
});
