import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styled from "styled-components";
import SinglePolicy from "./SinglePolicy";
import SingleFlight from "./SingleFlight";
import { formatDateIgnoreTimeZone, US_DATE_FORMAT } from "utils/dates";
import ChangeTripCostDialog from "components/policies/modals/ChangeTripCostDialog";
import {
  createPolicyPdf,
  fetchPolicyPDF
} from "components/policies/slice/thunks";
import { useAppDispatch, useAppSelector } from "hooks/redux-hooks";
import {
  openCancelPolicyModal,
  openDatesModal
} from "components/policies/slice";
import moment from "moment";
import LoadingButton from "@mui/lab/LoadingButton";
import classnames from "classnames";
import { documentsCreationStatuses } from "../../../../slice/selectors";

interface SingleTripProps {
  policy: any;
  selectedPolicy: any;
}

const SingleTripContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: var(--box-shadow);
  padding: 30px;
  width: 100%;
  margin: 0 auto 20px;

  & .MuiAccordionSummary-content {
    flex-grow: 0;
  }

  & .MuiButtonBase-root {
    padding: 0;
    justify-content: start;
  }

  & .travellers-accordion {
    border: none;
    box-shadow: none;
    padding: 10px 0 10px 0;

    ::before {
      height: 0;
    }
  }

  & .single-trip-accordion-details {
    padding: 0;
  }

  & .MuiCollapse-wrapperInner {
    width: unset;
  }

  & .single-trip-accordion-summary {
    max-width: 80%;
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    padding-left: 20px;
  }

  & .trip-header {
    display: flex;
    padding: 10px 0 30px 0;
    align-items: center;
    border-bottom: 1px solid #f5f5f5;
  }

  & .trip-status-indicator {
    height: 18px;
    width: 18px;
    border-radius: 50%;
    background-color: var(--policy-status-not-active);
  }

  & .in-trip {
    background-color: var(--trip-status-in-trip);
  }

  & .post-trip {
    background-color: var(--trip-status-post-trip);
  }

  & .pre-trip {
    background-color: var(--trip-status-pre-trip);
  }

  & .active {
    background-color: var(--policy-status-active);
  }

  & .uuid {
    color: var(--primary-color);
    font-weight: 550;
    font-size: 18px;
    line-height: 20px;
    cursor: pointer;
    margin: 0;
  }

  & .trip-destinations {
    font-weight: 550;
    font-size: 18px;
  }

  & .warning {
    color: var(--text-warning);
    white-space: nowrap;
  }

  & .spacer {
    min-width: 120px;
  }

  & .policy-details {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-rows: 1fr 2fr;
    padding: 20px 0 20px;
    align-items: start;
  }

  & .secondary-text {
    color: var(--secondary-text-color);
  }

  & .policy-btn {
    padding: 10px;
    text-transform: none;
    font-weight: 350;
    font-size: 14px;
    line-height: 28px;
  }

  & .cancel {
    color: var(--text-warning);
    border: 1px solid var(--text-warning);
    margin-left: 20px;
  }

  & .buttons-container {
    align-self: end;
  }

  & .no-background:hover {
    background-color: unset;
  }

  & .destinations-container {
    padding-right: 20px;
  }

  & .payment-service {
    text-transform: uppercase;
    margin: 0 5px;
  }

  & .disabled {
    color: gray;
    cursor: text;
  }
`;

interface ICoveragesThatAffectTripCost {
  [key: string]: boolean;
}

const SingleTrip: React.FC<SingleTripProps> = ({ policy }) => {
  const dispatch = useAppDispatch();
  const documentsStatuses = useAppSelector(documentsCreationStatuses);
  const startDate = formatDateIgnoreTimeZone(
    policy.tripStartDate,
    US_DATE_FORMAT
  );
  const endDate = formatDateIgnoreTimeZone(policy.tripEndDate, US_DATE_FORMAT);
  const [travellersAccordionOpen, setTravellersAccordionOpen] = useState(false);
  const [flightsAccordionOpen, setFlightsAccordionOpen] = useState(false);
  const [openTripCostDialog, setOpenTripCostDialog] = useState<boolean>(false);
  const [creatingPdf, setCreatingPdf] = useState(false);
  const [linkIsLoading, setLinkIsLoading] = useState(false);

  const isPostTrip = moment().isAfter(moment(new Date(endDate)).add(1, "d"));
  const paymentServiceProviderInitial = policy.paymentService
    ? policy.paymentService.charAt(0)
    : null;
  const handleOpenChangeDatesModal = useCallback(() => {
    dispatch(
      openDatesModal({
        policy: policy,
        tripStartDate: policy.tripStartDate,
        tripEndDate: policy.tripEndDate
      })
    );
  }, [dispatch, policy]);

  const openPDF = () => {
    setLinkIsLoading(true);
    dispatch(fetchPolicyPDF({ policyId: policy.id })).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        openInNewTab(res.payload);
      }
      setLinkIsLoading(false);
    });
  };

  function openInNewTab(url: string) {
    const win = window.open(url, "_blank");
    if (win !== null) {
      win.focus();
    }
  }

  const destinations = policy.destination.map((item: any, index: number) => {
    if (policy.destination.length - 1 === index) {
      return item.label;
    }
    return item.label + " , ";
  });

  const reasonTripCostIsNotAffected = useRef("");

  useEffect(() => {
    const coveragesThatAffectTripCost: ICoveragesThatAffectTripCost = {
      cancelForAnyReason: false,
      tripCancellation: false
    };
    for (const coverage of policy.coverages) {
      if (
        coverage.coverageType === "cancelForAnyReason" ||
        coverage.coverageType === "tripCancellation"
      ) {
        coveragesThatAffectTripCost[coverage.coverageType] =
          coverage.isSelected;
      }
    }
    if (
      !coveragesThatAffectTripCost.cancelForAnyReason &&
      !coveragesThatAffectTripCost.tripCancellation
    ) {
      reasonTripCostIsNotAffected.current =
        "Customer does not have trip cancellation coverage, no change to premium.";
    }
  }, [policy]);
  useEffect(() => {
    const documentLoading = !!documentsStatuses.find(
      (tempDoc) =>
        tempDoc.status === "uploading" && tempDoc.policyId === policy.id
    );
    setCreatingPdf(documentLoading);
    return () => {
      setCreatingPdf(false);
    };
  }, [documentsStatuses, policy]);

  const disableCharge =
    !!reasonTripCostIsNotAffected.current.length ||
    (!!policy.paymentService && policy.paymentService === "checkOut");

  const destination =
    destinations.length > 1
      ? `${policy.destination[0].label} & ${destinations.length - 1} more`
      : policy.destination[0].label;

  const singlePolicies =
    policy.policies && policy.policies.length > 0 ? (
      policy.policies
        .map((policy: any) => <SinglePolicy key={policy.id} policy={policy} />)
        .reverse()
    ) : (
      <p>No connected travelers</p>
    );

  const singleFlights =
    policy.flights && policy.flights.length > 0 ? (
      policy.flights.map((flight: any, index: number) => (
        <SingleFlight
          isActive={policy.isActive}
          policyId={policy.id}
          flight={flight}
          key={index}
        />
      ))
    ) : (
      <p>No connected fights</p>
    );

  const today = moment();
  const tripStatus = policy.isActive
    ? moment(policy.tripStartDate).isBefore(today)
      ? "pre-trip"
      : moment(policy.tripEndDate).isAfter(today)
      ? "in-trip"
      : "post-trip"
    : "warning";

  const creatNewPdf = async () => {
    setCreatingPdf(true);
    await dispatch(createPolicyPdf({ policyId: policy.id }));
    setCreatingPdf(false);
  };

  return (
    <SingleTripContainer className="single-trip-container">
      <div className="trip-header">
        <div className={`trip-status-indicator ${tripStatus}`}></div>
        <div className="single-trip-accordion-summary">
          <span
            className={classnames({
              "no-background": true,
              uuid: true,
              disabled: creatingPdf || linkIsLoading
            })}
            onClick={() => {
              if (!creatingPdf && !linkIsLoading) {
                openPDF();
              }
            }}
          >
            {policy.userPolicyUniqueUUID}
          </span>
          <div className="trip-destinations">{destination}</div>
          <div className="trip-premium">
            ${policy.cost}
            <span className="payment-service">
              {paymentServiceProviderInitial}
            </span>
          </div>

          <div className="spacer"></div>
        </div>
      </div>
      <div className="policy-details">
        <span className="secondary-text">Destinations</span>
        <span className="secondary-text">Dates</span>
        <span className="secondary-text">Trip cost</span>
        <div className="destinations-container">
          <span>{destinations}</span>
        </div>
        <Button
          className="no-background"
          variant="text"
          disabled={!policy.isActive || creatingPdf || isPostTrip}
          onClick={() => handleOpenChangeDatesModal()}
        >
          {`${startDate} - ${endDate}`}
        </Button>
        <Button
          className="no-background"
          variant="text"
          disabled={!policy.isActive || creatingPdf}
          onClick={() => setOpenTripCostDialog(true)}
        >
          ${policy.tripCost}
        </Button>
      </div>

      <Accordion
        expanded={travellersAccordionOpen}
        className="travellers-accordion"
      >
        <AccordionSummary
          onClick={() => setTravellersAccordionOpen(!travellersAccordionOpen)}
          expandIcon={<ExpandMoreIcon />}
          className="accordion-summary"
        >
          <span>Travelers</span>
        </AccordionSummary>
        <AccordionDetails className="single-trip-accordion-details">
          {singlePolicies}
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={flightsAccordionOpen}
        className="travellers-accordion"
      >
        <AccordionSummary
          onClick={() => setFlightsAccordionOpen(!flightsAccordionOpen)}
          expandIcon={<ExpandMoreIcon />}
          className="accordion-summary"
        >
          <span>Flights</span>
        </AccordionSummary>
        <AccordionDetails className="single-trip-accordion-details">
          {singleFlights}
        </AccordionDetails>
      </Accordion>
      {policy.isActive && (
        <div className="buttons-container">
          <LoadingButton
            variant="outlined"
            className="policy-btn"
            onClick={async () => await creatNewPdf()}
            disabled={!policy.isActive || creatingPdf}
            loading={creatingPdf}
          >
            Generate new pdf
          </LoadingButton>

          <Button
            variant="outlined"
            className="cancel policy-btn"
            onClick={() => dispatch(openCancelPolicyModal(policy))}
            disabled={!policy.isActive || creatingPdf}
          >
            Cancel policy
          </Button>
        </div>
      )}
      {openTripCostDialog && (
        <ChangeTripCostDialog
          policyId={policy.id}
          originalTripCost={`${policy.tripCost}`}
          openTripCostDialog={setOpenTripCostDialog}
          originalPremium={policy.cost}
          disableCharge={disableCharge}
          reasonTripCostIsNotAffected={reasonTripCostIsNotAffected.current}
        />
      )}
    </SingleTripContainer>
  );
};

export default SingleTrip;
