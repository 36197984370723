import React, { useEffect } from "react";
import classnames from "classnames";
import styled from "styled-components";
import TabPanel from "../../../../TabPanel";
import { IUserPolicyInfoDto } from "types/DTO/IUsersInfoDto";
import ClaimList from "./claims-list";
import { useAppDispatch, useAppSelector } from "hooks/redux-hooks";
import {
  claimsAreLoading,
  selectSelectedPolicyClaims
} from "components/policies/slice/selectors";
import { fetchPolicyClaims } from "components/policies/slice/thunks";
import AddClaimModal from "components/claims/Modals/AddClaimModal";
import { selectNewClaimModalOpen } from "components/claims/slice/selectors";
import SpinnerLoader from "components/ui/loaders/SpinnerLoader/SpinnerLoader";

const ClaimsTabContainer = styled(TabPanel)`
  &.claims-tab {
    padding: 24px 24px 100px 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & > .claims-list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      padding: 10px;
      gap: 10px;
    }
  }
`;

interface ClaimsTabProps {
  selectedPolicy: IUserPolicyInfoDto;
}

const UserClaimsTab: React.FC<ClaimsTabProps> = ({ selectedPolicy }) => {
  const dispatch = useAppDispatch();
  const newClaimModalOpen = useAppSelector(selectNewClaimModalOpen);
  const claims = useAppSelector(selectSelectedPolicyClaims);
  const isLoading = useAppSelector(claimsAreLoading);

  useEffect(() => {
    dispatch(fetchPolicyClaims({ userId: selectedPolicy.userId }));
  }, [selectedPolicy, dispatch]);

  const content = isLoading ? (
    <SpinnerLoader isLoading={true} />
  ) : (
    <ClaimList selectedCustomer={selectedPolicy} claims={claims} />
  );

  return (
    <ClaimsTabContainer
      currTabIndex={1}
      tabIndex={1}
      isHidden={Boolean(!selectedPolicy)}
      className={classnames("claims-tab", "tab-container")}
    >
      <AddClaimModal policyHolder={selectedPolicy} isOpen={newClaimModalOpen} />
      {content}
    </ClaimsTabContainer>
  );
};

export default UserClaimsTab;
