import React from "react";
import styled from "styled-components";
import {
  useAppDispatch,
  useAppSelector
} from "../../../../../hooks/redux-hooks";
import { addClaimComment } from "../../../slice/thunks";
import { updateClaimCommentText } from "../../../slice";
import { selectClaimCommentText } from "../../../slice/selectors";
import {
  InputLabel,
  OutlinedInput,
  InputAdornment,
  FormControl,
  IconButton
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { IClaim } from "../../../../../types/general";

const AddCommentContainer = styled(FormControl)`
  &.add-comment {
    width: 85%;
    max-width: 1100px;
    background-color: #ffffff;
    position: fixed;
    bottom: 0;
    margin: auto;
    padding: 0 0 30px 0;
    z-index: 1;
    & > .add-comment-input {
      border-radius: 42px;
    }
  }
`;

interface AddCommentProps {
  selectedClaim: IClaim;
}

export const AddComment: React.FC<AddCommentProps> = ({ selectedClaim }) => {
  const dispatch = useAppDispatch();
  const claimCommentText = useAppSelector(selectClaimCommentText);

  return (
    <AddCommentContainer variant="outlined" className="add-comment">
      <InputLabel>Comment...</InputLabel>
      <OutlinedInput
        multiline
        type="text"
        className="add-comment-input"
        value={claimCommentText ? claimCommentText : ""}
        onChange={(e) => dispatch(updateClaimCommentText(e.target.value))}
        label="Comment..."
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              onClick={(e) => {
                dispatch(
                  addClaimComment({
                    claimId: selectedClaim.claim.id,
                    userId: selectedClaim.userId,
                    created: new Date().toString(),
                    note: claimCommentText
                  })
                ).then((res) => {
                  if (res.meta.requestStatus === "fulfilled")
                    dispatch(updateClaimCommentText(""));
                });
              }}
            >
              <AddIcon />
            </IconButton>
          </InputAdornment>
        }
      />
    </AddCommentContainer>
  );
};

export default AddComment;
