import React from "react";
import styled from "styled-components";
import Box from "@mui/material/Box";
import { SECTION_ACTIONS } from "../CardActions";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";

const CLASS_BUTTON_APPLY = "button-apply";
const CLASS_UPDATE_USER_IN_PROGRESS_INDICATOR =
  "class-update-user-in-progress-indicator";

interface EditDetailsFormActionsProps {
  onClickApply(): void;
  updatingInProgress: boolean;
}

const CardActionsContainer = styled(Box)`
  &.${SECTION_ACTIONS} {
    grid-area: ${SECTION_ACTIONS};
    display: flex;
    align-items: flex-start;

    & .MuiIconButton-root {
      width: 24px;
      height: 24px;
      padding: 0;
    }
    & .MuiSvgIcon-root {
      width: 16px;
      height: 16px;
    }

    & .${CLASS_BUTTON_APPLY} {
      width: 54px;
      height: 24px;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      margin-right: 6px;
      overflow: hidden;
    }
    & .${CLASS_UPDATE_USER_IN_PROGRESS_INDICATOR} {
      width: 100%;
      position: absolute;
      height: 2px;
      top: 0;
    }
  }
`;

const EditDetailsFormActions: React.FC<EditDetailsFormActionsProps> = ({
  onClickApply,
  updatingInProgress
}) => {
  return (
    <CardActionsContainer className={SECTION_ACTIONS}>
      <Button
        className={CLASS_BUTTON_APPLY}
        color="primary"
        variant="contained"
        onClick={onClickApply}
      >
        {updatingInProgress && (
          <LinearProgress
            className={CLASS_UPDATE_USER_IN_PROGRESS_INDICATOR}
            color="secondary"
          />
        )}
        Apply
      </Button>
    </CardActionsContainer>
  );
};

export default React.memo(EditDetailsFormActions);
