import React, { useEffect } from "react";
import "./App.css";
import { selectToken } from "components/authentication/selectors";
import { useHistory } from "react-router-dom";
import { LOGIN_URL } from "Routes/constants";
import Routes from "Routes";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import styled from "styled-components";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import useFirebase from "hooks/useFirebase";
import WebFont from "webfontloader";
import { useAppSelector } from "hooks/redux-hooks";
import GenericModal from "components/modals/GenericModal";
import { StyledEngineProvider } from "@mui/material/styles";
import Header from "components/ui/Header";
import SideNav from "components/ui/SideNav";

const AppContainer = styled((props) => (
  <Container component="main" {...props} />
))`
  &.app-container {
    max-width: none;
    height: 100vh;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: 48px auto;
    grid-template-rows: 72px auto;
  }

  & .routes-container {
    grid-row-start: 2;
    grid-row-end: 5;
    grid-column-start: 2;
    grid-column-end: 5;
    overflow-y: scroll;
  }
`;

const App = () => {
  useFirebase();
  const accessToken = useAppSelector(selectToken);
  const history = useHistory();

  useEffect(() => {
    if (!accessToken) {
      history.push(LOGIN_URL);
    }
  }, [history, accessToken]);

  useEffect(() => {
    WebFont.load({
      google: {
        families: ["TWK Lausanne"]
      }
    });
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <AppContainer className="app-container">
          <Header />
          <SideNav />
          <div className="routes-container">
            <Routes />
          </div>
          <GenericModal />
          <CssBaseline />
        </AppContainer>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
