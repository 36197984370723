import React, { useState } from "react";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Container from "@mui/material/Container";
import styled from "styled-components";
import ClaimTab from "./Tabs/ClaimTab";
import ChatTab from "./Tabs/ChatTab";
import CommentsTab from "./Tabs/CommentsTab";
import PaymentsTab from "./Tabs/PaymentsTab";
import {
  selectConnectPolicyModal,
  selectPagination,
  selectSelectedClaim
} from "../slice/selectors";
import ConnectPolicyModal from "../Modals/ConnectPolicyModal";
import { useAppSelector } from "../../../hooks/redux-hooks";
import HistoryTab from "./Tabs/HistoryTab";
import DocumentsTab from "./Tabs/DocumentsTab";

interface ClaimPreviewProps {}

const ClaimPreviewPropsContainer = styled(Container)`
  &.section-preview {
    min-height: 70%;
    padding: 22px 26px 32px 26px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.13);
    border-radius: 12px;

    & .MuiTabs-root {
      & .MuiTab-root {
        font-weight: bold;
        text-transform: none;
        min-width: unset;
        border: 1px solid #bdbdbd;
      }

      & .MuiTabs-indicator {
        background-color: var(--primary-color);
      }
    }

    & label {
      color: #959493;
      font-weight: 500;
      line-height: 24px;
    }
  }
  &.claim-view-section {
    display: grid;
    grid-template-rows: 48px auto;
    grid-template-columns: 1fr;
    grid-template-areas:
      "tab-elements"
      "tab-content";

    & .tab-elements-container {
      grid-area: tab-elements;
    }
    & .tab-container {
      grid-area: tab-content;
      overflow: auto;
    }
  }
`;

const ClaimPreview: React.FC<ClaimPreviewProps> = () => {
  const { isOpen: connectPolicyModalOpen } = useAppSelector(
    selectConnectPolicyModal
  );

  const [tabIndex, setTabIndex] = useState(0);
  const selectedClaimPage = useAppSelector(selectPagination);
  const selectedClaim = useAppSelector(selectSelectedClaim);
  return (
    <ClaimPreviewPropsContainer className="section-preview claim-view-section">
      <Tabs
        value={tabIndex}
        textColor="primary"
        indicatorColor="primary"
        className="tab-elements-container"
        variant="fullWidth"
      >
        <Tab onClick={() => setTabIndex(0)} label="Claim" />
        <Tab onClick={() => setTabIndex(1)} label="Docs" />
        <Tab onClick={() => setTabIndex(2)} label="Chat" />
        <Tab onClick={() => setTabIndex(3)} label="History" />
        <Tab onClick={() => setTabIndex(4)} label="Comments" />
        <Tab onClick={() => setTabIndex(5)} label="Payments" />
      </Tabs>
      {tabIndex === 0 ? (
        <ClaimTab
          selectedClaimPage={selectedClaimPage!.currentPage}
          selectedClaim={selectedClaim!}
        />
      ) : tabIndex === 1 ? (
        <DocumentsTab selectedClaim={selectedClaim!} />
      ) : tabIndex === 2 ? (
        <ChatTab selectedClaim={selectedClaim!} />
      ) : tabIndex === 3 ? (
        <HistoryTab selectedClaim={selectedClaim!} />
      ) : tabIndex === 4 ? (
        <CommentsTab selectedClaim={selectedClaim!} />
      ) : tabIndex === 5 ? (
        <PaymentsTab selectedClaim={selectedClaim!} />
      ) : (
        <div></div>
      )}
      {connectPolicyModalOpen && <ConnectPolicyModal />}
    </ClaimPreviewPropsContainer>
  );
};

export default ClaimPreview;
