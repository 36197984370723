import React from "react";
import Box from "@mui/material/Box";

export const CLASS_TAB_CONTAINER = "tab-container";

interface TabPanelProps {
  tabIndex: number;
  currTabIndex: number;
  isHidden: boolean;
  className?: string;
}

const TabPanel: React.FC<TabPanelProps> = ({
  tabIndex,
  currTabIndex,
  isHidden,
  className,
  children
}) => {
  return (
    <Box hidden={isHidden || currTabIndex !== tabIndex} className={className}>
      {currTabIndex === tabIndex && <>{children}</>}
    </Box>
  );
};

export default TabPanel;
