import React, { useEffect } from "react";
import styled from "styled-components";
import Container from "@mui/material/Container";
import ClaimCard from "./ClaimCard";
import {
  fetchSelectedClaimPolicy,
  fetchSelectedClaimQuote,
  fetchSelectedClaimUserProfile
} from "./slice/thunks";
import ClaimPreview from "./ClaimPreview";
import {
  selectSelectedClaim,
  selectSelectedClaimPolicy,
  selectSelectedClaimQuote,
  selectSelectedClaimUser
} from "./slice/selectors";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { resetSelectedClaim } from "./slice";

const CLASS_CLAIM_VIEW_CONTAINER = "claim-view-container";

interface ClaimViewProps {}

const ClaimViewContainer = styled(Container)`
  min-height: 90%;
  padding: 50px;
  & .claim-view-section {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.13);
    border-radius: 12px;
  }
`;

const ClaimView: React.FC<ClaimViewProps> = () => {
  const dispatch = useAppDispatch();
  const selectedClaim = useAppSelector(selectSelectedClaim);
  const selectedClaimUser = useAppSelector(selectSelectedClaimUser);
  const selectedClaimPolicy = useAppSelector(selectSelectedClaimPolicy);
  const selectedClaimQuote = useAppSelector(selectSelectedClaimQuote);

  useEffect(() => {
    if (selectedClaim) {
      if (!selectedClaimQuote) {
        dispatch(fetchSelectedClaimQuote(selectedClaim?.policyId ?? null));
      }
      if (selectedClaim.policyCode && !selectedClaimPolicy) {
        dispatch(fetchSelectedClaimPolicy(selectedClaim.policyCode));
      }
      if (!selectedClaimUser) {
        dispatch(fetchSelectedClaimUserProfile(selectedClaim.userId));
      }
    }
  }, [
    selectedClaim,
    selectedClaimUser,
    selectedClaimQuote,
    selectedClaimPolicy,
    dispatch
  ]);

  useEffect(() => {
    return () => {
      dispatch(resetSelectedClaim());
    };
  }, [dispatch]);

  return (
    <>
      {selectedClaim && (
        <ClaimViewContainer className={CLASS_CLAIM_VIEW_CONTAINER}>
          <ClaimCard />
          <ClaimPreview />
        </ClaimViewContainer>
      )}
    </>
  );
};

export default ClaimView;
