import React, { useState } from "react";
import classnames from "classnames";
import styled from "styled-components";
import TabPanel from "../../../TabPanel";
import {ClaimStatus, IClaim} from "../../../../types/general";
import {
  Stack,
  Select,
  FormControl,
  MenuItem,
  SelectChangeEvent,
  Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import { updateSelectedClaimStatus } from "../../slice";
import { useAppDispatch } from "../../../../hooks/redux-hooks";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { changeClaimStatus } from "../../slice/thunks";

interface ClaimTabProps {
  selectedClaim: IClaim;
  selectedClaimPage: number;
}

enum claimNames {
  baggageDelay = "Lost Baggage",
  passport_claim = "Lost Passport",
  initial = "Initial State",
  medicalAssistance = "Medical Assistance",
  lostSomething = "Lost Something"
}

const ClaimTabContainer = styled(TabPanel)`
  &.claim-tab {
    display: grid;
    grid-template-rows: auto 48px;
    grid-template-columns: 1fr;
    grid-template-areas:
      "claim-content"
      "claim-actions";

    & .claim-tab-content {
      grid-area: claim-content;

      & > .claim-subject-owner {
        font-size: 18px;
        font-weight: bold;

        & > .claim-tab-subject {
          font-size: 18px;
          font-weight: normal;
        }
      }

      & > .claim-tab-content-top {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        & > .claim-tab-dates {
          & > .date {
            font-size: 14px;
            color: #959493;

            & > span {
              font-weight: bold;
              color: black;
            }
          }
        }

        & > .claim-tab-status {
          flex-direction: row;
          align-items: center;
          gap: 2px;

          & > .status-indicator {
          }

          & > .active {
            color: green;
          }

          & > .closed {
            color: red;
          }

          & > .reopen {
            color: yellow;
          }

          & > .approvedReimbursed {
            color: teal;
          }

          & > .approvedPaid {
            color: purple;
          }

          & > .claim-status {
            flex-direction: row;
            align-items: center;

            & > .pick-status-select,
            .pick-status-select:before {
              border: none;
            }
          }
        }

        & > h3 {
          & label {
            margin-left: 10px;
          }
        }
      }
    }

    & .claim-tab-actions {
      grid-area: claim-actions;
      display: flex;
      justify-content: center;

      & .MuiButton-root {
        border-radius: 6px;
        width: 184px;
        height: 48px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-transform: none;

        &:last-child {
          margin-left: 8px;
        }
      }
    }
  }
`;

const ClaimTab: React.FC<ClaimTabProps> = ({
  selectedClaim,
  selectedClaimPage
}) => {
  const dispatch = useAppDispatch();
  const [changeStatus, setChangeStatus] = useState(selectedClaim.claimStatus);
  const claimSubject = selectedClaim?.claim?.subject ?? "";

  const setClaimStatus = (e: SelectChangeEvent) => {
    setChangeStatus(e.target.value);
    dispatch(
      changeClaimStatus({
        claimId: selectedClaim.claim.id,
        status: e.target.value,
        selectedClaimPage
      })
    );
    dispatch(updateSelectedClaimStatus(e.target.value));
  };

  return (
    <ClaimTabContainer
      currTabIndex={0}
      tabIndex={0}
      isHidden={Boolean(!selectedClaim)}
      className={classnames("claim-tab", "tab-container")}
    >
      <Box className="claim-tab-content">
        <Stack className="claim-tab-content-top">
          <h3>
            Claim <label>{selectedClaim?.claim?.identifier}</label>
          </h3>

          <Stack className="claim-tab-status">
            <FiberManualRecordIcon
              className={`status-indicator ${changeStatus}`}
            />

            <FormControl className="claim-status">
              <Select
                className="pick-status-select"
                value={changeStatus}
                onChange={(e) => setClaimStatus(e)}
                variant="standard"
              >
                {Object.entries(ClaimStatus).map((entry) => (
                  <MenuItem key={entry[0]} value={entry[1]}>
                    {entry[0]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Stack>
        <div className="claim-subject-owner">
          Subject: <span className="claim-tab-subject">{claimSubject}</span>
        </div>
        <Stack className={"claim-tab-dates"}>
          <Typography className={"date"}>
            {selectedClaim.dateCreated}
          </Typography>
        </Stack>
        <strong>{claimNames[selectedClaim?.intent]}</strong>
      </Box>
    </ClaimTabContainer>
  );
};

export default ClaimTab;
