import React from "react";
import PoliciesList from "components/policies/PoliciesList";
import PolicyView from "components/policies/PolicyView";

import Drawer from "@mui/material/Drawer";
import { useAppDispatch, useAppSelector } from "hooks/redux-hooks";
import {
  backButtonLoading,
  displayPolicy
} from "components/policies/slice/selectors";
import {
  resetDocumentsCreationStatuses,
  resetSelectedPolicy,
  setDisplayPolicy
} from "components/policies/slice";
import WestIcon from "@mui/icons-material/West";
import styled from "styled-components";
import { LoadingButton } from "@mui/lab";
import PolicyModalsContainer from "../components/policies/modals";

const PoliciesDrawer = styled(Drawer)`
   {
    & .MuiDrawer-paper {
      box-sizing: border-box;
      width: 97%;
      margin-top: 72px;
      background-color: var(--navbar-background);
    }

    & .loading-back-button {
      justify-content: start;
      margin: 1em;
      text-transform: unset;
      max-width: 2em;
      position: fixed;
    }
    & .loading-back-button-container {
      width: 100%;
      position: fixed;
      z-index: 2;
    }
  }
`;

interface PolicyPageProps {}

const PoliciesPage: React.FC<PolicyPageProps> = () => {
  const open = useAppSelector(displayPolicy);
  const isLoading = useAppSelector(backButtonLoading);
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(resetDocumentsCreationStatuses());
    dispatch(resetSelectedPolicy());
    dispatch(setDisplayPolicy(false));
  };

  return (
    <>
      <PoliciesDrawer
        onClose={() => {
          handleClose();
        }}
        anchor={"right"}
        hideBackdrop={true}
        open={open}
      >
        <div className="loading-back-button-container">
          <LoadingButton
            loading={isLoading}
            fullWidth={false}
            variant="text"
            onClick={handleClose}
            className="loading-back-button"
            size={"small"}
            startIcon={<WestIcon />}
          >
            Back
          </LoadingButton>
        </div>
        <PolicyView />
      </PoliciesDrawer>
      <PoliciesList />
      <PolicyModalsContainer />
    </>
  );
};

export default PoliciesPage;
