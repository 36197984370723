import React from "react";
import { IDashboardDestinations } from "../../types/DTO/IDashboardDto";
import styled from "styled-components";
import { Box } from "@mui/material";

interface TripsTileProps {
  className: string;
  tripsInfo: IDashboardDestinations;
}

const TripsTileContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 !important;
  & .header {
    font-size: 165%;
    padding: 20px;
    margin: 0;
  }

  & .destinations-table {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: 30px;
    flex-grow: 1;
    padding-bottom: 20px;
  }

  & .destination-line {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-right: 2px solid #a7daff;
  }

  & .destination-line:nth-child(even) {
    border-right: none;
  }

  & .total-container {
    background-color: var(--primary-color);
    color: white;
    border-radius: 7px;
    height: 10%;
    padding: 2%;
    display: flex;
    justify-content: center;
    align-items: baseline;
  }

  & .caption {
    font-size: 22px;
  }

  & .amount {
    font-size: 35px;
    margin-left: 10px;
  }
`;

const TripsTile: React.FC<TripsTileProps> = ({ className, tripsInfo }) => {
  return (
    <TripsTileContainer className={className}>
      {tripsInfo && (
        <>
          <div className="header">
            <span className="tile-name">Amount of travelers</span>
            <span className="tile-description"> in trip</span>
          </div>
          <Box className="destinations-table">
            {tripsInfo.destinations.map(function (destination, index) {
              return (
                <div className="destination-line" key={index}>
                  <div className="country">{destination.country}</div>{" "}
                  <div className="count">{destination.cnt}</div>
                </div>
              );
            })}
          </Box>
          <div className="total-container">
            <span className="caption">Total</span>
            <span className="amount">{tripsInfo.total}</span>
          </div>
        </>
      )}
    </TripsTileContainer>
  );
};

export default React.memo(TripsTile);
