import {
  addDays,
  format,
  getDaysInMonth,
  getMonth,
  getTime,
  getYear,
  setHours,
  setMilliseconds,
  setMinutes,
  setSeconds
} from "date-fns";
import { Nullable } from "../types/general";
import moment from "moment";
export const US_DATE_FORMAT = "MM/dd/yyyy";

export const getFormattedDate = (date: Nullable<Date>) =>
  date ? format(date, US_DATE_FORMAT) : "";

export const formatDateIgnoreTimeZone = (date: string, dateFormat: string) => {
  const dt = new Date(date);
  dt.setMinutes(dt.getMinutes() + dt.getTimezoneOffset());
  return format(dt, dateFormat);
};

export const dateMonthRegex = /^(0|0[1-9]|[1-9]|1[0-2])?$/;
export const dateDayRegex = /^(0|0[1-9]|[1-9]|1\d|2\d|3[01])?$/;
export const dateYearRegex = /^(1|19|19\d{1,2}|2|20|20\d{1,2})?$/;
export const dateTextRegex = /^\d{2}\/\d{2}\/\d{4}$/;
export const MAX_DAYS_FROM_TODAY = 730;
export const MAX_DAYS_FROM_TRIP_START_DATE = 180;
export const MAX_DAYS_FROM_TRIP_START_DATE_FOR_HAWAII = 90;

export const isValid = (value: string, validationRegex: RegExp) => {
  const match = value.match(validationRegex);
  return Boolean(match);
};

export const isDayInMonth =
  (month: string, year: string) => (dayValue: string) => {
    const testYear = year.length === 4 && isYearValid(year) ? +year : 2000;
    const testMonth = month && isMonthValid(month) ? +month - 1 : 0;
    return !(+dayValue > getDaysInMonth(new Date(testYear, testMonth)));
  };

export const isMonthValid = (month: string) => isValid(month, dateMonthRegex);
export const isDayValid =
  (isDayInMonth: (day: string) => boolean) => (day: string) => {
    const isDayRegexOK = isValid(day, dateDayRegex);
    const isDayInMonthOK = isDayInMonth(day);
    return isDayInMonthOK && isDayRegexOK;
  };
export const isYearValid = (year: string) => isValid(year, dateYearRegex);

export const isDateStringValid = (dateText: string) => {
  if (!isValid(dateText, dateTextRegex)) {
    return false;
  } else {
    const [month, day, year] = dateText.split("/");
    const monthValid = isMonthValid(month);
    const yearValid = isYearValid(year);
    const dayValid = isDayValid(isDayInMonth(month, year))(day);
    return monthValid && yearValid && dayValid;
  }
};

export const isDateInTheFuture = (date: Date) => {
  const today = new Date();
  if (
    date.getFullYear() > today.getFullYear() ||
    (date.getFullYear() === today.getFullYear() &&
      date.getMonth() > today.getMonth()) ||
    (date.getFullYear() === today.getFullYear() &&
      date.getMonth() === today.getMonth() &&
      date.getDate() >= today.getDate())
  ) {
    return true;
  }
  return false;
};

export const getDefaultCalendarMonth = (tripStartDateObject: Date) => {
  const month = getMonth(tripStartDateObject);
  const year = getYear(tripStartDateObject);
  return new Date(year, month);
};

export const getDatesRangeString = (
  startDate: Date | undefined,
  endDate: Date | undefined
) => {
  if (!startDate || !endDate) {
    return "";
  } else {
    const startMonth = format(new Date(startDate), "MMM");
    const endMonth = format(new Date(endDate), "MMM");
    const startYear = format(new Date(startDate), "yyyy");
    const endYear = format(new Date(endDate), "yyyy");
    const startDay = format(new Date(startDate), "d");
    const endDay = format(new Date(endDate), "d");
    switch (true) {
      case startYear === endYear && startMonth !== endMonth:
        return `${startMonth} ${startDay} - ${endMonth} ${endDay}, ${endYear}`;
      case startYear === endYear && startMonth === endMonth:
        return `${startMonth} ${startDay} - ${endDay}, ${endYear}`;
      default:
        return `${startMonth} ${startDay}, ${startYear} - ${endMonth} ${endDay}, ${endYear}`;
    }
  }
};

export const getDateMidnight = (date: Date): Date =>
  setHours(setMinutes(setSeconds(setMilliseconds(date, 0), 0), 0), 0);

export const getTodayMidnight = (): Date => getDateMidnight(new Date());

export const getMaxEndDateFromToday = () => {
  const todayMidnight: Date = getTodayMidnight();
  return addDays(todayMidnight, MAX_DAYS_FROM_TODAY);
};

/***
 *
 * Example 1:
 * Today         = Day 1   ----> maxEndDateFromToday     = Day 730
 * TripStartDate = Day 201 ----> maxEndDateFromStartDate = Day 380
 * Then Max End Date should be Day 380
 *
 * Example 2:
 * Today         = Day 1   ----> maxEndDateFromToday     = Day 730
 * TripStartDate = Day 701 ----> maxEndDateFromStartDate = Day 880
 * Then Max End Date should be Day 730
 * In both cases the result is min(maxEndDateFromToday, maxEndDateFromStartDate)
 *
 * */
export const getMaxEndDateAccordingToStartDate = (
  startDate: Nullable<Date>
): Nullable<Date> => {
  if (!!startDate) {
    return new Date(getTime(addDays(startDate, MAX_DAYS_FROM_TRIP_START_DATE)));
  } else {
    return new Date();
  }
};

export const getTimeZonelessDate = (originalDate: Date) => {
  const timeZoneOffsetInMilliSec = originalDate.getTimezoneOffset() * 60 * 1000;
  return new Date(originalDate.getTime() + timeZoneOffsetInMilliSec);
};

export const stringDateToDisplayFormat = (date: Date | null): string => {
  if (!!date) {
    return moment(date).format("MMMM DD, YYYY");
  }
  return "";
};

export const getMaxHawaiiEndDateAccordingToStartDate = (
  startDate: Nullable<Date>
): Nullable<Date> => {
  if (!!startDate) {
    return new Date(
      getTime(addDays(startDate, MAX_DAYS_FROM_TRIP_START_DATE_FOR_HAWAII))
    );
  } else {
    return new Date();
  }
};
