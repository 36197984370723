import React, { useEffect } from "react";
import { GRID_AREA_SECTION_COLLECTION } from "constants/common";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "hooks/redux-hooks";
import { getDashboard } from "components/dashboard/slice/selectors";
import { fetchDashboard } from "components/dashboard/slice/thunks";
import Purchases from "components/dashboard/PurchasesTile";
import Policies from "components/dashboard/PoliciesTile";
import Revenues from "components/dashboard/RevenueTile";
import Average from "components/dashboard/AvarageTile";
import Trips from "components/dashboard/TripsTile";
import Logins from "components/dashboard/LoginsTile";
import PurchasesAndPolicies from "components/dashboard/PurchasesAndPoliciesTile";

interface DashboardPageProps {}

const TEN_MINUTES = 1000 * 60 * 10; //

const Dashboard = styled.div`
  display: grid;
  padding: 1%;
  gap: 15px;
  grid-template-columns: repeat(9, 1fr);

  & .tiles {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.13);
    border-radius: 12px;
    padding: 20px;
  }

  & .purchases {
    background-color: #fff2fb;
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  & .policies {
    grid-column-start: 4;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 2;
    background-color: #f7fcff;
  }

  & .revenues {
    grid-column-start: 7;
    grid-column-end: 10;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  & .trips {
    grid-column-start: 7;
    grid-column-end: 10;
    grid-row-start: 2;
    grid-row-end: 6;
  }

  & .purchasesAndPolicies {
    grid-column-start: 1;
    grid-column-end: 7;
    grid-row-start: 2;
    grid-row-end: 5;
    max-height: 500px;
  }

  & .logins {
    background-color: #e3c7ff;
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 5;
    grid-row-end: 6;
  }

  & .average {
    grid-column-start: 4;
    grid-column-end: 7;
    grid-row-start: 5;
    grid-row-end: 6;
  }

  & .tile-name {
    font-weight: 600;
    font-size: 24px;
    line-height: 27px;
  }

  & .tile-amount {
    font-weight: 600;
    font-size: 28px;
    line-height: 27px;
    color: var(--primary-color);
    padding: 8px 0 8px 0;
    display: block;
  }

  & .tile-description {
    font-weight: 400;
    font-size: 15px;
    line-height: 27px;
  }

  & .inner-container {
    display: flex;
    flex-direction: column;
  }
`;

const DashboardPage: React.FC<DashboardPageProps> = () => {
  const dispatch = useAppDispatch();
  const dashboard = useAppSelector(getDashboard);

  useEffect(() => {
    dispatch(fetchDashboard());
    const interval = setInterval(() => dispatch(fetchDashboard()), TEN_MINUTES);

    return () => {
      clearInterval(interval);
    };
  }, [dispatch]);

  return (
    dashboard &&
    dashboard.dashboard && (
      <Dashboard className={GRID_AREA_SECTION_COLLECTION}>
        <Purchases
          className="purchases tiles"
          purchasesInfo={dashboard.dashboard.purchases}
        ></Purchases>
        <Policies
          className="policies  tiles"
          policiesInfo={dashboard.dashboard.policiesAndRevenues}
        ></Policies>
        <Revenues
          className="revenues  tiles"
          revenueInfo={dashboard.dashboard.policiesAndRevenues}
        ></Revenues>
        <Trips
          className="trips  tiles"
          tripsInfo={dashboard.dashboard.destinations}
        ></Trips>
        <PurchasesAndPolicies
          className="purchasesAndPolicies  tiles"
          purchasesAndPoliciesInfo={dashboard.dashboard.trips}
        ></PurchasesAndPolicies>
        <Logins
          className="logins  tiles"
          loginsInfo={dashboard.dashboard.logins}
        ></Logins>
        <Average
          className="average  tiles"
          averagesInfo={dashboard.dashboard.policiesAndRevenues}
        ></Average>
      </Dashboard>
    )
  );
};

export default React.memo(DashboardPage);
