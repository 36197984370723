const getType = (stateName: string) => {
  if (stateName.includes("email")) {
    return "email";
  }
  return "phone";
};

export const validator = (values: any, fieldName: string) => {
  let errors = {};
  let type = getType(fieldName);
  switch (type) {
    case "email":
      validateEmail(
        fieldName,
        values[fieldName],
        errors,
        fieldName.includes("Emergency")
      );
      break;
    case "phone":
      validatePhoneNumber(
        fieldName,
        values[fieldName],
        errors,
        fieldName.includes("Emergency")
      );
      break;
    default:
  }
  return errors;
};

//******************************
function validatePhoneNumber(
  fieldName: string,
  phone: string,
  errors: any,
  required: boolean
) {
  let result = true;
  if (required && !phone) {
    errors[fieldName] = "This field is required";
    result = false;
  }
  // else if (phone) {
  // const re = /^\+(?:[0-9] ?){3,14}[0-9](ex\d{1,4})?(?<=.)$/;
  //   result = re.test(phone);
  //   if (!result) errors[fieldName] = "Phone number should contain only numbers";
  // }
  return result;
}

function validateEmail(
  fieldName: string,
  email: string,
  errors: any,
  required: boolean
) {
  let result = true;

  if (required && !email) {
    errors[fieldName] = "Email is Required";
    result = false;
  } else if (email) {
    //checks if string is an email.
    // re obtain from http://emailregex.com/
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    result = re.test(String(email).toLowerCase());
    if (!result) errors[fieldName] = "Invalid Email address";
  }
  return result;
}
