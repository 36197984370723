export const capitalize = (word: string) =>
  `${word.charAt(0).toUpperCase()}${word.slice(1).toLowerCase()}`;

export const capitalizeWords = (s: string) =>
  s.replace(/(?:^|\s)\S/g, (a) => a.toUpperCase());

export const copyToClipboard = (str: string) => {
  if (navigator?.clipboard?.writeText) {
    void navigator.clipboard.writeText(str);
  }
};
