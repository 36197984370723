import React, { useCallback, useEffect } from "react";
import styled from "styled-components";
import Table from "../../app/components/Table";
import Box from "@mui/material/Box";
import { getClaimsPage } from "./slice/thunks";
import {
  selectClaims,
  selectClaimSearchText,
  selectNewClaimModalOpen,
  selectPagination
} from "./slice/selectors";
import Button from "@mui/material/Button";
import Filter from "../Filter";
import {
  openNewClaimModal,
  setSelectedClaim,
  updateClaimSearchText
} from "./slice";
import AddClaimModal from "./Modals/AddClaimModal";
import ClaimIdRenderer from "../../app/components/Table/CellRenderers/ClaimIdRenderer";
import CommonCellRenderer from "../../app/components/Table/CellRenderers/CommonCellRenderer";
import { RowSelectedEvent } from "ag-grid-community/dist/lib/events";
import {
  resetSelected,
  setSelectedRowIndex
} from "../collectionTable/collectionTableSlice";
import { GRID_AREA_SECTION_COLLECTION } from "../../constants/common";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import PaginationController from "../PaginationController";

const ADD_CLAIM_BUTTON_CLASS = "add-claim-button";
const CLASS_TABLE_WRAPPER = "table-wrapper";

const TableWrapper = styled(Box)`
  &.${CLASS_TABLE_WRAPPER} {
    height: 100%;
    flex-basis: 87%;
  }
`;

const ClaimsListContainer = styled.div`
  &.${GRID_AREA_SECTION_COLLECTION} {
    padding: 16px;
    background: #f4f4f4;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    & > .filter-container {
      display: flex;
      align-items: center;
      gap: 40px;
      height: 48px;

      & > .${ADD_CLAIM_BUTTON_CLASS} {
        font-weight: 500;
        font-size: 13px;
        flex-basis: 40%;
        text-transform: none;
      }
    }
  }
`;

const ClaimsList = () => {
  const dispatch = useAppDispatch();
  const claims = useAppSelector(selectClaims);
  const pagination = useAppSelector(selectPagination);
  const newClaimModalOpen = useAppSelector(selectNewClaimModalOpen);
  const searchText = useAppSelector(selectClaimSearchText);

  const handleAddClaimClicked = useCallback(() => {
    dispatch(openNewClaimModal());
  }, [dispatch]);

  const handleRowSelected = useCallback(
    (event: RowSelectedEvent) => {
      dispatch(setSelectedClaim(event.data));
      dispatch(setSelectedRowIndex(event.node.rowIndex));
    },
    [dispatch]
  );

  useEffect(() => {
    if (!claims.length) {
      dispatch(getClaimsPage(0));
    }
    dispatch(resetSelected());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ClaimsListContainer className={GRID_AREA_SECTION_COLLECTION}>
      <Box className="filter-container">
        <Button
          className={ADD_CLAIM_BUTTON_CLASS}
          variant="contained"
          onClick={handleAddClaimClicked}
        >
          + Add new claim
        </Button>
        <Filter
          updateFilterFunction={updateClaimSearchText}
          fetchResultsFunction={getClaimsPage}
          searchText={searchText}
          label="Search by name, #Claim, Type etc."
        />
      </Box>
      <TableWrapper className={`${CLASS_TABLE_WRAPPER} ag-theme-alpine`}>
        <Table
          rowData={claims}
          cellRenderers={{
            claimIdRenderer: ClaimIdRenderer,
            commonCellRenderer: CommonCellRenderer
          }}
          columnDef={[
            { name: "claim", componentName: "claimIdRenderer" },
            { name: "dateCreated", componentName: "commonCellRenderer" },
            { name: "name", componentName: "commonCellRenderer" },
            { name: "claimStatus", componentName: "commonCellRenderer" }
          ]}
          onRowSelected={handleRowSelected}
        />
        <PaginationController
          fetchFunction={getClaimsPage}
          pagination={pagination}
        />
      </TableWrapper>
      {newClaimModalOpen && <AddClaimModal isOpen={newClaimModalOpen} />}
    </ClaimsListContainer>
  );
};

export default React.memo(ClaimsList);
