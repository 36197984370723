import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import "./index.css";
import App from "./app/App";
import { store } from "./store";
import setupInterceptors from "./utils/ApiWrapper/setupInterceptors";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router } from "react-router-dom";
import { LicenseInfo } from "@mui/x-license-pro";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_PRO_KEY!);

Sentry.init({
  dsn: "https://7cbbf4c85c2e42b0980d5cc3519b25a4@o954259.ingest.sentry.io/6125250",
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.REACT_APP_BITBUCKET_BRANCH,
  tracesSampleRate: 1.0,
  release: `empanda@${process.env.REACT_APP_VERSION}`
});

setupInterceptors(store).then(() =>
  ReactDOM.render(
    <React.StrictMode>
      <Router>
        <Provider store={store}>
          <App />
        </Provider>
      </Router>
    </React.StrictMode>,
    document.getElementById("root")
  )
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
