import { createSlice } from "@reduxjs/toolkit";
import { Nullable } from "../../types/general";

interface ICollectionTable {
  selectedRowIndex: Nullable<number>;
  selectedRowData: Nullable<any>;
}

const initialState = {
  selectedRowIndex: -1,
  selectedRowData: null
};

const collectionTableSlice = createSlice({
  name: "collectionTable",
  initialState,
  reducers: {
    setSelectedRowIndex: (
      state: ICollectionTable,
      { payload }: { payload: Nullable<number> }
    ) => {
      state.selectedRowIndex = payload;
    },
    resetSelected: () => {
      return initialState;
    }
  }
});

export const { setSelectedRowIndex, resetSelected } =
  collectionTableSlice.actions;

export default collectionTableSlice.reducer;
