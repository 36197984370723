import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styled from "styled-components";
import { format } from "date-fns";
import { IUserPolicyInfoDto } from "../../../../../../types/DTO/IUsersInfoDto";
import { useAppDispatch } from "../../../../../../hooks/redux-hooks";
import { useHistory } from "react-router-dom";
import {
  setSelectedClaim,
  updateClaimSearchText
} from "../../../../../claims/slice";
import { getClaimsPage } from "../../../../../claims/slice/thunks";
import { IClaimDTO } from "../../../../../../types/DTO/IClaimDTO";
import { IClaim } from "../../../../../../types/general";
import { CLAIMS_URL } from "../../../../../../Routes/constants";
import { resetSelectedPolicy, setDisplayPolicy } from "../../../../slice";

type Claim = {
  id: number;
  created: string;
  status: string;
  claimIdentifier: string;
  intent: string;
  user_id: number;
  active: boolean;
  isPayed: boolean;
  isApprovedToPay: boolean;
  subject: string;
  user_policy_id: number;
  claimItems: any;
};

interface SingleClaimProps {
  claim: Claim;
  selectedCustomer?: IUserPolicyInfoDto;
}

const SingleClaimContainer = styled(Box)`
  &.single-claim-container {
    font-size: 12px;

    & > .single-claim-accordion {
      & > .MuiCollapse-root {
        & > .MuiCollapse-wrapper {
          & > .MuiCollapse-wrapperInner {
            & > .MuiAccordion-region {
              & > .single-claim-accordion-details {
                display: flex;
                flex-direction: column;
                gap: 8px;

                & > .claim-items-container {
                  display: flex;
                  flex-direction: column;
                  gap: 4px;

                  & > h4 {
                    font-size: 12px;
                    font-weight: bold;
                  }

                  & > .claim-items-headers {
                    display: flex;

                    & > * {
                      font-size: 12px;
                      font-weight: bold;
                      flex: 1;
                    }
                  }

                  & > .single-claim-item {
                    display: flex;
                    align-items: center;

                    & > * {
                      font-size: 12px;
                      flex: 1;
                      padding: 0;

                      & > svg {
                        font-size: 14px;
                      }
                    }

                    & > button {
                      justify-content: flex-start;
                    }
                  }
                }
              }
            }
          }
        }
      }

      & > .single-claim-accordion-summary {
        & > .MuiAccordionSummary-content {
          display: flex;
          align-items: center;
          gap: 4px;

          & > * {
            flex: 1;
          }
        }
      }
    }
  }
  & .claim-identifier {
    cursor: pointer;
    color: blue;
  }
  & .claim-identifier:hover {
    font-weight: bolder;
  }
`;

const SingleClaim: React.FC<SingleClaimProps> = ({
  claim,
  selectedCustomer
}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [amounts, setAmounts] = useState({
    requested: 0,
    payed: 0
  });

  const convertToIClaim = (claim: IClaimDTO): IClaim => {
    return {
      claim: {
        id: claim.claimId,
        identifier: claim.claimIdentifier,
        subject: claim.subject
      },
      dateCreated: claim.claimCreated,
      name: claim.name,
      policyCode: claim.policyCode,
      searchString: claim.searchString,
      claimStatus: claim.claim_status,
      subject: claim.subject,
      policyId: claim.policy_id,
      userId: claim.user_id,
      intent: claim.intent
    };
  };

  const openClaim = async (event: { stopPropagation: () => void }) => {
    event.stopPropagation();
    dispatch(updateClaimSearchText(claim.claimIdentifier));
    const searchResults = (await dispatch(getClaimsPage(0)).unwrap())?.claims;
    if (searchResults) {
      dispatch(
        setSelectedClaim({ ...convertToIClaim(searchResults[0]), rowIndex: 0 })
      );
      dispatch(setDisplayPolicy(false));
      dispatch(resetSelectedPolicy());
      history.push(CLAIMS_URL);
    }
  };

  useEffect(() => {
    const calcAmount = (amountType: string) => {
      let amount = 0;
      claim.claimItems.map((claimItem: any) => {
        return amountType === "requested"
          ? (amount += claimItem.requestedAmount)
          : (amount += claimItem.payedAmount);
      });
      return amount;
    };
    setAmounts({
      requested: calcAmount("requested"),
      payed: calcAmount("payed")
    });
  }, [selectedCustomer, claim]);

  return (
    <SingleClaimContainer className="single-claim-container">
      <Accordion expanded={accordionOpen} className="single-claim-accordion">
        <AccordionSummary
          onClick={() => setAccordionOpen(!accordionOpen)}
          expandIcon={<ExpandMoreIcon />}
          className="single-claim-accordion-summary"
        >
          <Box onClick={openClaim} className="claim-identifier">
            {claim.claimIdentifier}
          </Box>
          <Box className="claim-subject">{claim.subject}</Box>
          <Box className="claim-status">{claim.status}</Box>
          <Box className="claim-amount-requested">{amounts.requested}</Box>
          <Box className="claim-amount-requested">{amounts.payed}</Box>
        </AccordionSummary>
        <AccordionDetails className="single-claim-accordion-details">
          <Box className="claim-items-container">
            <Typography variant="h4">Claim items</Typography>
            {claim.claimItems && claim.claimItems.length > 0 ? (
              <Box className="claim-items-headers">
                <Typography className="claim-items-header">Type</Typography>
                <Typography className="claim-items-header">
                  Requested
                </Typography>
                <Typography className="claim-items-header">Paid</Typography>
                <Typography className="claim-items-header">Updated</Typography>
              </Box>
            ) : (
              <Typography variant="h4">No claim items to show</Typography>
            )}
            {claim.claimItems &&
              claim.claimItems.length > 0 &&
              claim.claimItems.map((item: any) => {
                return (
                  <Box key={item.id} className="single-claim-item">
                    <Box className="claim-item-type">{item.claimType}</Box>
                    <Box className="claim-item-amount-requested">
                      {item.requestedAmount ?? 0}
                    </Box>
                    <Box className="claim-item-amount-payed">
                      {item.payedAmount ?? 0}
                    </Box>
                    <Box className="claim-item-amount-payed">
                      {format(new Date(item.updated), "MM/dd/yyyy")}
                    </Box>
                  </Box>
                );
              })}
          </Box>
        </AccordionDetails>
      </Accordion>
    </SingleClaimContainer>
  );
};

export default SingleClaim;
