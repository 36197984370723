import { configureStore } from "@reduxjs/toolkit";
import authenticationReducer from "components/authentication/slice";
import claimsReducer from "components/claims/slice";
import collectionTableReducer from "components/collectionTable/collectionTableSlice";
import policiesReducer from "components/policies/slice";
import usersReducer from "components/advisors/slice";
import DashboardReducer from "components/dashboard/slice";
import WidgetsSliceReducer from "components/widgets/slice";
import modalSliceReducer from "components/modals/slice";

export const store = configureStore({
  reducer: {
    authentication: authenticationReducer,
    claims: claimsReducer,
    collectionTable: collectionTableReducer,
    policies: policiesReducer,
    users: usersReducer,
    dashboard: DashboardReducer,
    widgets: WidgetsSliceReducer,
    modals: modalSliceReducer
  }
});
