import React from "react";
import styled from "styled-components";
import FormControl from "@mui/material/FormControl";
import { SECTION_USER_DETAILS } from "../../constants/common";
import { Typography } from "@mui/material";
import { IUserDto } from "../../types/DTO/IUserDto";

const CLASS_DETAILS_BIG = "detail-big";
const CLASS_DETAILS_REGULAR = "detail-regular";

interface UserDetailsProps {
  selectedUserDetails: IUserDto;
}

const UserDetailsContainer = styled(FormControl)`
  &.${SECTION_USER_DETAILS} {
    display: flex;
    flex-direction: column;
    gap: 8px;
    & .${CLASS_DETAILS_BIG} {
      font-weight: 600;
      font-size: 16px;
      line-height: 18px;
    }
    & .${CLASS_DETAILS_REGULAR} {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
    }
  }
`;

const UserDetails: React.FC<UserDetailsProps> = ({ selectedUserDetails }) => {
  return (
    <UserDetailsContainer className={SECTION_USER_DETAILS}>
      <Typography component="h3" className={CLASS_DETAILS_BIG}>
        {selectedUserDetails.nickName}
      </Typography>
      <Typography component="label" className={CLASS_DETAILS_REGULAR}>
        {selectedUserDetails.email}
      </Typography>

      {selectedUserDetails.mobile && (
        <Typography component="label" className={CLASS_DETAILS_REGULAR}>
          {selectedUserDetails.mobile}
        </Typography>
      )}
      {selectedUserDetails.advisorId && (
        <Typography component="label" className={CLASS_DETAILS_REGULAR}>
          {selectedUserDetails.advisorId}
        </Typography>
      )}
    </UserDetailsContainer>
  );
};

export default UserDetails;
