import { createSlice } from "@reduxjs/toolkit";
import {
  createClaim,
  fetchSelectedClaimPolicy,
  fetchSelectedClaimUserProfile,
  fetchClaimChat,
  fetchClaimComments,
  connectClaimToPolicy,
  getClaimsPage,
  fetchClaimPayments,
  fetchClaimHistory,
  fetchPolicyPDF,
  fetchClaimDocuments,
  openDocument,
  fetchSelectedClaimQuote
} from "./thunks";
import { mapClaimDtoToAppClaim } from "utils/claims";
import { IClaim, IPageMeta, Nullable, StatusType } from "types/general";
import IQuoteDTO from "types/DTO/IQuoteDTO";
import { IUserDto } from "types/DTO/IUserDto";
import { IUserPolicyInfoDto } from "types/DTO/IUsersInfoDto";

const PAGINATION_INITIAL_VALUE = {
  currentPage: 0,
  itemCount: 0,
  itemsPerPage: 0,
  totalItems: 0,
  totalPages: 0
};

export type ClaimRow = IClaim & { rowIndex: number };

interface IClaimsState {
  claims: IClaim[];
  claimsFetchingInProcessAmount: number;
  pagination?: IPageMeta;
  claimSearchText: string;
  claimCommentText: string;
  createClaimModal: {
    isOpen: boolean;
  };
  connectPolicyModal: {
    isOpen: boolean;
    inProcess: boolean;
    completed?: boolean;
    error?: any;
  };
  selectedClaim: Nullable<ClaimRow>;
  selectedClaimUser?: Nullable<IUserDto>;
  selectedClaimQuote?: Nullable<IQuoteDTO>;
  selectedClaimPolicy?: Nullable<IUserPolicyInfoDto>;
  selectedClaimPolicyPDF?: any;
  selectedClaimChat?: any;
  selectedClaimComments?: any;
  selectedClaimPayments?: any;
  selectedClaimHistory?: any;
  selectedClaimDocuments?: any;
  currentDocument?: any;
  tabIsLoading: boolean;
}

const initialState: IClaimsState = {
  claims: [],
  claimsFetchingInProcessAmount: 0,
  createClaimModal: {
    isOpen: false
  },
  connectPolicyModal: { isOpen: false, inProcess: false },
  pagination: PAGINATION_INITIAL_VALUE,
  claimSearchText: "",
  selectedClaim: null,
  selectedClaimChat: null,
  selectedClaimComments: null,
  selectedClaimPayments: null,
  selectedClaimHistory: null,
  selectedClaimPolicyPDF: null,
  selectedClaimDocuments: null,
  currentDocument: null,
  claimCommentText: "",
  tabIsLoading: true
};

const claimsSlice = createSlice({
  name: "claims",
  initialState,
  reducers: {
    openNewClaimModal: (state: IClaimsState) => {
      state.createClaimModal = { isOpen: true };
    },
    abortNewClaimModal: (state: IClaimsState) => {
      state.createClaimModal = { isOpen: false };
    },
    openConnectPolicyModal: (state: IClaimsState) => {
      state.connectPolicyModal.isOpen = true;
    },
    abortConnectPolicyModal: (state: IClaimsState) => {
      state.connectPolicyModal = { isOpen: false, inProcess: false };
    },
    updateClaimCommentText: (
      state: IClaimsState,
      { payload }: { payload: string }
    ) => {
      state.claimCommentText = payload;
    },
    updateClaimSearchText: (
      state: IClaimsState,
      { payload }: { payload: string }
    ) => {
      state.claimSearchText = payload;
    },
    setSelectedClaim: (
      state: IClaimsState,
      { payload }: { payload: ClaimRow }
    ) => {
      state.selectedClaim = payload;
      state.selectedClaimUser = null;
      state.selectedClaimPolicy = null;
    },
    updateSelectedClaimStatus: (
      state: IClaimsState,
      { payload }: { payload: StatusType }
    ) => {
      state.selectedClaim = { ...state.selectedClaim!, claimStatus: payload };
    },
    resetSelectedClaim: (state: IClaimsState) => {
      state.selectedClaim = null;
      state.selectedClaimUser = null;
      state.selectedClaimPolicy = null;
      state.selectedClaimQuote = null;
    },

    setSelectedClaimPolicyId: (
      state: IClaimsState,
      { payload }: { payload: number }
    ) => {
      state.selectedClaim!.policyId = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      getClaimsPage.fulfilled,
      (state: IClaimsState, { payload }) => {
        if (payload !== undefined) {
          state.claims = payload.claims.map(mapClaimDtoToAppClaim);
          state.pagination = payload.pageMeta;
        }
        state.claimsFetchingInProcessAmount =
          state.claimsFetchingInProcessAmount - 1;
      }
    );
    builder.addCase(getClaimsPage.pending, (state: IClaimsState) => {
      state.claimsFetchingInProcessAmount =
        state.claimsFetchingInProcessAmount + 1;
    });
    builder.addCase(createClaim.fulfilled, (state: IClaimsState) => {
      state.createClaimModal.isOpen = false;
    });
    builder.addCase(createClaim.rejected, (state: IClaimsState) => {
      state.createClaimModal.isOpen = false;
    });
    builder.addCase(
      fetchSelectedClaimPolicy.fulfilled,
      (state: IClaimsState, { payload }) => {
        state.selectedClaimPolicy = payload;
      }
    );
    builder.addCase(
      fetchSelectedClaimQuote.fulfilled,
      (state: IClaimsState, { payload }) => {
        state.selectedClaimQuote = payload;
      }
    );
    builder.addCase(
      fetchSelectedClaimUserProfile.fulfilled,
      (state: IClaimsState, { payload }) => {
        state.selectedClaimUser = payload;
      }
    );

    builder.addCase(
      fetchClaimComments.fulfilled,
      (state: IClaimsState, { payload }) => {
        state.selectedClaimComments = payload;
        state.tabIsLoading = false;
      }
    );
    builder.addCase(fetchClaimComments.pending, (state: IClaimsState) => {
      state.tabIsLoading = true;
    });

    builder.addCase(
      fetchClaimHistory.fulfilled,
      (state: IClaimsState, { payload }) => {
        state.selectedClaimHistory = payload;
      }
    );

    builder.addCase(
      fetchClaimPayments.fulfilled,
      (state: IClaimsState, { payload }) => {
        state.selectedClaimPayments = payload;
        state.tabIsLoading = false;
      }
    );

    builder.addCase(fetchClaimPayments.pending, (state: IClaimsState) => {
      state.tabIsLoading = true;
    });

    builder.addCase(
      fetchClaimChat.fulfilled,
      (state: IClaimsState, { payload }) => {
        state.selectedClaimChat = payload;
        state.tabIsLoading = false;
      }
    );
    builder.addCase(fetchClaimChat.pending, (state: IClaimsState) => {
      state.tabIsLoading = true;
    });
    builder.addCase(
      fetchPolicyPDF.fulfilled,
      (state: IClaimsState, { payload }) => {
        state.selectedClaimPolicyPDF = payload;
      }
    );
    builder.addCase(
      fetchClaimDocuments.fulfilled,
      (state: IClaimsState, { payload }) => {
        state.selectedClaimDocuments = payload;
        state.tabIsLoading = false;
      }
    );
    builder.addCase(fetchClaimDocuments.pending, (state: IClaimsState) => {
      state.tabIsLoading = true;
    });
    builder.addCase(fetchClaimChat.rejected, (state: IClaimsState) => {
      state.selectedClaimChat = null;
    });
    builder.addCase(
      openDocument.fulfilled,
      (state: IClaimsState, { payload }) => {
        state.currentDocument = payload;
      }
    );

    builder.addCase(connectClaimToPolicy.pending, (state: IClaimsState) => {
      state.connectPolicyModal.inProcess = true;
    });
    builder.addCase(connectClaimToPolicy.fulfilled, (state: IClaimsState) => {
      state.connectPolicyModal.completed = true;
      state.connectPolicyModal.inProcess = false;
    });
  }
});

export const {
  openNewClaimModal,
  abortNewClaimModal,
  updateClaimSearchText,
  setSelectedClaim,
  openConnectPolicyModal,
  abortConnectPolicyModal,
  setSelectedClaimPolicyId,
  resetSelectedClaim,
  updateClaimCommentText,
  updateSelectedClaimStatus
} = claimsSlice.actions;

export default claimsSlice.reducer;
