export const GRID_AREA_HEADER = "header";
export const GRID_AREA_SIDENAV = "side-nav";
export const GRID_AREA_SECTION_COLLECTION = "section-collection";
export const GRID_AREA_DYNAMIC_CONTAINER = "section-dynamic-container";
export const GRID_AREA_SECTION_VIEW = "section-view";
export const PAGINATION_CONTROLLER_CLASS = "pagination-controller";
export const PAGINATION_CONTROLLER_SECTION = PAGINATION_CONTROLLER_CLASS;
export const CLASS_COLLECTION_FILTER_ELEMENT = "collection-filter-element";
export const CLASS_SECTION_CARD = "section-card";
export const CLASS_SECTION_PREVIEW = "section-preview";
export const SECTION_USER_DETAILS = "card-user-details";
export const MIN_WIDTH = 1920;
export const SECTION_VIEW_INITIAL_WIDTH = 680;

export const REGEX_EMAIL =
  // eslint-disable-next-line
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
