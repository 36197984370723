import React, { useEffect } from "react";
import classnames from "classnames";
import styled from "styled-components";
import TabPanel from "../../../../TabPanel";
import MessageBox from "./MessageBox";
import { IClaim } from "../../../../../types/general";
import {
  useAppDispatch,
  useAppSelector
} from "../../../../../hooks/redux-hooks";
import { fetchClaimChat } from "../../../slice/thunks";
import {
  selectSelectedClaimChat,
  tabIsLoading
} from "../../../slice/selectors";
import { Box } from "@mui/system";
import SpinnerLoader from "../../../../ui/loaders/SpinnerLoader/SpinnerLoader";

const ChatTabContainer = styled(TabPanel)`
  &.chat-tab {
    overflow: auto;
    padding: 10px;

    & > .message-list {
      display: flex;
      flex-direction: column-reverse;
      gap: 5px;
    }
  }

  & .place-holder {
    display: none;
  }
`;

interface ChatTabProps {
  selectedClaim: IClaim;
}

const ChatTab: React.FC<ChatTabProps> = ({ selectedClaim }) => {
  const dispatch = useAppDispatch();
  const claimChat = useAppSelector(selectSelectedClaimChat);
  const isLoading = useAppSelector(tabIsLoading);

  const content = isLoading ? (
    <SpinnerLoader isLoading={true} />
  ) : (
    <Box className="message-list">
      {claimChat &&
        claimChat.map((chat: any, index: number) => {
          if (chat?.message) {
            return <MessageBox key={chat.id} chat={chat} />;
          } else {
            return <span key={index} className="place-holder"></span>;
          }
        })}
    </Box>
  );
  useEffect(() => {
    dispatch(
      fetchClaimChat({
        userId: selectedClaim.userId,
        claimIdentifier: selectedClaim.claim.identifier
      })
    );
  }, [selectedClaim, dispatch]);

  return (
    <ChatTabContainer
      currTabIndex={2}
      tabIndex={2}
      isHidden={Boolean(!selectedClaim)}
      className={classnames("chat-tab", "tab-container")}
    >
      {content}
    </ChatTabContainer>
  );
};

export default ChatTab;
