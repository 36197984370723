import * as React from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux-hooks";
import {
  editCustomerModal,
  selectPoliciesPagination,
  selectSelectedPolicy
} from "../slice/selectors";
import LoadingButton from "@mui/lab/LoadingButton";
import { useEffect, useState } from "react";
import { closeEditCustomerModal } from "../slice";
import useFormInput from "hooks/useFormInput";
import { validateEmail, validateName, validateNumbers } from "utils/validation";
import styled from "styled-components";
import InputAdornment from "@mui/material/InputAdornment";
import EmailIcon from "@mui/icons-material/EmailOutlined";
import { ReactComponent as Phone } from "assets/svg/Phone.svg";
import { ReactComponent as Cake } from "assets/svg/Birthday.svg";
import { ReactComponent as AddressPin } from "assets/svg/AddressPin.svg";
import { fetchPolicyPage, updateUser } from "../slice/thunks";
import useDateInput from "../../../hooks/useDateInput";
import Button from "@mui/material/Button";
import { capitalize } from "utils/general";

const EditCustomerDetailsContainer = styled(Dialog)`
  & .MuiPaper-root {
    height: 540px;
    width: 510px;
    padding-bottom: 20px;
  }

  & .content-container {
    padding-top: 10px;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas:
      "fname fname lname lname"
      "email email email email"
      "dob dob dob dob"
      "address address address address"
      "mobile mobile mobile mobile";

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    & .address-field {
      grid-area: address;
    }

    & .mobile-field {
      grid-area: mobile;
    }

    & .email-field {
      grid-area: email;
    }

    & .dob-field {
      grid-area: dob;
      display: grid;
      grid-template-columns: 4fr repeat(3, 1fr);

      & .dob-input {
        .Mui-disabled {
          -webkit-text-fill-color: unset;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }

      & .MuiFormControl-root {
        & .MuiOutlinedInput-root {
          border-radius: 0 0 0 0;
        }

        :last-child {
          & .MuiOutlinedInput-root {
            border-radius: 0 4px 4px 0;
          }
        }

        :first-child {
          & .MuiOutlinedInput-root {
            border-radius: 4px 0 0 4px;
            border-right: none;
          }
        }
      }
    }

    & .first-name-field {
      grid-area: fname;
    }

    & .last-name-field {
      grid-area: lname;
    }
  }

  & .button-container {
    justify-content: center;
  }

  & .error-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 0 0 5px 25px;
  }

  & .error {
    color: var(--text-warning);
  }
`;

const EditCustomerDetails = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { customer, error } = useAppSelector(editCustomerModal);
  const customerPolicy = useAppSelector(selectSelectedPolicy);
  const policiesPagination = useAppSelector(selectPoliciesPagination);
  const emailValidation = !!customerPolicy?.user.id
    ? validateEmail
    : (value: string) => {
      return value === "" || validateEmail(value);
    };
  const initialFirstName = customer ? `${customer.firstName}` : "";
  const initialLastName = customer ? `${customer.lastName}` : "";
  const initialEmail = customer ? `${customer.email}` : "";
  const initialMobile = customer ? `${customer.phone}` : "";

  const dispatch = useAppDispatch();

  const {
    value: firstName,
    wasChanged: firstNameChanged,
    changeValueHandler: changeFirstName,
    inputBlurHandler: firstNameBlurHandler,
    hasError: firstNameError
  } = useFormInput(initialFirstName, validateName, capitalize);

  const {
    value: lastName,
    wasChanged: lastNameChanged,
    changeValueHandler: changeLastName,
    inputBlurHandler: lastNameBlurHandler,
    hasError: lastNameError
  } = useFormInput(initialLastName, validateName, capitalize);

  //to be developed
  // const {
  //   value: address,
  //   changeValueHandler: changeAddress,
  //   inputBlurHandler: addressBlurHandler,
  //   hasError: addressError
  // } = useFormInput(initialHomeAddress, (value: string) => value !== "");
  //to be developed

  const { street, city, country, state, zip } = customer!.address;
  const addressForDisplay =
    street && city && state && country && zip
      ? `${street}, ${city}, ${state}, ${country}, ${zip}`
      : "";
  const {
    day,
    month,
    year,
    wasChanged: dateWasChanged,
    dayError,
    monthError,
    yearError,
    dayChangeHandler,
    monthChangeHandler,
    yearChangeHandler,
    dayBlurHandler,
    monthBlurHandler,
    yearBlurHandler
  } = useDateInput(new Date(customer!.dateOfBirth));
  const {
    value: mobile,
    wasChanged: mobileChanged,
    changeValueHandler: changeMobile,
    inputBlurHandler: mobileBlurHandler,
    hasError: mobileError
  } = useFormInput(initialMobile, validateNumbers);
  const {
    value: email,
    wasChanged: emailChanged,
    changeValueHandler: changeEmail,
    inputBlurHandler: emailBlurHandler,
    isValid: emailIsValid,
    hasError: emailError
  } = useFormInput(initialEmail, emailValidation);
  const [valuesChanged, setValuesChanged] = useState(false);

  const updateCustomer = async () => {
    setIsLoading(true);

    const res = await dispatch(
      updateUser({
        userId: !!customerPolicy?.user.id ? customerPolicy.user.id : null,
        policyId: customerPolicy!.id as number,
        firstName,
        lastName,
        email,
        address:
          !!addressForDisplay.length && !!customer?.address
            ? customer.address
            : null,
        dateOfBirth: `${year}-${month}-${day}`,
        mobile
      })
    );

    if (!!res.payload) {
      setIsLoading(false);
      if (policiesPagination) {
        dispatch(fetchPolicyPage(policiesPagination.currentPage));
      }
      closeHandler();
    }
  };

  const closeHandler = () => {
    dispatch(closeEditCustomerModal());
  };

  useEffect(() => {
    if (
      firstNameChanged ||
      lastNameChanged ||
      emailChanged ||
      dateWasChanged ||
      mobileChanged
    ) {
      setValuesChanged(true);
    }
  }, [
    firstNameChanged,
    lastNameChanged,
    emailChanged,
    dateWasChanged,
    mobileChanged
  ]);

  const button = !error.length ? (
    <LoadingButton
      disabled={
        !valuesChanged ||
        isLoading ||
        firstNameError ||
        lastNameError ||
        emailError ||
        !!monthError.length ||
        !!dayError.length ||
        !!yearError.length
      }
      loading={isLoading}
      onClick={updateCustomer}
      variant="contained"
    >
      Save changes
    </LoadingButton>
  ) : (
    <Button variant="contained" onClick={closeHandler}>
      Close
    </Button>
  );

  return (
    <EditCustomerDetailsContainer
      open={true}
      onClose={closeHandler}
      className="editCustomerDetailsContainer"
    >
      <DialogTitle>Edit customer information</DialogTitle>
      <DialogContent className="content-container">
        <TextField
          label="First name"
          variant="outlined"
          size="small"
          className="first-name-field"
          value={firstName}
          onChange={changeFirstName}
          error={firstNameError}
          onBlur={firstNameBlurHandler}
          disabled={isLoading}
          helperText={firstNameError ? "*Invalid first name" : ""}
        ></TextField>
        <TextField
          label="Last name"
          variant="outlined"
          size="small"
          className="last-name-field"
          value={lastName}
          onChange={changeLastName}
          onBlur={lastNameBlurHandler}
          error={lastNameError}
          disabled={isLoading}
          helperText={lastNameError ? "*Invalid last name" : ""}
        ></TextField>
        <TextField
          label="Home address"
          variant="outlined"
          size="small"
          className="address-field"
          value={addressForDisplay}
          disabled
          helperText="*to be developed"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AddressPin fontSize="small" color="primary" />
              </InputAdornment>
            )
          }}
        ></TextField>
        <TextField
          label="Email address"
          variant="outlined"
          type="email"
          size="small"
          className="email-field"
          value={email}
          onChange={changeEmail}
          error={emailError}
          onBlur={emailBlurHandler}
          disabled={isLoading}
          helperText={emailError ? "*Invalid email address" : ""}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailIcon fontSize="small" color="primary" />
              </InputAdornment>
            )
          }}
        ></TextField>
        <div className="dob-field">
          <TextField
            className="dob-input"
            placeholder="Date of birth"
            variant="outlined"
            size="small"
            helperText={dayError + monthError + yearError}
            disabled
            error={
              !!dayError.length || !!monthError.length || !!yearError.length
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Cake fontSize="small" color="primary" />
                </InputAdornment>
              )
            }}
          ></TextField>{" "}
          <TextField
            label="MM"
            variant="outlined"
            size="small"
            value={month}
            type="number"
            className="dob-input"
            onChange={monthChangeHandler}
            onBlur={monthBlurHandler}
            error={!!monthError}
            inputProps={{
              min: 1,
              max: 12
            }}
            disabled={isLoading}
          ></TextField>{" "}
          <TextField
            className="dob-input"
            label="DD"
            variant="outlined"
            size="small"
            type="number"
            value={day}
            inputProps={{
              min: 1,
              max: 31
            }}
            onChange={dayChangeHandler}
            onBlur={dayBlurHandler}
            error={!!dayError}
            disabled={isLoading}
          ></TextField>
          <TextField
            className="dob-input"
            label="YYYY"
            inputProps={{
              min: 1920,
              max: new Date().getFullYear()
            }}
            type="number"
            variant="outlined"
            size="small"
            value={year}
            onBlur={yearBlurHandler}
            onChange={yearChangeHandler}
            error={!!yearError.length}
            disabled={isLoading}
          ></TextField>{" "}
        </div>

        <TextField
          label="Phone number"
          variant="outlined"
          size="small"
          className="mobile-field"
          type="text"
          value={mobile}
          onChange={changeMobile}
          error={mobileError}
          onBlur={mobileBlurHandler}
          disabled={isLoading || !emailIsValid}
          helperText={mobileError ? "*Invalid phone number" : ""}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Phone fontSize="small" color="primary" />
              </InputAdornment>
            )
          }}
        ></TextField>
      </DialogContent>
      {!!error.length && (
        <div className="error-container">
          <span>Unable to update customer details:</span>
          <span className="error">Error: {error}</span>
        </div>
      )}
      <DialogActions className="button-container">{button}</DialogActions>
    </EditCustomerDetailsContainer>
  );
};

export default EditCustomerDetails;
