import React, { useCallback } from "react";
import Modal from "@mui/material/Modal";
import { abortNewUserModal } from "../../slice";
import styled from "styled-components";
import { Button, TextField } from "@mui/material";
import useInput from "../../../../hooks/useInput";
import Container from "@mui/material/Container";
import { createUser } from "../../slice/thunks";
import { useAppDispatch } from "../../../../hooks/redux-hooks";

const MODAL_CONTENT_CLASS = "modal-content";

interface AddUserModalProps {
  isOpen: boolean;
}

const AddUserModalContainer = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;

  & .${MODAL_CONTENT_CLASS} {
    padding: 20px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
    border-radius: 8px;
  }
`;

const AddUserModal: React.FC<AddUserModalProps> = ({ isOpen }) => {
  const dispatch = useAppDispatch();
  const { value: firstName, onChange: onChangeFirstName } = useInput("");

  const { value: lastName, onChange: onChangeLastName } = useInput("");

  const { value: email, onChange: onChangeEmail } = useInput("");

  const { value: mobile, onChange: onChangeMobile } = useInput("");

  const { value: advisorId, onChange: onChangeAdvisorId } = useInput("");

  const handleCloseAddUserModal = useCallback(() => {
    dispatch(abortNewUserModal());
  }, [dispatch]);

  const handleCreateUserClick = useCallback(() => {
    dispatch(
      createUser({
        firstName: firstName as string,
        lastName: lastName as string,
        email: email as string,
        advisorId: advisorId as string,
        mobile: mobile as string,
        role: "advisor"
      })
    );
  }, [dispatch, firstName, lastName, email, advisorId, mobile]);

  return (
    <AddUserModalContainer open={isOpen} onClose={handleCloseAddUserModal}>
      <Container className={MODAL_CONTENT_CLASS}>
        <TextField
          value={firstName}
          onChange={onChangeFirstName}
          label="First Name"
          fullWidth
        />
        <br />
        <TextField
          value={lastName}
          onChange={onChangeLastName}
          label="Last Name"
          fullWidth
        />
        <br />
        <TextField
          value={email}
          onChange={onChangeEmail}
          label="Email"
          fullWidth
        />
        <br />
        <TextField
          value={advisorId}
          onChange={onChangeAdvisorId}
          label="Advisor Id"
          fullWidth
        />
        <br />
        <TextField
          value={mobile}
          onChange={onChangeMobile}
          label="Mobile"
          fullWidth
        />
        <br />
        <Button
          variant="contained"
          disabled={!(firstName && lastName && email && advisorId)}
          onClick={handleCreateUserClick}
        >
          Create a User
        </Button>
      </Container>
    </AddUserModalContainer>
  );
};

export default AddUserModal;
