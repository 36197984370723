import React from "react";
import styled from "styled-components";
import Box from "@mui/material/Box";
import SingleTrip from "./single-trip";
import { Typography } from "@mui/material";

interface TripListProps {
  policies: any;
  selectedPolicy: any;
}

const TripListContainer = styled(Box)`
  &.trip-list-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    & > h4 {
      font-size: 12px;
      font-weight: bold;
    }
    & > .trip-list-headers {
      display: flex;
      align-items: center;
      padding: 0 16px;
      width: calc(100% - 1.2em);
      & > * {
        font-size: 12px;
        font-weight: bold;
        flex: 1;
        align-self: flex-start;
      }
    }
  }
`;

const TripList: React.FC<TripListProps> = ({ policies, selectedPolicy }) => {
  if (!policies || policies.length <= 0)
    return (
      <TripListContainer className="trip-list-container">
        <Typography variant="h4">No connected trips</Typography>
      </TripListContainer>
    );

  return (
    <TripListContainer className="trip-list-container">
      {policies.map((policy: any) => {
        return (
          <SingleTrip
            selectedPolicy={selectedPolicy}
            key={policy.id}
            policy={policy}
          />
        );
      })}
    </TripListContainer>
  );
};

export default TripList;
