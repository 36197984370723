import { createSlice } from "@reduxjs/toolkit";
import {
  createOrEditEmergencyContact,
  fetchEmergencyContact,
  fetchWidgetsPerLocationPage
} from "./thunks";
import { Nullable } from "../../../types/general";
import { IContact } from "../../../types/DTO/IContactDTO";

interface IWidgetsSlice {
  isLoading: boolean;
  widgets: Nullable<any>;
  country: string;
}

const initialState: IWidgetsSlice = {
  isLoading: false,
  widgets: null,
  country: ""
};

const WidgetsSlice = createSlice({
  name: "WidgetsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchWidgetsPerLocationPage.fulfilled,
      (state: IWidgetsSlice, { payload }) => {
        state.widgets = payload.widgets;
        state.country = payload.country;
        state.isLoading = false;
      }
    );
    builder.addCase(
      fetchWidgetsPerLocationPage.pending,
      (state: IWidgetsSlice) => {
        state.widgets = null;
        state.country = "";
        state.isLoading = true;
      }
    );
    builder.addCase(
      fetchWidgetsPerLocationPage.rejected,
      (state: IWidgetsSlice) => {
        state.widgets = null;
        state.country = "";
        state.isLoading = false;
      }
    );
    builder.addCase(
      createOrEditEmergencyContact.fulfilled,
      (state: IWidgetsSlice, { payload }) => {
        state.widgets = {
          ...state.widgets,
          contactDetails: {
            ...state.widgets.contactDetails,
            emergencyNumber: payload?.emergencyNumber,
            contacts: payload?.contacts
          }
        };
      }
    );
    builder.addCase(
      createOrEditEmergencyContact.pending,
      (state: IWidgetsSlice) => {
        state.widgets = {
          ...state.widgets,
          contactDetails: {
            ...state.widgets.contactDetails,
            emergencyNumber: {
              caption: "Widget status",
              value: "Loading...",
              type: "phone"
            } as IContact,
            contacts: []
          }
        };
      }
    );
    builder.addCase(
      fetchEmergencyContact.fulfilled,
      (state: IWidgetsSlice, { payload }) => {
        state.widgets = { ...state.widgets, contactDetails: payload };
      }
    );
  }
});

export default WidgetsSlice.reducer;
