import ApiWrapper from "../../../utils/ApiWrapper";
import { IUserDto } from "../../../types/DTO/IUserDto";
import { MobileUserPolicyDto } from "../../../types/general";
import { IUserNameForClaimDto } from "../../../types/DTO/IUserNameForClaimDto";
import { IClaimIntentDTO } from "types/DTO/IClaimIntentDTO";

export const getUserById = (id: string) =>
  ApiWrapper.request<IUserDto>(`/user/profile/${id}`, "GET");

export const getPoliciesByUserId = (userId: string) =>
  ApiWrapper.request<MobileUserPolicyDto[]>(
    `/policy/user/${userId}/policies`,
    "get"
  );

export const getPolicyGroupsByUserId = (userId: string) =>
  ApiWrapper.request<MobileUserPolicyDto[]>(
    `/policy/user/${userId}/policiesGroups`,
    "get"
  );

export const getUserNames = () =>
  ApiWrapper.request<IUserNameForClaimDto[]>("/user/names", "get");

export const getClaimIntents = () =>
  ApiWrapper.request<IClaimIntentDTO[]>(
    "/claims/five-sigma/claim-intents",
    "get"
  );

export const getClaimSubIntents = (intent: string) =>
  ApiWrapper.request<IClaimIntentDTO[]>(
    `/claims/five-sigma/sub/claim-intents/${intent}`,
    "get"
  );
