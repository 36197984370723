import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Container from "@mui/material/Container";
import { Button } from "@mui/material";
import { useAppDispatch } from "../hooks/redux-hooks";
import { AsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../types/redux-types";
import { IPageMeta } from "../types/general";
import { PAGINATION_CONTROLLER_CLASS } from "../constants/common";

const PAGE_NUMBER_BUTTON_CLASS = "page-number-button";

interface PaginationControllerProps {
  fetchFunction: AsyncThunk<any, number, { state: RootState }>;
  pagination?: IPageMeta;
}

const PaginationControllerContainer = styled(Container)`
  &.${PAGINATION_CONTROLLER_CLASS} {
    padding: 0;
    display: flex;
    align-items: center;
    overflow: auto;
  }

  & .${PAGE_NUMBER_BUTTON_CLASS} {
    padding-right: 0;
    padding-left: 0;
    min-width: 34px;
  }
`;

const PaginationController: React.FC<PaginationControllerProps> = ({
  fetchFunction,
  pagination
}) => {
  const dispatch = useAppDispatch();
  const [pages, setPages] = useState<number[]>([]);
  const totalPages = pagination?.totalPages ?? 0;

  const getPage = useCallback(
    (page) => {
      dispatch(fetchFunction(page));
    },
    [dispatch, fetchFunction]
  );

  useEffect(() => {
    if (totalPages > 0) {
      const pageNumbers = new Array(totalPages)
        .fill({})
        .map((_, pageIndex) => pageIndex);
      setPages(pageNumbers);
    } else {
      setPages([]);
    }
  }, [totalPages]);

  return (
    <PaginationControllerContainer className={PAGINATION_CONTROLLER_CLASS}>
      {pages.map((_, pageIndex) => (
        <Button
          className={PAGE_NUMBER_BUTTON_CLASS}
          key={`page-${pageIndex}-key`}
          variant="text"
          disabled={pageIndex + 1 === pagination?.currentPage}
          onClick={() => getPage(pageIndex + 1)}
        >
          {pageIndex + 1}
        </Button>
      ))}
    </PaginationControllerContainer>
  );
};

export default PaginationController;
