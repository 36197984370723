import React from "react";
import { IDashboardLogins } from "../../types/DTO/IDashboardDto";
import styled from "styled-components";
import { Box } from "@mui/material";
import DashboardData from "./DashboardData";
import { calcRatio } from "./dashboard-helper";
import cellPhone from "../../assets/images/cell_phone.png";

interface LoginsTileProps {
  className: string;
  loginsInfo: IDashboardLogins;
}

const LoginsTileContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;

  & .inner-container {
    width: 60%;
    flex-grow: 1;
  }
  & .info {
    display: flex;
    justify-content: space-between;
  }

  & .phone-image {
    padding-left: 10%;
    max-width: 175px;
    position: absolute;
    right: 0;
    bottom: 0;
  }
`;

const LoginsTile: React.FC<LoginsTileProps> = ({ className, loginsInfo }) => {
  return (
    <LoginsTileContainer className={className}>
      <Box className="inner-container">
        <Box>
          <span className="tile-name">App opens</span>
        </Box>
        <Box>
          <span className="tile-amount">{loginsInfo.all}</span>
        </Box>
        <Box className="info">
          <DashboardData
            text={"Last 7 days"}
            amount={loginsInfo.sevenDays}
            delta={calcRatio(
              loginsInfo.previousSevenDays,
              loginsInfo.sevenDays
            )}
          ></DashboardData>
          <DashboardData
            text={"24hr"}
            amount={loginsInfo.lastDay}
            delta={calcRatio(loginsInfo.sevenDays / 7, loginsInfo.lastDay)}
          ></DashboardData>
        </Box>
      </Box>
      <img className="phone-image" src={cellPhone} alt="cell phone" />
    </LoginsTileContainer>
  );
};

export default React.memo(LoginsTile);
