import React, { useCallback } from "react";
import { Card, Tooltip } from "@mui/material";
import styled from "styled-components";
import Container from "@mui/material/Container";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AddLinkOutlinedIcon from "@mui/icons-material/AddLinkOutlined";
import { selectSelectedClaimQuote } from "../slice/selectors";
import IconButton from "@mui/material/IconButton";
import UserSection from "./UserSection";
import PolicySection from "./PolicySection";
import { openConnectPolicyModal } from "../slice";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux-hooks";

const ACTIONS_CONTAINER_CLASS = "actions-container";

interface PolicyCardProps {}

const ClaimCardContainer = styled(Card)`
  &.claim-card-container {
    display: grid;
    padding: 20px;
    grid-template-columns: 1fr 1fr 80px;
    grid-template-rows: 1fr;
    grid-template-areas: "user-section policy-section .";
    margin-top: 10px;
    margin-bottom: 10px;

    & h3 {
      margin: 0;
      line-height: 30px;
    }
    & label {
      color: #959493;
      line-height: 24px;
    }
    & .see-full-details-CTA {
      cursor: pointer;
    }
  }
`;
const ActionsContainer = styled(Container)`
  &.${ACTIONS_CONTAINER_CLASS} {
  }
`;
const ClaimCard: React.FC<PolicyCardProps> = () => {
  const dispatch = useAppDispatch();
  const selectedClaimQuote = useAppSelector(selectSelectedClaimQuote);

  const handleConnectPolicyClicked = useCallback(() => {
    dispatch(openConnectPolicyModal());
  }, [dispatch]);

  return (
    <ClaimCardContainer className="claim-card-container claim-view-section">
      <PolicySection />
      <UserSection />
      <ActionsContainer className={ACTIONS_CONTAINER_CLASS}>
        <IconButton>
          <MoreHorizIcon />
        </IconButton>
        {!Boolean(selectedClaimQuote) && (
          <Tooltip title="Connect a policy">
            <IconButton onClick={handleConnectPolicyClicked}>
              <AddLinkOutlinedIcon />
            </IconButton>
          </Tooltip>
        )}
      </ActionsContainer>
    </ClaimCardContainer>
  );
};

export default ClaimCard;
