import React, { useState } from "react";
import moment from "moment";
import {
  validateBirthYearStr,
  validateDayStr,
  validateMonthStr
} from "../utils/validation";

const useDateInput = (initialValue: Date) => {
  const initialMonth =
    initialValue.getMonth() + 1 < 10
      ? `0${initialValue.getMonth() + 1}`
      : `${initialValue.getMonth() + 1}`;
  const initialDay =
    initialValue.getDate() < 10
      ? `0${initialValue.getDate()}`
      : `${initialValue.getDate()}`;
  const [day, setDay] = useState(initialDay);
  const [month, setMonth] = useState(initialMonth);
  const [year, setYear] = useState(String(initialValue.getFullYear()));
  const [wasChanged, setWasChanged] = useState(false);
  const [dayError, setDayError] = useState("");
  const [monthError, setMonthError] = useState("");
  const [yearError, setYearError] = useState("");
  const isValid = !dayError.length && !monthError.length && yearError.length;
  const dayChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length <= 2 && +event.target.value <= 31) {
      setDay(event.target.value);
      setWasChanged(true);
      resetErrors();
    }
  };
  const monthChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length <= 2 && +event.target.value <= 12) {
      setMonth(event.target.value);
      setWasChanged(true);
      resetErrors();
    }
  };
  const yearChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length <= 4) {
      setYear(event.target.value);
      setWasChanged(true);
      resetErrors();
    }
  };

  const dayBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
    const modifiedDay =
      !!event.target.value && event.target.value.length === 1
        ? 0 + event.target.value
        : event.target.value;
    setDay(modifiedDay);
    if (!validateDayStr(event.target.value)) {
      setDayError("Invalid day ");
    } else if (!moment(`${year}-${month}-${modifiedDay}`).isValid()) {
      const monthAndYear = moment(`${year}-${month}-01`).format("MMMM, YYYY");
      setDayError(
        `The ${event.target.value}th doesnt exist on ${monthAndYear} `
      );
    }
  };
  const monthBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
    const modifiedMonth =
      !!event.target.value && event.target.value.length === 1
        ? 0 + event.target.value
        : event.target.value;
    setMonth(modifiedMonth);
    if (!validateMonthStr(event.target.value)) {
      setMonthError("Invalid month ");
    } else if (!moment(`${year}-${modifiedMonth}-${day}`).isValid()) {
      const monthAndYear = moment(`${year}-${modifiedMonth}-01`).format(
        "MMMM, YYYY"
      );
      setDayError(`The ${day}th doesnt exist on ${monthAndYear} `);
    }
  };

  const yearBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
    let modifiedYear = event.target.value;
    if (!!event.target.value && event.target.value.length < 4) {
      for (let i = 0; i < 4 - event.target.value.length; i++) {
        modifiedYear += "0";
      }
      setYear(modifiedYear);
    }

    if (!validateBirthYearStr(modifiedYear)) {
      setYearError("Invalid year ");
    } else if (!moment(`${modifiedYear}-${month}-${day}`).isValid()) {
      const monthAndYear = moment(`${modifiedYear}-${month}-01`).format(
        "MMMM, YYYY"
      );
      setDayError(`The ${day}th doesnt exist on ${monthAndYear} `);
    }
  };

  const resetErrors = () => {
    setMonthError("");
    setDayError("");
    setYearError("");
  };

  const resetDate = () => {
    setDay(String(initialValue.getDate()));
    setMonth(String(initialValue.getMonth() + 1));
    setYear(String(initialValue.getFullYear()));
    setWasChanged(false);
  };

  return {
    day,
    month,
    year,
    isValid,
    wasChanged,
    dayError,
    monthError,
    yearError,
    dayChangeHandler,
    monthChangeHandler,
    yearChangeHandler,
    dayBlurHandler,
    monthBlurHandler,
    yearBlurHandler,
    resetDate
  };
};

export default useDateInput;
