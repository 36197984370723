import { createSlice } from "@reduxjs/toolkit";
import { IPageMeta, Nullable } from "../../../types/general";
import {
  fetchUsersPage,
  fetchSelectedUserProfile,
  updateUser,
  createUser
} from "./thunks";
import { IUserPolicyInfoDto } from "../../../types/DTO/IUsersInfoDto";
import { IUserDto } from "../../../types/DTO/IUserDto";

interface IUsersSlice {
  Users: Nullable<IUserPolicyInfoDto[]>;
  selectedUser?: Nullable<IUserPolicyInfoDto>;
  isSelectedUserEdit: {
    isOn: boolean;
    updatingInProcess: boolean;
    error?: any;
  };
  isLoading: boolean;
  selectedUserDetails?: Nullable<IUserDto>;
  UsersFetchingInProcessAmount: number;
  UsersSearchText: string;
  pagination?: IPageMeta;
  UserError: string;
  createUserModal: {
    isOpen: boolean;
  };
}

const initialState: IUsersSlice = {
  Users: null,
  isSelectedUserEdit: {
    isOn: false,
    updatingInProcess: false
  },
  isLoading: true,
  UsersSearchText: "",
  UsersFetchingInProcessAmount: 0,
  selectedUser: null,
  UserError: "",
  createUserModal: {
    isOpen: false
  }
};

const UsersSlice = createSlice({
  name: "Users",
  initialState,
  reducers: {
    setSelectedUser: (
      state: IUsersSlice,
      { payload: User }: { payload: IUserPolicyInfoDto }
    ) => {
      state.selectedUser = User;
      state.isSelectedUserEdit = initialState.isSelectedUserEdit;
      state.selectedUserDetails = undefined;
    },
    setSelectedUserEdit: (
      state: IUsersSlice,
      { payload }: { payload: boolean }
    ) => {
      state.isSelectedUserEdit.isOn = payload;
    },
    updateUserSearchText: (
      state: IUsersSlice,
      { payload }: { payload: string }
    ) => {
      state.UsersSearchText = payload;
    },
    resetSelectedUser: (state: IUsersSlice) => {
      state.selectedUser = null;
    },
    openNewUserModal: (state: IUsersSlice) => {
      state.createUserModal = { isOpen: true };
    },
    abortNewUserModal: (state: IUsersSlice) => {
      state.createUserModal = { isOpen: false };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchUsersPage.fulfilled,
      (state: IUsersSlice, { payload }) => {
        if (payload !== undefined) {
          state.Users = payload.usersInfo;
          state.pagination = payload.pageMeta;
        }
        state.UsersFetchingInProcessAmount =
          state.UsersFetchingInProcessAmount - 1;
        state.isLoading = false;
      }
    );
    builder.addCase(fetchUsersPage.pending, (state: IUsersSlice) => {
      state.UsersFetchingInProcessAmount =
        state.UsersFetchingInProcessAmount + 1;
      state.isLoading = true;
    });
    builder.addCase(
      fetchSelectedUserProfile.fulfilled,
      (state: IUsersSlice, { payload }) => {
        state.selectedUserDetails = payload;
      }
    );
    builder.addCase(updateUser.pending, (state: IUsersSlice) => {
      state.isSelectedUserEdit.updatingInProcess = true;
    });
    builder.addCase(updateUser.fulfilled, (state: IUsersSlice, { payload }) => {
      state.selectedUserDetails = payload;
      state.isSelectedUserEdit.isOn = false;
      state.isSelectedUserEdit.updatingInProcess = false;
    });

    builder.addCase(createUser.fulfilled, (state: IUsersSlice, { payload }) => {
      state.createUserModal = { isOpen: false };
    });
  }
});

export const {
  setSelectedUser,
  updateUserSearchText,
  setSelectedUserEdit,
  resetSelectedUser,
  openNewUserModal,
  abortNewUserModal
} = UsersSlice.actions;

export default UsersSlice.reducer;
