import React from "react";
import { ReactComponent as Logo } from "../../assets/svg/Logo.svg";
import styled from "styled-components";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { selectAvatar, selectUser } from "../authentication/selectors";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useHistory } from "react-router-dom";
import { LOGIN_URL } from "../../Routes/constants";
import { logout } from "../authentication/slice";

const HeaderContainer = styled.header`
  &.header {
    grid-column-start: 1;
    grid-column-end: 5;
    background: var(--primary-color);
    box-shadow: 0 4px 10px 1px rgba(0, 0, 0, 0.13);
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > .version-alert-trigger {
      width: 50px;
      height: 50px;
      position: absolute;
      left: 0;

      &:hover {
        cursor: auto;
      }
    }
  }

  & .version-txt {
    color: var(--secondary-color);
    font-size: 13px;
  }

  & .user-txt {
    color: white;
    text-transform: none;
    display: block;
    margin: 0 10px;
  }

  & .btn {
    background-color: unset;
  }

  & .btn:hover {
    background-color: unset;
  }

  & .logo-container {
    display: flex;
    align-items: flex-end;
  }
`;

const Item = styled(MenuItem)`
  min-width: 150px;
`;

const Header = () => {
  const avatarUrl = useAppSelector(selectAvatar);
  const loggedInUser = useAppSelector(selectUser);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutUser = () => {
    dispatch(logout());
    history.push(LOGIN_URL);
    handleClose();
  };

  return (
    <HeaderContainer className="header">
      <Box className="logo-container">
        <Logo />
        <span className="version-txt"> {process.env.REACT_APP_VERSION}</span>
      </Box>
      <div className="menu-container">
        {!!loggedInUser && (
          <Menu
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            anchorEl={anchorEl}
            open={!!anchorEl}
            onClose={handleClose}
          >
            <Item onClick={logoutUser}>Logout</Item>
          </Menu>
        )}
        <Button
          disabled={!loggedInUser}
          onClick={handleClick}
          variant="contained"
          endIcon={!!loggedInUser ? <KeyboardArrowDownIcon /> : null}
          disableElevation
          disableRipple={true}
          className="btn"
        >
          <Avatar src={avatarUrl} />
          <span className="user-txt"> {loggedInUser?.displayName}</span>
        </Button>
      </div>
    </HeaderContainer>
  );
};

export default Header;
