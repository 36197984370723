import { initializeApp, FirebaseOptions, FirebaseApp } from "firebase/app";
import { Auth, getAuth, GoogleAuthProvider } from "firebase/auth";
import { getAnalytics, Analytics } from "firebase/analytics";
import { Nullable } from "../types/general";

let appInstance: FirebaseApp;
let analytics: Analytics;
let auth: Nullable<Auth>;
let googleAuthProviderInstance: Nullable<GoogleAuthProvider>;

const firebaseConfig: FirebaseOptions = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

export const getFirebaseInstance = () => {
  if (typeof window !== "undefined") {
    if (appInstance) return appInstance;
    appInstance = initializeApp(firebaseConfig);
    return appInstance;
  }
  return null;
};

export const getAuthInstance = () => {
  if (typeof appInstance !== "undefined") {
    if (auth) return auth;
    auth = getAuth();
    return auth;
  }
  return null;
};

export const getGoogleAuthProviderInstance =
  (): Nullable<GoogleAuthProvider> => {
    if (!googleAuthProviderInstance) {
      googleAuthProviderInstance = new GoogleAuthProvider();
    }
    return googleAuthProviderInstance;
  };

export const getAnalyticsInstance = () => {
  if (typeof appInstance !== "undefined") {
    if (analytics) return analytics;
    analytics = getAnalytics(appInstance);
    return analytics;
  }
  return null;
};
